import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { FilterList, FilterListItem } from 'react-admin'

export const ClosedFilter: FC = () => (
  <Root className={classes.root} label="filters.status" icon={undefined}>
    <FilterListItem
      className={classes.item}
      label={'filters.showClosed'}
      value={{
        showClosed: true,
      }}
    />
  </Root>
)

const PREFIX = 'xxllncTicketsFilter'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
}

const Root = styled(FilterList)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    margin: '8px',
  },
  '& li': {
    marginBottom: 0,
  },
}))
