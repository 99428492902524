import { styled } from '@mui/material/styles'
import { xxllncColor } from '../../layout/themes'

export const AppCardActions = ({ icon }: AppCardActionsProps): JSX.Element => (
  <Root className={classes.root}>
    <div className={classes.logo}>
      <img src={`data:image/svg+xml;base64,${icon}`} />
    </div>
  </Root>
)

interface AppCardActionsProps {
  icon: string;
}

const PREFIX = 'xxllncAppCardActions'

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    height: '100%',
  },
  [`& .${classes.logo}`]: {
    height: '32px',
    width: '32px',
    overflow: 'hidden',
    ['& img']: {
      width: '100%',
    }
  },
  [`& .${classes.button}`]: {
    alignItems: 'center',
    backgroundColor: xxllncColor.dark100,
    borderRadius: '50px',
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    lineHeight: 0,
    marginLeft: 'auto',
    position: 'absolute',
    right: '12px',
    top: '12px',
    transition: 'background .5s, color .5s',
    width: '32px',
  },
  [`& .${classes.icon}`]: {
    fill: xxllncColor.light100,
    width: '18px',
    height: '18px',
  },
}))
