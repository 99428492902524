import { Typography } from '@mui/material'
import { FC } from 'react'
import { Create, RaRecord, SelectInput, SimpleForm, useGetList, useTranslate } from 'react-admin'
import { HeaderTitle } from '../../components'
import { AppModuleOut } from '../../types/ecoRequests'

interface Choices extends RaRecord {
  name: string
}

const AppOrganizationIntegrationCreate: FC = () => {
  const translate = useTranslate()
  const search = new URLSearchParams(location?.search)
  const appOrganizationId = search.get('appOrganizationId')
  const redirect = appOrganizationId ? `/app_organization/${appOrganizationId}/show/integrations` : undefined
  const appId = search.get('appId')

  const { data } = useGetList<AppModuleOut>(
    'app_integration',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: { appId }
    }
  )

  const choices = data?.map(({ id, name }: Choices) => ({ id, name }))

  return (
    <Create
      title={<HeaderTitle i18n="resources.app_organization_integration.title" />}
      redirect={redirect}
    >
      <SimpleForm defaultValues={{ appOrganizationId }}>
        { !choices ? (
          <Typography>
            { translate('resources.app_integration.notAvailable') }
          </Typography>) :
          (<SelectInput
            choices={choices.sort()}
            helperText=""
            label="data.name"
            source="appIntegrationId"
            isRequired
          />)
        }

      </SimpleForm>
    </Create>
  )
}

export default AppOrganizationIntegrationCreate
