import { makeStyles } from '@mui/styles'
import { FC, useMemo } from 'react'
import { TextField, TextFieldProps, useRecordContext } from 'react-admin'

const useStyles = makeStyles({
  endedStyle: {
    color: 'lightgray',
  },
  defaultStyle: {},
})

export const ConditionalTextField: FC<TextFieldProps> = (props) => {
  const { className, ...rest } = props
  const record = useRecordContext(props)
  const classes = useStyles()
  const style = useMemo(() => (record && record.ended ? classes.endedStyle : classes.defaultStyle), [record, classes])

  return <TextField {...rest} className={style} />
}
