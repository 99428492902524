import { Grid } from '@mui/material'
import { FC } from 'react'
import { BooleanInput, DateTimeInput, TextInput } from 'react-admin'
import { ActivateForm } from '../../components/ActivateForm'
import { defaultDate } from '../../utils/defaultDate'
import { colorValidation, required } from '../../utils/validation'

const OrganizationForm: FC<OrganizationFormProps> = ({view}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextInput
        helperText=""
        isRequired
        label="data.name"
        source="name"
        validate={required()}
      />
    </Grid>
    <Grid item xs={12} sm={view === 'create' ? 12 : 6}>
      <DateTimeInput
        defaultValue={defaultDate}
        helperText=""
        isRequired
        label="data.started"
        source="started"
        validate={required()}
      />
    </Grid>
    {view === 'edit' && (
      <Grid item xs={12} sm={6}>
        <DateTimeInput
          helperText=""
          label="data.ended"
          source="ended"
        />
      </Grid>
    )}
    <Grid item xs={12}>
      <BooleanInput
        helperText=""
        label="data.isPartnership"
        source="isPartnership"
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        helperText=""
        label="data.logoUrl"
        source="logoUrl"
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextInput
        helperText="data.hexColorHelper"
        label="data.primaryColor"
        source="primaryColor"
        validate={colorValidation}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextInput
        helperText="data.hexColorHelper"
        label="data.secondaryColor"
        source="secondaryColor"
        validate={colorValidation}
      />
    </Grid>
    <ActivateForm description="organization" />
  </Grid>
)

interface OrganizationFormProps {
  view: 'create' | 'edit';
}

export default OrganizationForm
