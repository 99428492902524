import { Typography } from '@mui/material'
import React, { FC } from 'react'
import { useGetList, useTranslate } from 'react-admin'
import { AppOrganizationLicenceOut } from '../../types/ecoRequests'
import { StartIcon, XxllncAccordion, XxllncAccordionDetails, XxllncAccordionSummary } from './GenericOrganizationList'

export const LicenceList: FC = () => {
  const translate = useTranslate()
  const search = new URLSearchParams(location?.search)
  const appOrganizationId = search.get('appOrganizationId')
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const { data: licences } = useGetList<AppOrganizationLicenceOut>(
    'app_organization_licence',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { appOrganizationId },
    },
  )

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return licences && licences.length > 0 ? (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
      <Typography variant="h6" style={{ marginBottom: '10px' }}>
        {translate('show.licence')}
      </Typography>
      { licences?.map((licence) => (
        <XxllncAccordion
          key={licence.appLicenceName}
          expanded={expanded === licence.appLicenceName}
          onChange={handleChange(licence.appLicenceName)}
        >
          <XxllncAccordionSummary aria-controls={`${licence.appLicenceName}-content`} id={`${licence.appLicenceName}-header`}>
            <Typography sx={{ marginLeft: '20px', lineHeight: '27px' }}>
              <StartIcon />
              { licence.appLicenceName }
            </Typography>
          </XxllncAccordionSummary>
          <XxllncAccordionDetails>
            <Typography sx={{ whiteSpace: 'pre-line' }}>
              { translate(`resources.app_licence.${licence.type}`, { amount: licence.amount }) }
            </Typography>
          </XxllncAccordionDetails>
        </XxllncAccordion>
      )) }
    </div>) : null
}