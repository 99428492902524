import React from 'react'
import { SimpleShowLayout, SimpleShowLayoutProps } from 'react-admin'
import { styled } from '@mui/system'

const StyledSimpleShowLayout = styled(SimpleShowLayout)({
  padding: 0,
})

export const NestedShowLayout: React.FC<SimpleShowLayoutProps> = (props) => (
  <StyledSimpleShowLayout {...props} />
)
