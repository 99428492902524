import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Button, Snackbar } from '@mui/material'
import { FC, useState } from 'react'
import { useTranslate } from 'react-admin'

interface CopyToClipboardButtonProps {
    id: string
    hideLabel?: boolean
}

const CopyToClipboardButton: FC<CopyToClipboardButtonProps> = ({ id, hideLabel = false }) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
    void navigator.clipboard.writeText(id)
  }

  return (
    <>
      <Button
        onClick={handleClick}
        style={{ minWidth: '64px' }}
        variant="text"
        endIcon={<ContentCopyIcon />}
      >
        { hideLabel ? null : translate('actions.copyId') }
      </Button>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="ID gekopieerd naar klembord"
      />
    </>
  )
}

export default CopyToClipboardButton