import { Avatar, Box, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { DownloadFileButton } from '../../components'
import getInitials from '../../utils/getInitials'

interface ContractFormProps {
    name?: string | null,
    url?: string | null,
    backgroundColor: string
}

const ContractDocument: FC<ContractFormProps> = ({ name, url, backgroundColor }) => (
  <StyledBox
    className={classes.root}
    sx={{ backgroundColor }}
  >
    <Avatar
      variant="circular"
      sx={{ backgroundColor: '#bdbdbd', fontSize: '11pt' }}
    >
      { getInitials(name) }
    </Avatar>
    <Box className={classes.content}>
      <Typography variant="subtitle1" sx={{ fontSize: '9pt', flexGrown: 1 }}>
        { name }
      </Typography>
      <DownloadFileButton fileName={ name } fileUrl={ url } />
    </Box>
  </StyledBox>
)

const PREFIX = 'xxllncContractDocument'

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    padding: '16px',
  },
  [`& .${classes.content}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '10px',
    width: '100%',
  },
}))

export default ContractDocument