import { Box, Grid, styled, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { Button, useRecordContext, useTranslate } from 'react-admin'
import { xxllncColor } from '../../layout/themes'
import { Contract } from '../../types/ecoRequests'
import { dateFormatter } from '../../utils/dateFormatter'
import ContractDocument from './ContractDocument'
import ContractStatus from './ContractStatus'

const emailAddress = 'fieldops@xxllnc.nl'

const ContractForm: FC = () => {
  const translate = useTranslate()
  const record = useRecordContext<Contract>()
  const [showEmailPopup, setShowEmailPopup] = useState(false)

  const documents = [record?.servicelevelAgreement, record?.contractDocument, record?.processingAgreement]

  const sendMail = () => {
    const subject = `Correctie contract ${record?.name}`

    const blurListener = () => {
      setShowEmailPopup(false)
    }

    window.addEventListener('blur', blurListener)

    const timeout = setTimeout(() => {
      setShowEmailPopup(true)
      window.removeEventListener('blur', blurListener)
    }, 500)

    window.location.assign(`mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`)

    return () => {
      clearTimeout(timeout)
    }
  }

  return (
    <StyledGrid container spacing={2} className={classes.root}>
      <Grid item xs={12} className={classes.item}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">{ record?.name }</Typography>
          <Box className={classes.info}>
            <span><b>{`${translate('data.team')}: `}</b>{ record?.domain}</span>
            { record?.duration && <span><b>{`${translate('data.duration')}: `}</b>{ record?.duration}</span>}
            <span><b>{`${translate('data.statingDate')}: `}</b>{ dateFormatter(record?.statingDate, false) }</span>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography variant="body1" sx={{ fontSize: '12pt', marginBottom: '20px', whiteSpace: 'pre-wrap' }}>
            { translate('resources.contracts.info') }
          </Typography>
          <Button
            onClick={sendMail}
            label={'resources.contracts.contactBtn'}
            sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
          />
          { showEmailPopup  && <Typography variant="body1" sx={{ fontSize: '12pt', marginTop: '20px' }}>
            { translate('resources.contracts.contactMessage', { name: emailAddress }) }
          </Typography>}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <ContractStatus />
          <Box className={classes.documents}>
            { documents.filter(document => !!document).map((document, index) => (
              <ContractDocument
                {...document}
                backgroundColor={index % 2 === 0 ? xxllncColor.light80 : 'transparant'}
              />)) }
          </Box>
        </Box>
      </Grid>
    </StyledGrid>
  )
}

const PREFIX = 'xxllncContractForm'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  info: `${PREFIX}-info`,
  documents: `${PREFIX}-documents`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: { },
  [`& .${classes.item}`]: {
    paddingLeft: 0,
  },
  [`& .${classes.info}`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: `1px solid ${xxllncColor.dark20}`,
    paddingBottom: '24px',
    marginBottom: '30px',
    marginTop: '16px',
    ['& span']: {
      fontSize: '10pt',
      color: '#6E6E74',
      paddingRight: '20px',
      marginRight: '20px',
      ['&:not(:last-child)']: {
        borderRight: `1px solid ${xxllncColor.dark20}`,
      },
    },
  },
  [`& .${classes.documents}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 0 10px 0',
    width: '100%',
  },
}))

export default ContractForm