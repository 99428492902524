import { Chip, TableCell } from '@mui/material'
import { FC } from 'react'
import { RaRecord, useGetList } from 'react-admin'
import { AppModuleOut } from '../../types/ecoRequests'

interface ModulesFieldProps {
  record: RaRecord
}

export const ModulesField: FC<ModulesFieldProps> = ({ record }) => {
  const { data: modules } = useGetList<AppModuleOut>(
    'app_module',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { appId: record.id },
    },
  )

  return record ? (
    <TableCell>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        gap: '10px',
        maxWidth: '370px',
        flexWrap: 'wrap',
      }}
      >
        { modules?.map((module) => <Chip label={module.name} size="small" />) }
      </div>
    </TableCell>) : null
}