import { FC } from 'react'
import { EditButton } from 'react-admin'
import { ActionsToolbar, RestrictedButton } from '../../components'

const TicketConfigShowButtons: FC = () => (
  <RestrictedButton
    access={['eco.admin.ticketConfig:write']}
    align="right"
  >
    {(): JSX.Element => <EditButton variant="contained" />}
  </RestrictedButton>
)

export const TicketConfigShowActions: FC = () => (
  <ActionsToolbar source="name" to="/ticket_config">
    <TicketConfigShowButtons />
  </ActionsToolbar>
)
