import { FC } from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { ActionsToolbar, ConfirmDeleteToolbar, HeaderTitle } from '../../components'
import TicketConfigForm from './TicketConfigForm'

const TicketConfigEdit: FC = () => (
  <Edit
    title={<HeaderTitle source="description" i18n="edit.title" />}
    actions={<ActionsToolbar source="description" i18n="resources.ticketConfig.edit" />}
    redirect="show"
  >
    <SimpleForm
      toolbar={<ConfirmDeleteToolbar access={['eco.admin.ticketConfig:delete']} />}
      sanitizeEmptyValues
    >
      <TicketConfigForm />
    </SimpleForm>
  </Edit>
)

export default TicketConfigEdit
