import { Grid } from '@mui/material'
import { FC } from 'react'
import { DateTimeInput, TextInput } from 'react-admin'
import { defaultDate } from '../../utils/defaultDate'
import { required } from '../../utils/validation'

const HostAliasesForm: FC = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextInput
        helperText=""
        source="hostname"
        label="data.hostname"
        validate={required()}
        isRequired
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <DateTimeInput
        defaultValue={defaultDate}
        helperText=""
        label="data.started"
        source="started"
        validate={required()}
        isRequired
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <DateTimeInput
        helperText=""
        label="data.ended"
        source="ended"
      />
    </Grid>
  </Grid>
)

export default HostAliasesForm
