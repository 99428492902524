import { xxllncColor } from './color'

export const border = {
  radius: {
    sm: 4,
    md: 24,
    lg: 50,
  },
  thin: '1px',
  normal: '2px',
  thick: '3px',
  dark: xxllncColor.dark80,
  light: xxllncColor.dark20,
  button: {
    radius: 50,
  },
  input: {
    radius: 5,
  },
  dropdown: {
    radius: 12,
  },
  icon: {
    radius: {
      sm: 8,
      md: 16,
    }
  }
}
