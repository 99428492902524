import AddIcon from '@mui/icons-material/Add'
import { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'
import { MouseEvent, ReactElement, useEffect, useState } from 'react'
import { Button as RaButton, RaRecord, useNotify, useRefresh, useTranslate } from 'react-admin'
import { Dialog, RestrictedButton } from '../../components'
import { getApiUrl } from '../../providers/data'
import { debug } from '../../test'
import { AuthConnectionBase } from '../../types/ecoRequests'
import authToken from '../../utils/authToken'
import { ContainerAlign } from './ButtonTypes'

/**
 * Sign on connection option.
 *
 * @example // basic usage
 * import { ConnectButton } from '../../components';
 *
 * const ConnectButton = (): JSX.Element => (
 *   <ConnectButton
 *     access={[access]}
 *     resource={resource}
 *     record={record}
 *   />
 * );
 */
export const ConnectButton = (props: ConnectButtonProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false)
  const [token, setToken] = useState<string | undefined>()
  const [submit, setSubmit] = useState<boolean>(false)
  const authenticationToken = authToken.getToken() || ''
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()
  const {
    access,
    align = 'left',
    icon = defaultIcon,
    label = translate('actions.connect'),
    record,
    resource,
    title = 'resources.auth_connection.notification.title',
    variant = 'contained',
    ...rest
  } = props
  const { id } = record as AuthConnectionBase
  const url = `${getApiUrl(resource)}/${resource}/share`

  useEffect(() => {
    const getData = async () => {
      await fetch(url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Authorization': authenticationToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token,
          organizationAuthId: id,
        }),
      }).then((response) => response.json())
        .then((data) => {
          console.log('Success:', data)
          refresh()
        }).catch(error => {
          debug && console.error('ConnectButton error:', error)
          notify('notification.warning', { type: 'error' })
        })
    }

    if (submit && token) {
      debug && console.log('FIRE!')
      void getData()
      setSubmit(false)
    }
  }, [resource, authToken, url, token, submit])

  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    event.preventDefault()
    setToken(event.target.value as string)
  }

  const content = (
    <TextField
      onChange={handleOnChange}
      label={translate('resources.auth_connection.connect.tokenInput')}
    />
  )

  const handleDialogOpen = (): void => {
    debug && console.log('dialog open')
    setOpen(true)
  }

  const handleDialogClose = (): void => {
    debug && console.log('dialog close')
    setOpen(false)
  }

  const handleDialogConfirm = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
    setOpen(false)
    setSubmit(true)
    debug && console.log('I am submitting my token', submit)
  }

  return (
    <RestrictedButton
      access={access}
      align={align || 'right'}
    >
      {(): JSX.Element => (
        <>
          <RaButton
            label={label}
            variant={variant}
            color="primary"
            onClick={handleDialogOpen}
            {...(rest as any)}
          >
            {icon}
          </RaButton>
          <Dialog
            content={content}
            isOpen={isOpen}
            onConfirm={handleDialogConfirm}
            onClose={handleDialogClose}
            title={title}
          />
        </>
      )}
    </RestrictedButton>
  )
}

const defaultIcon = <AddIcon />

interface Props {
  access: string[];
  align?: ContainerAlign;
  icon?: ReactElement;
  label?: string;
  record: RaRecord;
  resource: string;
  title?: string;
  variant?: string;
}

type ConnectButtonProps = Props & MuiButtonProps

ConnectButton.propTypes = {
  access: PropTypes.arrayOf(PropTypes.string).isRequired,
  align: PropTypes.oneOf<ContainerAlign>([
    'left',
    'center',
    'right'
  ]),
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  title: PropTypes.string,
  variant: PropTypes.string,
}
