import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { BooleanInput, DateTimeInput, FormDataConsumer, useTranslate } from 'react-admin'

interface Props {
    description: string
}

export const ActivateForm: FC<Props> = ({ description }) => {
  const translate = useTranslate()

  return (
    <FormDataConsumer>{({formData}) => formData.ended ? (
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" component="div" style={{ marginBottom: '10px'}}>
          {translate('data.inactive', { description: translate(`data.${description}`) })}
        </Typography>
        <DateTimeInput
          helperText=""
          label="data.ended"
          source="ended"
        />
        <BooleanInput
          helperText={`resources.${description}.activate`}
          label="data.activate"
          source="activate"
        />
      </Grid>
    ) : null}</FormDataConsumer>
  )
}