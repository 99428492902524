import { FC } from 'react'
import { ListProps, List, useTranslate } from 'react-admin'
import { Typography, styled } from '@mui/material'

interface CustomListProps extends ListProps {
  title: string
}

const CustomList: FC<CustomListProps> = (props) => {
  const { title, children, ...listProps } = props
  const translate = useTranslate()

  return (
    <Root className={listProps.actions ? classes.root : classes.noActionsRoot}>
      <div className={classes.header}>
        <Typography variant="h1" className={classes.title}>
          {translate(title)}
        </Typography>
      </div>
      <List {...listProps} className={classes.list}>
        { children }
      </List>
    </Root>
  )
}

const PREFIX = 'xxllncCustomList'

const classes = {
  root: `${PREFIX}-root`,
  noActionsRoot: `${PREFIX}-noActionsRoot`,
  list: `${PREFIX}-list`,
  header: `${PREFIX}-header`,
  title: `${PREFIX}-title`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
  },
  [`&.${classes.noActionsRoot}`]: {
    '& .RaList-actions': {
      display: 'none',
    },
  },
  [`& .${classes.header}`]: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      marginBottom: '20px',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px',
    }
  },
  [`& .${classes.title}`]: {
    fontSize: '24px',
    marginBottom: '4px',
  },
  [`& .${classes.list}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .xxllncListActionsToolbar-title': {
    display: 'none',
  },
}))

export default CustomList
