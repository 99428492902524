import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FC } from 'react'
import { EditButton, useRecordContext } from 'react-admin'
import { ActionsToolbar, RestrictedButton } from '../../components'
import { AppInstanceOut } from '../../types/ecoRequests'
import { getTabPath } from '../../utils/getTabPath'

const AppInstanceButtons: FC = () => {
  const record = useRecordContext<AppInstanceOut>()
  const tabPath = getTabPath()

  if (record) {
    switch (tabPath) {
      case 'auditlog':
        return <></>
      default:
        return (
          <RestrictedButton
            access={['eco.admin.appinstance:write']}
            align="right"
          >
            {(): JSX.Element => <EditButton record={record} icon={<EditOutlinedIcon />} variant="contained" />}
          </RestrictedButton>
        )
    }
  }

  return <></>
}

export const AppInstanceShowActions: FC = () => (
  <ActionsToolbar source="appName">
    <AppInstanceButtons />
  </ActionsToolbar>
)
