import get from 'lodash/get'
import { RaRecord, useRecordContext, useTranslate } from 'react-admin'

export const ToolbarTitle = (props: ToolbarTitleProps): JSX.Element | null => {
  const {
    className,
    i18n,
    record = useRecordContext(),
    source,
  } = props
  const translate = useTranslate()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const getSource = source ? get(record, source) as string : undefined

  if (i18n && getSource) {
    return <span className={className}>{translate(i18n, { name: getSource })}</span>
  } if (getSource) {
    return <span className={className}>{getSource}</span>
  } if (i18n) {
    return <span className={className}>{translate(i18n)}</span>
  } else {
    return null
  }
}

interface ToolbarTitleProps {
  className?: string | undefined;
  i18n?: string;
  record?: RaRecord;
  source?: string;
  [key: string]: any;
}
