import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { ActionsToolbar } from '../../components'
import AppShowButtons from './actions/AppShowButtons'

const AppShowActions: FC<AppShowActionsProps> = ({ icon, source }) => (
  <Root className={classes.root}>
    <ActionsToolbar
      icon={icon}
      source={source}
      to="/app_overview"
    >
      <div className={classes.nav}>
        <AppShowButtons />
      </div>
    </ActionsToolbar>
  </Root>
)

interface AppShowActionsProps {
  source: string;
  icon?: string;
}

const PREFIX = 'xxllncAppShowActions'

const classes = {
  root: `${PREFIX}-root`,
  nav: `${PREFIX}-nav`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (_props, styles) => styles.root,
})(({ theme }) => ({
  [`&.${classes.root}`]: {
    paddingTop: theme.spacing(3),
  },
  [`& .${classes.nav}`]: {
    display: 'flex',
    height: '30px',
  },
}))

export default AppShowActions
