import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Done'
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps as MuiDialogProps,
  DialogTitle
} from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes, { ReactComponentLike } from 'prop-types'
import * as React from 'react'
import { FC, MouseEventHandler, useCallback } from 'react'
import { useTranslate } from 'react-admin'

/**
 * Popup dialog
 *
 * @example
 * <Dialog
 *   content="This is a special description of your action"
 *   done="Done"
 *   DoneIcon={<DoneIcon />}
 *   isOpen={true}
 *   onClose={() => { // do something }}
 *   title="Something has been generated for you"
 * />
 */

export const Dialog: FC<DialogProps> = (props) => {
  const translate = useTranslate()
  const {
    close = 'actions.close',
    className,
    content,
    done = 'actions.done',
    DoneIcon = CheckIcon,
    isOpen = false,
    onClose,
    onConfirm,
    title,
    translateOptions = {},
    variant = 'contained',
    ...rest
  } = props

  const handleClick = useCallback(e => {
    // useful to prevent click bubbling in a datagrid with rowClick
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    e.stopPropagation()
  }, [])

  return (
    <StyledDialog
      className={className}
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="dialog-title"
      {...rest}
    >
      <DialogTitle id="dialog-title">
        {translate(title, { _: title, ...translateOptions })}
      </DialogTitle>
      <DialogContent
        className={classes.content}
      >
        {typeof content === 'string' ? (
          <DialogContentText>
            {translate(content, {
              _: content,
              ...translateOptions,
            })}
          </DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {onClose && (
          <Button
            color="primary"
            onClick={onClose}
            startIcon={<CloseIcon />}
            variant={variant}
          >
            {translate(close)}
          </Button>
        )}
        {onConfirm && (
          <Button
            color="primary"
            onClick={onConfirm}
            startIcon={<DoneIcon />}
            variant={variant}
          >
            {translate(done, {
              _: done,
              ...translateOptions,
            })}
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  )
}

const PREFIX = 'xxllncDialog'

export const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  actions: `${PREFIX}-actions`,
}

const StyledDialog = styled(MuiDialog, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.content}`]: {
    wordBreak: 'break-word',
  },
  [`& .${classes.actions}`]: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

export interface DialogProps extends Omit<MuiDialogProps, 'open' | 'onClose' | 'content'> {
  close?: string;
  className?: string;
  content: React.ReactNode;
  done?: string;
  DoneIcon?: ReactComponentLike;
  isOpen?: boolean;
  onClose: MouseEventHandler;
  onConfirm?: MouseEventHandler;
  title: string;
  translateOptions?: object;
  variant?: 'text' | 'outlined' | 'contained';
}

Dialog.propTypes = {
  close: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  done: PropTypes.string,
  DoneIcon: PropTypes.elementType,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  sx: PropTypes.any,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
}
