import Admin from './admin/Admin'
import { initAuth0 } from './auth0'

const App = (): JSX.Element => {
  const search = new URLSearchParams(location?.search)
  const organization = search?.get('organization') as string

  initAuth0(organization)

  return <Admin/>
}

export default App
