import AppsIcon from '@mui/icons-material/Apps'
import AppList from './App.list'
import AppShow from './App.show'

const resource = {
  list: AppList,
  show: AppShow,
  icon: AppsIcon,
}

export default resource
