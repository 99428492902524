import { Grid } from '@mui/material'
import { FC } from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { AuthConnectionEnrichment, AuthConnectionType } from '../../../types/ecoRequests'
import { required } from '../../../utils/validation'

const Oauth2Form: FC = () => {
  const getTypesArray = Object.values(AuthConnectionType)
  const typeChoices = getTypesArray.map(choice => ({
    id: choice as string,
    name: `signicat.${choice}`,
  }))

  const getChoicesArray = Object.values(AuthConnectionEnrichment)
  const choices = getChoicesArray.map(choice => ({
    id: choice,
    name: `enrichment.${choice}`,
  }))

  return (
    <>
      <Grid item xs={12}>
        <SelectInput
          choices={typeChoices}
          defaultValue="eHerkenning"
          helperText=""
          label="data.signicatType"
          source="options.signicatType"
          validate={required()}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.authorizationUrl"
          source="options.authorizationUrl"
          type="url"
          validate={required()}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.tokenUrl"
          source="options.tokenUrl"
          type="url"
          validate={required()}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.clientId"
          source="options.clientId"
          validate={required()}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.clientSecret"
          source="options.clientSecret"
          validate={required()}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <SelectInput
          choices={choices}
          defaultValue="noEnrichment"
          emptyText="enrichment.noEnrichment"
          emptyValue="noEnrichment"
          format={(v: string) => v === 'noEnrichment' ? 'enrichment.noEnrichment' : v}
          helperText=""
          label="data.enrichment"
          parse={(v: string) => v === 'noEnrichment' ? null : v}
          source="options.enrichment"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.haalCentraalApiKey"
          source="options.haalCentraalApiKey"
        />
      </Grid>
    </>
  )
}

export default Oauth2Form
