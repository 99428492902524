import { Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useGetList, useTranslate } from 'react-admin'
import { AppInstanceLogOut, AppInstanceOut } from '../../types/ecoRequests'
import { StartIcon, XxllncAccordion, XxllncAccordionDetails, XxllncAccordionSummary } from './GenericOrganizationList'

interface DataUsageListProps {
  appInstances: AppInstanceOut[]
  setTotalUsage: React.Dispatch<React.SetStateAction<number>>
}

export const DataUsageList: FC<DataUsageListProps> = ({ appInstances, setTotalUsage }) => {
  const translate = useTranslate()
  const [expanded, setExpanded] = useState<string | false>(false)

  const { data: logs } = useGetList<AppInstanceLogOut>(
    'app_instance_log',
    {
      pagination: { page: 1, perPage: 100 },
      filter: { appInstanceIds: appInstances.map(i => i.id), onlyLast: true },
    },
  )

  useEffect(() => {
    if (logs) {
      const totalUsage = logs.reduce((acc, log) => acc + log.usedMemory, 0)
      setTotalUsage(totalUsage)
    }
  }, [logs])

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return logs && logs.length > 0 ? (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
      <Typography variant="h6" style={{ marginBottom: '10px' }}>
        {translate('data.dataUsage')}
      </Typography>
      { logs?.map(log => (
        <XxllncAccordion
          key={log.description}
          expanded={expanded === log.description}
          onChange={handleChange(log.description)}
        >
          <XxllncAccordionSummary aria-controls={`${log.description}-content`} id={`${log.description}-header`}>
            <Typography sx={{ marginLeft: '20px', lineHeight: '27px' }}>
              <StartIcon />
              { log.description }
            </Typography>
          </XxllncAccordionSummary>
          <XxllncAccordionDetails>
            <Typography sx={{ whiteSpace: 'pre-line' }}>{ translate('data.activeUsers', { amount: log.activeUsers }) }</Typography>
            <Typography sx={{ whiteSpace: 'pre-line' }}>{ translate('data.usedMemory', { amount: log.usedMemory }) }</Typography>
          </XxllncAccordionDetails>
        </XxllncAccordion>
      )) }
    </div>) : null
}