import { FC } from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { ActionsToolbar, ConfirmDeleteToolbar, HeaderTitle } from '../../components'
import { HostAliasOut } from '../../types/ecoRequests'
import HostAliasesForm from './HostAliasesForm'

const HostAliasesEdit: FC = () => {
  const redirect = (basePath, _id, data) => {
    const appInstanceId = (data as HostAliasOut)?.appInstanceId
    return appInstanceId ? `app_instance/${appInstanceId}/show` : `${basePath || '/'}`
  }

  return (
    <Edit
      actions={<ActionsToolbar i18n="resources.hostalias.edit" />}
      redirect={redirect}
      title={<HeaderTitle i18n="resources.hostalias.edit" />}
    >
      <SimpleForm
        toolbar={<ConfirmDeleteToolbar access={['eco.admin.appinstance:delete']} />}
        sanitizeEmptyValues
      >
        <HostAliasesForm />
      </SimpleForm>
    </Edit>
  )
}

export default HostAliasesEdit
