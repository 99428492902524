import { FC } from 'react'
import { Create, SimpleForm, useGetOne } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { AppOrganizationOut } from '../../types/ecoRequests'
import AppInstanceForm from './AppInstanceForm'

const AppInstanceCreate: FC = () => {
  const search = new URLSearchParams(location?.search)
  const appId = search.get('appId')
  const appOrganizationId = search.get('appOrganizationId')
  const organizationId = search.get('organizationId')
  const redirect = appOrganizationId ? `/app_organization/${appOrganizationId}/show/environments` : undefined

  const { data, isLoading } = useGetOne<AppOrganizationOut>(
    'app_organization',
    {
      id: appOrganizationId || '',
    }
  )

  const appOrganizationSystemTags = !isLoading ? data?.systemTags : undefined

  return !isLoading ? (
    <Create
      actions={<ActionsToolbar source="appName" i18n="resources.app_instance.create" record={data} />}
      redirect={redirect}
      title={<HeaderTitle source="appName" i18n="resources.app_instance.create" record={data} />}
    >
      <SimpleForm
        defaultValues={{
          appId,
          organizationId,
          appOrganizationSystemTags
        }}
        sanitizeEmptyValues
      >
        <AppInstanceForm />
      </SimpleForm>
    </Create>
  ) : <></>
}

export default AppInstanceCreate
