import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import GroupIcon from '@mui/icons-material/Group'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Menu } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, MouseEvent, useState } from 'react'
import { Button, DeleteButton, EditButton, useRecordContext, useTranslate } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { ResetPasswordButton, RestrictedButton } from '../../components'
import { MemberOut } from '../../types/ecoRequests'

const MemberDropdown: FC<MemberDropdownProps> = (props) => {
  const { redirect } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedId, setSelectedId] = useState<null | string>(null)
  const translate = useTranslate()
  const navigate = useNavigate()
  const record = useRecordContext<MemberOut>()

  const handleClick = (event: MouseEvent<HTMLButtonElement>, id: string): void => {
    setAnchorEl(event.currentTarget)
    setSelectedId(id)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <StyledRestrictedButton
      access={['eco.admin.member:write', 'eco.user.member:write']}
      align="right"
      cellClassName={classes.smallCell}
      headerClassName={classes.smallCell}
    >
      {() => (
        <div key={record?.id}>
          <Button onClick={(event) => handleClick(event, record?.id || '0')}>
            <MoreVertIcon />
          </Button>
          <Menu
            anchorEl={anchorEl}
            id="auth-member-menu"
            keepMounted
            onClose={handleClose}
            open={Boolean(anchorEl) && selectedId === record?.id}
          >
            <EditButton record={record} resource="auth_member" icon={<EditOutlinedIcon />} fullWidth />
            <Button
              onClick={() => navigate(`/auth_member_roles?authMemberId=${record?.id}`)}
              fullWidth
              startIcon={<GroupIcon />}
              label="resources.auth_member.manageRoles"
            />
            <ResetPasswordButton record={record} resource="auth_member" onClose={handleClose} {...record} />
            <RestrictedButton
              access={['eco.admin.member:delete', 'eco.user.member:delete']}
              align="right"
              fullWidth
            >
              {(): JSX.Element => (
                <DeleteButton
                  confirmContent="resources.auth_member.deleteContent"
                  confirmTitle={translate('resources.auth_member.deleteTitle', { name: record?.name })}
                  fullWidth
                  icon={<DeleteOutlinedIcon />}
                  mutationMode="pessimistic"
                  record={record}
                  redirect={redirect}
                  resource="auth_member"
                />
              )}
            </RestrictedButton>
          </Menu>
        </div>
      )}
    </StyledRestrictedButton>
  )
}

interface MemberDropdownProps {
  redirect: string;
}

const PREFIX = 'xxllncMemberDropdown'

const classes = {
  smallCell: `${PREFIX}-smallCell`,
}

const StyledRestrictedButton = styled(RestrictedButton)(({ theme }) => ({
  [`& .${classes.smallCell}`]: {
    paddingRight: 0,
    width: 24,
  },
}))

export default MemberDropdown
