import { Grid } from '@mui/material'
import { FC } from 'react'
import { TextInput } from 'react-admin'
import { required } from '../../../utils/validation'

const AdfsForm: FC = () => (
  <Grid item xs={12}>
    <TextInput
      helperText=""
      label="data.adfsServer"
      source="options.adfs_server"
      validate={required()}
      isRequired
    />
  </Grid>
)

export default AdfsForm
