import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Grid, useMediaQuery } from '@mui/material'
import { FC, useState } from 'react'
import {
  Datagrid,
  DateField,
  EditButton,
  SimpleShowLayout,
  SortPayload,
  TextField,
  useGetList,
  useRecordContext
} from 'react-admin'
import { RestrictedButton } from '../../components'
import { ConditionalTextField } from '../../components/fields/ConditionalTextField'
import { AppInstanceOut, AppOrganizationOut } from '../../types/ecoRequests'
import { getLocale } from '../../utils/getLocale'

const AppInstances: FC = () => {
  const isSmall = useMediaQuery('(min-width:600px)')
  const record = useRecordContext<AppOrganizationOut>()
  const [sort, setSort] = useState<SortPayload>({ field: 'id', order: 'ASC' })

  const { data, total, isLoading } = useGetList<AppInstanceOut>(
    'app_instance',
    {
      pagination: { page: 1, perPage: 100 },
      sort,
      filter: { appId: record?.appId, organizationId: record?.organizationId }
    }
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <Datagrid
            sort={sort}
            data={data}
            isLoading={isLoading}
            resource="app_instance"
            rowClick="show"
            selectedIds={[]}
            bulkActionButtons={false}
            setSort={() => setSort({ field: sort.field, order: sort.order || 'ASC' })}
            total={total}
          >
            <ConditionalTextField
              source="primaryHostname"
              label="data.hostname"
              emptyText="-"
              sortable={false}
            />
            <TextField source="description" label="data.description" />
            <DateField
              source="started"
              label="data.started"
              locales={getLocale()}
            />
            {!isSmall && (
              <RestrictedButton
                access={['eco.admin.organization:write']}
                align="right"
              >
                {(): JSX.Element => <EditButton resource="app_instance" label="" icon={<EditOutlinedIcon />} />}
              </RestrictedButton>
            )}
          </Datagrid>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  )
}

export default AppInstances
