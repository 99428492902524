import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { FC } from 'react'
import { FieldProps, useRecordContext } from 'react-admin'
import { border, xxllncColor } from '../../layout/themes'
import { InjectedFieldProps, PublicFieldProps } from './types'

const SHOW_BY_DEFAULT = 3

export const AppField: FC<AppFieldProps> = ({
  source,
  className
}) => {
  const record = useRecordContext()
  const apps = record?.[`${source}`] as string[]

  const getApps = (): JSX.Element | null => record ? (
    <>
      {apps && apps.slice(0, SHOW_BY_DEFAULT).map((item: string): JSX.Element => (
        <div className={classes.item}>{item}</div>
      ))}
      {apps && apps.length > SHOW_BY_DEFAULT &&
        <div className={classes.more}>
          {'+' + (apps.length - SHOW_BY_DEFAULT).toString()}
        </div>
      }
    </>
  ) : null

  return (
    <Root className={clsx(classes.root, className)}>
      {getApps()}
    </Root>
  )
}

AppField.propTypes = {
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
}

AppField.displayName = 'AppField'

interface AppFieldProps
    extends PublicFieldProps,
        Omit<InjectedFieldProps, 'record'>,
        FieldProps {
  className?: string;
  emptyText?: string;
  source: string;
  record?: any;
}

const PREFIX = 'xxllncAppField'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  more: `${PREFIX}-more`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.item}`]: {
    backgroundColor: xxllncColor.opDark10,
    border: border.thin + ' solid ' + xxllncColor.opDark10,
    borderRadius: border.radius.lg,
    fontSize: 14,
    marginRight: theme.spacing(1),
    padding: theme.spacing(.5, 1).toString(),
  },
  [`& .${classes.more}`]: {
    backgroundColor: xxllncColor.opDark10,
    border: border.thin + ' solid transparent',
    borderRadius: border.radius.lg,
    fontSize: 14,
    marginRight: theme.spacing(1),
    padding: theme.spacing(.5, 1)
  },
}))
