import { AppInstanceOut } from '../../types/ecoRequests'

export const mockAppInstance: AppInstanceOut[] = [
  {
    id: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056',
    appName: 'Competent',
    primaryHostname: 'Exxellence_Competent_1',
    description: 'Instance from app Competent for Exxellence',
    systemTags: [
      'one',
      'two'
    ],
    appId: '7f2e75aa-8a98-4685-915e-0f1918da94bd',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    started: '2000-05-10T10:07:11+00:00',
    created: '2021-08-04T13:43:54.604016+00:00',
    ended: undefined,
    lastModified: '2000-05-11T09:00:14+00:00',
  },
  {
    description: 'Instance from app contactmomenten for Exxellence',
    started: '2000-05-10T10:07:11+00:00',
    ended: undefined,
    systemTags: [
      'one',
      'two'
    ],
    appId: '57f9460f-a2a0-4bf0-ba9b-2040ad484f8b',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    created: '2021-08-04T13:43:54.604016+00:00',
    lastModified: '2000-05-11T09:00:14+00:00',
    id: 'd7213d40-6f76-4d2e-a04e-24fdacb52b54',
    appName: 'contactmomenten',
    primaryHostname: 'Exxellence_contactmomenten_1',
  },
  {
    description: 'Instance from app Koppel.app for Exxellence',
    started: '2000-05-10T10:07:11+00:00',
    ended: undefined,
    systemTags: [
      'one',
      'two'
    ],
    appId: '15cef401-4d6e-4d86-a04a-1511f4421ce8',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    created: '2021-08-04T13:43:54.604016+00:00',
    lastModified: '2000-05-11T09:00:14+00:00',
    id: '4fc07620-466d-4fad-a96c-e62403b43cd5',
    appName: 'Koppel.app',
    primaryHostname: 'Exxellence_Koppel.app_1',
  },
  {
    description: 'Instance from app Leerlingenvervoer for Exxellence',
    started: '2000-05-10T10:07:11+00:00',
    ended: undefined,
    systemTags: [
      'one',
      'two'
    ],
    appId: '10558303-8254-4aae-8203-ea66903fc331',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    created: '2021-08-04T13:43:54.604016+00:00',
    lastModified: '2000-05-11T09:00:14+00:00',
    id: '2d2d4523-7de5-4631-8a30-4b036e7a9a3a',
    appName: 'Leerlingenvervoer',
    primaryHostname: 'Exxellence_Leerlingenvervoer_1',
  },
  {
    description: 'Instance from app Terugbelverzoeken for Exxellence',
    started: '2000-05-10T10:07:11+00:00',
    ended: undefined,
    systemTags: [
      'one',
      'two'
    ],
    appId: 'cf41bfce-c22d-43e8-a2f1-3abef35e8981',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    created: '2021-08-04T13:43:54.604016+00:00',
    lastModified: '2000-05-11T09:00:14+00:00',
    id: '0d84539c-27a2-4b1c-84d0-d08feb96aebd',
    appName: 'Terugbelverzoeken',
    primaryHostname: 'Exxellence_Terugbelverzoeken_1',
  },
  {
    description: 'Instance from app Zaaksysteem for Exxellence',
    started: '2000-05-10T10:07:11+00:00',
    ended: undefined,
    systemTags: [
      'one',
      'two'
    ],
    appId: '42d06e6a-a49e-49f3-be38-3606169bedee',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    created: '2021-08-04T13:43:54.604016+00:00',
    lastModified: '2000-05-11T09:00:14+00:00',
    id: '10a3361b-e716-4a6f-b529-3948d78d8815',
    appName: 'Zaaksysteem',
    primaryHostname: 'Exxellence_Zaaksysteem_1',
  },
  {
    appId: 'd7f48ccf-6431-4f9a-bc64-6dd18e9560b5',
    appName: '4Value',
    created: '2021-09-19T11:53:14.562631+00:00',
    description: 'Instance from app 4Value for Exxellence',
    ended: undefined,
    systemTags: [
      'one',
      'two'
    ],
    id: '8f1579da-ef06-4ead-bd2b-e4c45582bc3c',
    lastModified: '2021-09-19T11:53:14.562631+00:00',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    primaryHostname: undefined,
    started: '2021-09-19T11:53:00+00:00',
  }
]
