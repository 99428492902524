import Typography, { TypographyProps } from '@mui/material/Typography'
import get from 'lodash/get'
import { ElementType, FC, memo } from 'react'
import { useRecordContext } from 'react-admin'
import { fieldPropTypes, InjectedFieldProps, PublicFieldProps, sanitizeFieldRestProps } from './types'

export const DataField: FC<DataFieldProps> = memo(props => {
  const { className, source, emptyText, ...rest } = props
  const record = useRecordContext()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const value = source ? get(record, source) as string : undefined

  const roundNumber = (data) => Math.round((Number(data) + Number.EPSILON) * 100) / 100

  if (!record || value == null || value.length <= 0 || value === undefined) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(props)}
      >
        {emptyText || '-'}
      </Typography>
    ) : null
  }

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      {value != null && typeof value !== 'string'
        ? `${roundNumber(JSON.stringify(value))}GB`
        : `${roundNumber(value)}GB` || emptyText}
    </Typography>
  )
})

DataField.propTypes = fieldPropTypes
DataField.displayName = 'DataField'

export interface DataFieldProps
    extends PublicFieldProps,
        InjectedFieldProps,
        Omit<TypographyProps, 'textAlign'> {
    // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
    component?: ElementType<any>;
}
