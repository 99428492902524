/* eslint-disable max-len */

export interface MemberOut {
  name: string;
  email: string;
  id: string;
  organizationId: string;
  organizationAuthId: string;

  /** @format uri */
  picture: string;
}

export const mockAuthMember: MemberOut[] = [
  {
    name: 'Frits Westers',
    email: 'frits@westers.nl',
    id: '51ad511c-5c46-4bc8-be07-3e661e510cdd',
    organizationId: '2d119b68-5b0c-4e98-ade6-c7e6f825f135',
    organizationAuthId: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056',
    picture: 'https://nl.wikipedia.org/wiki/Frits_Wester#/media/Bestand:Frits_Wester_2.jpg',
  },
  {
    name: 'Karin Bloemen',
    email: 'karin@bloemen.nl',
    id: 'df63e2f8-9704-41dd-af22-b023b15213b3',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    organizationAuthId: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056',
    picture: 'https://en.wikipedia.org/wiki/Karin_Bloemen#/media/File:Karin_Bloemen_tijdens_theatershow_Witte_Nar_in_2014,_Rob_Becker_(cropped).jpg',
  },
  {
    name: 'Gerard Joling',
    email: 'gerard@joling.nl',
    id: '08f71f6e-6957-11ec-90d6-0242ac120003',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    organizationAuthId: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056',
    picture: 'https://en.wikipedia.org/wiki/Karin_Bloemen#/media/File:Karin_Bloemen_tijdens_theatershow_Witte_Nar_in_2014,_Rob_Becker_(cropped).jpg',
  },
]
