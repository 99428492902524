import { styled } from '@mui/material/styles'
import { border, xxllncColor } from '../../layout/themes'

export const EmptyContainer = ({ children }: ContainerProps): JSX.Element => (
  <Root className={classes.root}>
    {children}
  </Root>
)

interface ContainerProps {
  children: JSX.Element;
}

const PREFIX = 'xxllncEmptyContainer'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: xxllncColor.light100,
    borderRadius: border.radius.md,
    width: '100%',
  },
}))
