import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { Button, ButtonProps, useCreatePath, useRecordContext, useResourceContext } from 'react-admin'
import { Link } from 'react-router-dom'

export const LinkRecordButton = (props: LinkRecordButtonProps): JSX.Element | null => {
  const {
    label = 'actions.link',
    source,
    ...rest
  } = props
  const resource = useResourceContext(props)
  const record = useRecordContext(props)
  const createPath = useCreatePath()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const target = get(record, source) as string

  if (!record) return null

  return (
    <Button
      component={Link}
      to={createPath({ type: 'edit', resource, id: record.id })}
      label={label}
      onClick={stopPropagation}
      {...(rest)}
    >
      {target ? <LinkIcon/> : <LinkOffIcon/>}
    </Button>
  )
}

// useful to prevent click bubbling in a datagrid with rowClick
// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
const stopPropagation = e => e.stopPropagation()

interface Props {
  basePath?: string;
  label?: string;
  source: string;
}

type LinkRecordButtonProps = Props & ButtonProps & MuiButtonProps;

LinkRecordButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
}
