import { Typography } from '@mui/material'
import get from 'lodash/get'
import { FieldProps, RaRecord, useRecordContext } from 'react-admin'
import { fieldPropTypes, sanitizeFieldRestProps } from './types'

export const SvgField = (props: SvgFieldProps): JSX.Element => {
  const {
    emptyText,
    className,
    source,
    record = useRecordContext()
  } = props
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const image = typeof record === 'string' ? record : get(record, source) as string

  if (image !== null && image !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const value = typeof image === 'string' ? image : get(image, source) as string
    const sanitizedImage = value && value.indexOf('blob:') === 0 ? value : `data:image/svg+xml;base64,${value}`
    return <img className={className} src={sanitizedImage} />
  }

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      {...sanitizeFieldRestProps(props)}
    >
      {emptyText}
    </Typography>
  )
}

SvgField.propTypes = fieldPropTypes
SvgField.displayName = 'SvgField'

interface SvgFieldProps extends FieldProps {
  source: string;
  record?: RaRecord;
}
