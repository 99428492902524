import { FC } from 'react'
import { styled } from '@mui/material/styles'
import { Button, useRecordContext, useTranslate } from 'react-admin'
import { xxllncColor } from '../../../layout/themes'
import { AppOverview } from '../../../types/ecoRequests'
import { openUrl } from '../../../utils/openUrl'
import { Typography } from '@mui/material'

const AppShowButtons: FC = () => {
  const translate = useTranslate()
  const record = useRecordContext<AppOverview>()

  return (
    <Root className={classes.root}>
      {record?.isActiveForMyOrganization &&
          <Typography
            variant="caption"
            component="span"
            className={classes.tag}
          >
            {translate('actions.active')}
          </Typography>
      }
      {!record?.isActiveForMyOrganization && record?.demoUrl &&
          <Button
            onClick={() => openUrl(record.demoUrl)}
            label="resources.app_overview.information"
          />
      }
      {!record?.isActiveForMyOrganization && record?.orderUrl &&
          <Button
            onClick={() => openUrl(record.orderUrl)}
            label="resources.app_overview.demo"
            variant="contained"
          />
      }
    </Root>
  )
}

const PREFIX = 'xxllncAppShowButtons'

const classes = {
  root: `${PREFIX}-root`,
  demo: `${PREFIX}-demo`,
  spacer: `${PREFIX}-spacer`,
  tag: `${PREFIX}-tag`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
  },
  [`& .${classes.demo}`]: {
    color: xxllncColor.dark60,
  },
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(2),
  },
  [`& .${classes.tag}`]: {
    color: xxllncColor.dark40,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '10px',
    marginLeft: theme.spacing(1),
    padding: '10px 20px',
  },
}))

export default AppShowButtons
