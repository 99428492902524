import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Grid } from '@mui/material'
import { FC } from 'react'
import {
  Datagrid,
  DeleteButton,
  EditButton,
  SimpleShowLayout,
  SortPayload,
  TextField,
  useGetList,
  useRecordContext,
  useTranslate
} from 'react-admin'
import { Empty, LocationCreateButton, RestrictedButton } from '../../components'
import { AppOut, AppReportOut } from '../../types/ecoRequests'

export const AppReport: FC = () => {
  const translate = useTranslate()
  const appRecord = useRecordContext<AppOut>()
  const search = `?appId=${appRecord?.id}`
  const sort: SortPayload = { field: 'name', order: 'ASC' }

  const { data, total, isLoading } = useGetList<AppReportOut>(
    'app_report',
    {
      pagination: { page: 1, perPage: 99 },
      sort,
      filter: { appId: appRecord?.id }
    }
  )

  return !isLoading ? (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <Datagrid
            sort={sort}
            data={data}
            selectedIds={[]}
            isLoading={isLoading as boolean}
            bulkActionButtons={false}
            total={total}
            empty={
              <Empty
                resource="app_report"
                createButton={
                  <LocationCreateButton
                    access={['eco.admin.app:write']}
                    align="center"
                    resource="app_report"
                    search={search}
                    label="actions.report"
                    disabled={!appRecord}
                  />
                }
              />
            }
          >
            <TextField source="name" label="data.name" sortable={false} />
            <TextField source="url" label="data.url" sortable={false} />
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <RestrictedButton
                access={['eco.admin.app:write']}
                align="right"
              >
                {(): JSX.Element | null => {
                  const record = useRecordContext<AppReportOut>()

                  if (!record) return null

                  return (
                    <DeleteButton
                      redirect={`/app/${appRecord?.id}/show/report`}
                      mutationMode="pessimistic"
                      record={record}
                      resource="app_report"
                      confirmTitle={translate('message.deleteTitle', { name: record.name })}
                      label=""
                      icon={<DeleteOutlinedIcon />}
                    />
                  )
                }}
              </RestrictedButton>
              <RestrictedButton
                access={['eco.admin.app:write']}
                align="right"
              >
                {(): JSX.Element | null => {
                  const record = useRecordContext<AppReportOut>()

                  if (!record) return null

                  return (
                    <EditButton
                      record={record}
                      resource="app_report"
                      label=""
                      icon={<EditOutlinedIcon />}
                    />
                  )
                }}
              </RestrictedButton>
            </div>
          </Datagrid>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  ) : <></>
}
