import { Grid } from '@mui/material'
import { FC } from 'react'
import { TextInput } from 'react-admin'
import { ChipInput } from '../../components'
import { ActivateForm } from '../../components/ActivateForm'
import { required } from '../../utils/validation'

const AppOrganizationForm: FC = () => (
  <>
    <Grid item xs={12} sm={6}>
      <TextInput
        helperText=""
        isRequired
        label="data.totalInstanceSoftLimit"
        source="totalInstanceSoftLimit"
        validate={required()}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextInput
        helperText=""
        isRequired
        label="data.totalStorageSoftLimit"
        source="totalStorageSoftLimit"
        validate={required()}
      />
    </Grid>
    <Grid item xs={12}>
      <ChipInput
        helperText=""
        label="data.appOrganizationSystemTags"
        source="systemTags"
      />
    </Grid>
    <ActivateForm description="app" />
  </>
)

export default AppOrganizationForm
