import { ResponsivePie } from '@nivo/pie'
import { FC } from 'react'
import { AppOut } from '../../types/ecoRequests'

interface Props {
  apps: AppOut[] | undefined
}

const InstancesPerTeamPie: FC<Props> = ({ apps }) => {
  const processData = () => {
    if (!apps) return []

    const categoryCounts = apps.reduce((acc, app) => {
      const { category } = app
      if (category) {
        // eslint-disable-next-line security/detect-object-injection
        acc[category] = (acc[category] || 0) + 1
      }
      return acc
    }, {} as Record<string, number>)

    return Object.entries(categoryCounts).map(([key, value]) => ({
      id: key,
      label: key,
      value,
    }))
  }

  const data = processData()

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      colors={{ scheme: 'blues' }}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]]
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor="#31679B"
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            2
          ]
        ]
      }}
      defs={[]}
      fill={[]}
      legends={[]}
    />
  )
}


export default InstancesPerTeamPie