import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Tab, TabProps, usePermissions } from 'react-admin'
import { IPermissions } from '../../types/ecoTypes'

export const RestrictedTab = ({
  access,
  children,
  path,
  ...rest
}: RestrictedTabProps): JSX.Element | null => {
  const { permissions } = usePermissions() as IPermissions

  if (permissions !== undefined && access.some((i: string) => permissions?.scopes.includes(i))) {
    return (
      <Root
        path={path}
        className={classes.root}
        {...rest}
      >
        {children}
      </Root>
    )
  }

  return null
}

RestrictedTab.propTypes = {
  access: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.string,
}

interface RestrictedTabProps extends TabProps {
  access: string[];
  path?: string;
}

const PREFIX = 'xxllncRestrictedTab'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled(Tab)(({ theme }) => ({
  [`&.${classes.root}`]: {},
}))
