import BusinessIcon from '@mui/icons-material/Business'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { Box, Grid } from '@mui/material'
import { useGetIdentity, useGetList, useGetOne, useRedirect } from 'react-admin'
import { AppInstanceOut, AppOut } from '../../types/ecoRequests'
import ActiveInstancesBar from './ActiveInstances.barchart'
import InstancesPerTeamPie from './InstancesPerTeams.piechart'
import NewInstancesChart from './NewInstances.linechart'
import RecentLogs from './Recent.logs'
import StatBox from './StatBox'

interface Stats {
  id: string,
  activeInstances: number,
  newInstances: number,
  activeOrganizations: number,
}

const AdminDashboard = () => {
  const redirect = useRedirect()
  const { data } = useGetIdentity()
  const { data: appInstances } = useGetList<AppInstanceOut>('app_instance')
  const { data: apps } = useGetList<AppOut>('app')
  const { data: statsData } = useGetOne<Stats>(
    'stats',
    { id: (data as { id: string })?.id }
  )

  return (
    <Box >

      <Grid container columnSpacing={2}>

        <Grid item xs={12} sm={12} md={4} zeroMinWidth onClick={() => redirect('list', 'app_instance')}>
          <StatBox
            title="app_instances"
            subtitle="active"
            increase="+14%"
            icon={<IntegrationInstructionsIcon sx={{ color: '#31679B', fontSize:'28px'}}/>}
            amount={statsData?.activeInstances}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} zeroMinWidth onClick={() => redirect('list', 'app_instance')}>
          <StatBox
            title="app_instances"
            subtitle="new"
            increase="+14%"
            icon={<ReceiptIcon sx={{ color: '#31679B', fontSize:'30px'}}/>}
            amount={statsData?.newInstances}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} zeroMinWidth onClick={() => redirect('list', 'organization')}>
          <StatBox
            title="organizations"
            subtitle="active"
            increase="+14%"
            icon={<BusinessIcon sx={{ color: '#31679B', fontSize:'28px'}}/>}
            amount={statsData?.activeOrganizations}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} marginTop='-16px'>
        <Grid item container spacing={2} xs={12} md={8} display='flex' flexDirection='row'>
          <Grid item xs={12} >
            <Box width="100%" height="300px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
              <NewInstancesChart appInstances={appInstances} />
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '-16px' }}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '-16px' }}>
                  <Box width="100%" height="320px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
                    <ActiveInstancesBar appInstances={appInstances} apps={apps} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '-16px' }}>
                  <Box width="100%" height="320px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
                    <InstancesPerTeamPie apps={apps} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid item container spacing={2} xs={12} md={4} display='flex' flexDirection='column'>
          <Grid item sx={{ height: '100%' }}>
            <Box width="100%" height="100%" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
              <RecentLogs />
            </Box>
          </Grid>
        </Grid>
      </Grid>

    </Box>
  )
}

export default AdminDashboard