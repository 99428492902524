import { FC } from 'react'
import { useRecordContext } from 'react-admin'
import {
  AdfsCreate,
  AdfsEdit,
  Auth0Create,
  Auth0Edit,
  GoogleAppsCreate,
  GoogleAppsEdit,
  Oauth2Create,
  Oauth2Edit,
  WaadCreate,
  WaadEdit
} from './options'

interface AuthConnectionsOptionsProps {
  type?: string
}

const AuthConnectionOptions: FC<AuthConnectionsOptionsProps> = ({ type }) => {
  const record = useRecordContext<{ strategy: string }>()
  const optionsType = type || record?.strategy

  switch (optionsType) {
    case 'waad': return !type ? <WaadEdit /> : <WaadCreate />
    case 'adfs': return !type ?<AdfsEdit /> : <AdfsCreate />
    case 'auth0': return !type ? <Auth0Edit /> : <Auth0Create />
    case 'oauth2': return !type ? <Oauth2Edit /> : <Oauth2Create />
    case 'googleapps': return !type ? <GoogleAppsEdit /> : <GoogleAppsCreate />
  }
  return <></>
}

export default AuthConnectionOptions
