import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { BulkDeleteButton, BulkDeleteWithConfirmButtonProps, usePermissions } from 'react-admin'
import { IPermissions } from '../../types/ecoTypes'

export const ListActionBulkDelete = ({
  access,
  mutationMode='pessimistic',
  ...props
}: ListActionBulkDeleteProps): JSX.Element | false => {
  const { permissions } = usePermissions() as IPermissions

  if (permissions !== undefined && access.some((i: string) => permissions?.scopes.includes(i))) {
    return <BulkDeleteButton {...props} mutationMode={mutationMode} icon={<DeleteOutlinedIcon />} />
  }

  return false
}

interface ListActionBulkDeleteProps extends BulkDeleteWithConfirmButtonProps {
  access: string[];
}
