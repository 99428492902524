import GetAppIcon from '@mui/icons-material/GetApp'
import { Button } from '@mui/material'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { getUrl } from '../../providers/data'
import authToken from '../../utils/authToken'

interface DownloadButtonProps {
  fileName?: string | null
  fileUrl?: string | null
}

export const DownloadFileButton: FC<DownloadButtonProps> = ({ fileName, fileUrl }) => {
  const translate = useTranslate()

  const handleDownload = async () => {
    if  (!fileName) return
    try {
      const accessToken = authToken.getToken() || ''
      const requestOptions = {
        headers: new Headers({
          Authorization: accessToken,
        }),
        responseType: 'blob',
      }

      const response = await fetch(`${getUrl()}/${fileUrl}`, requestOptions)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      a.remove()
    } catch (error) {
      console.error('File download error:', error)
    }
  }

  return (
    <Button variant="text" startIcon={<GetAppIcon />} onClick={() => void handleDownload()}>
      { translate('data.download') }
    </Button>
  )
}
