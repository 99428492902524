import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { FilterList, FilterListItem } from 'react-admin'
import { AppCategories } from '../../types/ecoRequests'

export const DomainFilter: FC = () => (
  <StyledFilterList className={classes.root} label="filters.domain" icon={undefined}>
    {Object.keys(AppCategories).map((keyName, i): JSX.Element => (
      <FilterListItem
        className={`${classes.item} ${AppCategories[`${keyName}`] as string}`}
        key={i}
        label={`domains.${AppCategories[`${keyName}`] as string}`}
        value={{
          category: AppCategories[`${keyName}`] as string,
        }}
      />
    ))}
  </StyledFilterList>
)

const PREFIX = 'xxllncDomainFilter'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
}

const StyledFilterList = styled(FilterList)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    marginBottom: '20px',
  },
  [`& .${classes.item}`]: {
    border: '1px solid #E0E0E0',
    marginRight: '10px',
    position: 'relative',
    borderRadius: '50px',
    ['&::before']: {
      content: '""',
      position: 'absolute',
      left: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: '#31679B',
    },
    ['& span']: {
      paddingLeft: '10px',
    },
  },
  '& .MuiBox-root': {
    display: 'none',
  },
  '& .MuiList-root': {
    display: 'flex',
    flexDirection: 'row',
  },
  [`& .${classes.item}.${AppCategories.Belastingen}::before`]: {
    backgroundColor: '#31679B',
  },
  [`& .${classes.item}.${AppCategories.Omgeving}::before`]: {
    backgroundColor: '#006450',
  },
  [`& .${classes.item}.${AppCategories.Productiviteit}::before`]: {
    backgroundColor: '#E15B32',
  },
  [`& .${classes.item}.${AppCategories.Sociaal}::before`]: {
    backgroundColor: '#F0C952',
  },
  [`& .${classes.item}.${AppCategories.ZaakgerichtWerken}::before`]: {
    backgroundColor: '#E59925',
  },
  ['& .MuiSvgIcon-root']: {
    width: '16px',
    height: '16px',
  },
}))
