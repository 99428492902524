import { FC } from 'react'
import {
  ListProps,
  Datagrid,
  TextField,
} from 'react-admin'
import CustomList from '../../components/list/CustomList'

const contractList: FC<ListProps> = (props) => (
  <CustomList
    {...props}
    title="resources.contracts.list"
    sort={{ field: 'id', order: 'ASC' }}
    exporter={false}
  >
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField
        source="name"
        label="data.name"
        sortable={false}
      />
      <TextField
        source="domain"
        label="data.team"
        emptyText="-"
        sortable={false}
      />
      <TextField
        source="status"
        label="data.status"
        sortable={false}
      />
    </Datagrid>
  </CustomList>
)

export default contractList
