import CallMade from '@mui/icons-material/CallMade'
import { Link, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import get from 'lodash/get'
import { AnchorHTMLAttributes, FC, memo } from 'react'
import { useRecordContext } from 'react-admin'
import { fieldPropTypes, InjectedFieldProps, PublicFieldProps, sanitizeFieldRestProps } from './types'

const PREFIX = 'xxllncLinkField'

const classes = {
  link: `${PREFIX}-link`,
  icon: `${PREFIX}-icon`,
}

const StyledLink = styled(Link)(({ theme }) => ({
  [`& .${classes.link}`]: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
  },
  [`& .${classes.icon}`]: {
    marginLeft: theme.spacing(.4),
    height: 14,
    width: 14,
  },
}))

export const LinkField: FC<LinkFieldProps> = memo(props => {
  const { className, emptyText, source, ...rest } = props
  const record = useRecordContext()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const value = source ? get(record, source) as string : undefined
  const hasPrefix = value ? value.indexOf('http://') === 0 || value.indexOf('https://') === 0 : undefined

  if (!record || value == null || value.length <= 0 || value === undefined) {
    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText || '-'}
      </Typography>
    )
  }

  return (
    <StyledLink
      className={className}
      href={hasPrefix ? value : 'https://' + value}
      variant="body2"
      {...sanitizeFieldRestProps(rest)}
    >
      <span className={classes.link}>
        {hasPrefix ? value : 'https://' + value}
        <CallMade className={classes.icon} />
      </span>
    </StyledLink>
  )
})

LinkField.propTypes = fieldPropTypes
LinkField.displayName = 'LinkField'

export interface LinkFieldProps
    extends PublicFieldProps,
        InjectedFieldProps,
        AnchorHTMLAttributes<HTMLAnchorElement> {}
