import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FC } from 'react'
import { CreateButton, Datagrid, EditButton, Empty } from 'react-admin'
import { EmptyContainer, ListActionsToolbar, RestrictedButton } from '../../components'
import { ConditionalTextField } from '../../components/fields/ConditionalTextField'
import CustomList from '../../components/list/CustomList'

const ManageAppList: FC = () => (
  <CustomList
    title='resources.app.title'
    sort={{ field: 'name', order: 'ASC' }}
    exporter={false}
    actions={
      <ListActionsToolbar i18n="resources.app.list">
        <RestrictedButton
          access={['eco.admin.app:write']}
          align="right"
        >
          {(): JSX.Element => <CreateButton variant="contained" />}
        </RestrictedButton>
      </ListActionsToolbar>
    }
    empty={
      <EmptyContainer>
        <Empty />
      </EmptyContainer>
    }
  >
    <Datagrid
      bulkActionButtons={false}
      rowClick="show"
    >
      <ConditionalTextField source="name" label="data.name" />
      <RestrictedButton
        access={['eco.admin.app:write']}
        align="right"
      >
        {(): JSX.Element => <EditButton label="" icon={<EditOutlinedIcon />} />}
      </RestrictedButton>
    </Datagrid>
  </CustomList>
)

export default ManageAppList
