import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  Button, Datagrid, Empty,
  List, ReferenceField, TextField
} from 'react-admin'
import { Link } from 'react-router-dom'
import {
  EmptyContainer, ListActionsToolbar,
  RestrictedButton
} from '../../components'
import { DeleteAuthMemberRoleButton } from '../../components/buttons'

const AuthMemberRoleList: FC = () => {
  const search = new URLSearchParams(location?.search)
  const authMemberId = search.get('authMemberId') as string

  return (
    <StyledList
      exporter={false}
      empty={
        <EmptyContainer>
          <Empty />
        </EmptyContainer>
      }
      actions={
        <ListActionsToolbar i18n="resources.auth_member.list">
          <RestrictedButton
            access={['eco.admin.member:write', 'eco.user.member:write']}
            align="right"
            className={classes.spacer}
          >
            {(): JSX.Element => (
              <Button
                component={Link}
                to={{
                  pathname: '/auth_member_roles/create',
                  search: `?authMemberId=${authMemberId}`,
                }}
                label="actions.create"
                variant="contained"
              >
                <AddIcon />
              </Button>
            )}
          </RestrictedButton>
        </ ListActionsToolbar>
      }
      filterDefaultValues={{ authMemberId }}
    >
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="name" label="data.name" />
        <ReferenceField source="appId" reference="app">
          <TextField source="name" />
        </ReferenceField>
        <DeleteAuthMemberRoleButton authMemberId={authMemberId} />
      </Datagrid>
    </StyledList>
  )
}

const PREFIX = 'xxllncAuthMemberRoleList'

const classes = {
  smallCell: `${PREFIX}-smallCell`,
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.smallCell}`]: {
    paddingRight: 0,
    width: 24,
  },
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default AuthMemberRoleList
