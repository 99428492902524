import { Grid } from '@mui/material'
import { FC } from 'react'
import { Create, SimpleForm } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { connectionsTransform } from '../../utils/connectionsTransform'
import AuthConnectionForm from './AuthConnectionForm'

const AuthConnectionCreate: FC = () => {
  const search = new URLSearchParams(location?.search)
  const organizationAuthId = search.get('organizationAuthId')
  const organizationName = search.get('organizationName')
  const redirect = organizationAuthId ? `/organization_auth/${organizationAuthId}/show/signOn` : undefined

  return (
    <Create
      title={<HeaderTitle i18n="resources.auth_connection.create" />}
      actions={<ActionsToolbar i18n="resources.auth_connection.create" />}
      redirect={redirect}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      transform={connectionsTransform}
    >
      <SimpleForm
        defaultValues={{ organizationAuthId }}
        sanitizeEmptyValues
      >
        <Grid container spacing={2}>
          <AuthConnectionForm view="create" organizationName={organizationName || undefined} />
        </Grid>
      </SimpleForm>
    </Create>
  )
}

export default AuthConnectionCreate
