import { IReview } from '../mockTypes'

export const mockReviews: IReview[] = [
  {
    id: '1',
    user: 'Tessa Tester',
    text: 'Review text voor zaaksysteem.nl',
    date: '2021-04-13 17:23:12',
    appId: '1',
  },
  {
    id: '2',
    user: 'W Jansen',
    text: 'Andere tekst voor zaaksysteem.nl',
    date: '2021-04-13 17:23:12',
    appId: '1',
  },
  {
    id: '3',
    user: 'Karel Appel',
    text: 'Review voor koppel.app',
    date: '2021-04-13 17:23:12',
    appId: '2',
  },
]
