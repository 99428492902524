import { Grid } from '@mui/material'
import { Create, CreateProps, SelectInput, SimpleForm, useGetList, usePermissions } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { AuthConnectionOut } from '../../types/ecoRequests'
import { IPermissions } from '../../types/ecoTypes'
import { required } from '../../utils/validation'
import AuthMemberForm from './AuthMemberForm'

const AuthMemberCreate = (props: CreateProps): JSX.Element => {
  const { permissions } = usePermissions() as IPermissions
  const { resource } = props
  const search = new URLSearchParams(location?.search)
  const organizationAuthId = search.get('organizationAuthId') as string

  const redirect = (): string | undefined => {
    if (permissions !== undefined && permissions.scopes?.some((i: string) => i.includes('eco.admin'))) {
      return organizationAuthId ? `/organization_auth/${organizationAuthId}/show/member` : undefined
    } else {
      return resource ? `/${resource}` : '/'
    }
  }

  const { data, isLoading } = useGetList<AuthConnectionOut>(
    'auth_connection',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: { organizationAuthId }
    }
  )

  const choices = data ? data.map((record: Choices) => ({
    id: record.id.substring(0, record.id.indexOf('|')) ? record.id.substring(0, record.id.indexOf('|')) : record.id,
    name: record.name
  })) : []

  return !isLoading ? (
    <Create
      actions={<ActionsToolbar i18n="resources.auth_member.create" />}
      redirect={redirect()}
      title={<HeaderTitle i18n="resources.auth_member.create" />}
    >
      <SimpleForm
        defaultValues={{ organizationAuthId }}
        sanitizeEmptyValues
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectInput
              choices={choices.sort()}
              helperText=""
              label="data.connectionType"
              source="connectionId"
              validate={required()}
              isRequired
            />
          </Grid>
          <AuthMemberForm />
        </Grid>
      </SimpleForm>
    </Create>
  ) : <></>
}

interface Choices {
  id: string;
  name: string;
}

export default AuthMemberCreate
