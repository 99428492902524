import { createTheme } from '@mui/material'
import { border } from './border'
import { button } from './button'
import { color, xxllncColor } from './color'
import { RaGlobalRoot } from './global'
import { typography } from './typography'

const material = createTheme()

export const raOverrides = {
  RaLayout: {
    styleOverrides: {
      root: {
        fontFamily: typography.headline,
        backgroundColor: xxllncColor.base,
        ['& .RaLayout-content']: {
          backgroundColor: xxllncColor.base,
          [material.breakpoints.down('sm')]: {
            padding: 0,
          },
          [material.breakpoints.up('sm')]: {
            margin: material.spacing(6),
          },
          [material.breakpoints.up('xs')]: {
            marginTop: material.spacing(9),
          },
          [material.breakpoints.down('xs')]: {
            marginTop: material.spacing(7),
          },
        },
        ['& .RaLayout-appFrame']: {
          marginTop: 0,
        },
      },
    },
  },
  RaSidebarToggleButton: {
    styleOverrides: {
      root: {
        [material.breakpoints.up('sm')]: {
          display: 'none',
          ['& .RaSidebarToggleButton-menuButtonIconOpen']: {
            fill: xxllncColor.dark80,
          },
          ['& .RaSidebarToggleButton-menuButtonIconClosed']: {
            fill: xxllncColor.dark80,
          },
        },
      },
    },
  },
  RaList: {
    styleOverrides: {
      root: {
        [material.breakpoints.up('md')]: {
          maxWidth: '80%',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '100%',
        },
        ['& .RaList-content']: {
          backgroundColor: xxllncColor.base,
          marginBottom: 0,
        },
        ['& .RaList-bulkActionsDisplayed']: {
          marginTop: 0,
        },
      },
    },
  },
  RaShow: {
    styleOverrides: {
      root: {
        ...RaGlobalRoot,
        ['& .RaShow-noActions']: {
          marginTop: 0,
        },
        ['& .RaShow-main']: {
          ['& .show-tab']: {
            fontSize: 14,
            textTransform: 'initial',
            minWidth: 'auto',
          },
        },
        ['& .RaShow-card']: {
          [material.breakpoints.down('sm')]: {
            backgroundColor: xxllncColor.base,
          },
          [material.breakpoints.up('xs')]: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        },
      },
    },
  },
  RaTabbedShowLayout: {
    styleOverrides: {
      root: {
        ['& .RaTabbedShowLayout-content']: {
          [material.breakpoints.down('md')]: {
            borderTopLeftRadius: border.radius.md,
            borderTopRightRadius: border.radius.md,
            backgroundColor: color.white,
          },
          [material.breakpoints.down('lg')]: {
            padding: material.spacing(0, 3, 3, 3),
          },
          [material.breakpoints.up('md')]: {
            padding: material.spacing(0, 6, 3, 6),
          },
        },
      },
    },
  },
  RaSidebar: {
    styleOverrides: {
      root: {
        backgroundColor: color.primary.dark,
        height: 'auto',
        ['& .RaSidebar-fixed']: {
          height: '100%',
          borderRight: border.thin + ' solid ' + border.light,
          position: 'relative',
          paddingTop: 12,
        },
      },
    },
  },
  RaMenuItemLink: {
    styleOverrides: {
      root: {
        borderRadius: border.radius.md,
        padding: (props: { sidebarIsOpen?: boolean }): any =>
          props.sidebarIsOpen ? material.spacing(2) : 12,
        marginBottom: material.spacing(1),
        height: 48,
        [material.breakpoints.up('sm')]: {
          color: xxllncColor.light100,
        },
        fontSize: 15,
        fontWeight: 400,
        lineHeight: '25px',
      },
    },
  },
  RaTopToolbar: {
    styleOverrides: {
      root: {
        boxSizing: 'border-box',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        paddingBottom: material.spacing(3.5),
        paddingTop: 0,
        width: '100%',
        [material.breakpoints.down('sm')]: {
          backgroundColor: 'transparent',
          padding: material.spacing(1, 1.5),
        },
        [material.breakpoints.up('sm')]: {
          padding: material.spacing(1.5, 0),
        },
      },
    },
  },
  RaBulkActionsToolbar: {
    styleOverrides: {
      root: {
        ['& .RaBulkActionsToolbar-topToolbar']: {
          padding: 0,
          paddingTop: 0,
          display: 'flex',
          alignItems: 'center',
        },
        ['& .RaBulkActionsToolbar-toolbar']: {
          padding: material.spacing(0, 1.5, 0, 1.5),
          backgroundColor: xxllncColor.light100,
          borderRadius: border.radius.md,
        },
        ['& .RaBulkActionsToolbar-icon']: {
          marginLeft: 0,
        },
      },
    },
  },
  RaCreate: {
    styleOverrides: {
      root: {
        ...RaGlobalRoot,
        ['& .RaCreate-card']: {
          [material.breakpoints.down('sm')]: {
            backgroundColor: xxllncColor.base,
          },
          ['& .MuiGrid-container']: {
            marginTop: material.spacing(-2),
          },
          ['& .MuiCardContent-root']: {
            paddingTop: 0,
            paddingLeft: material.spacing(3),
            paddingRight: material.spacing(3),
            ['&:first-child']: {
              paddingTop: 0,
              paddingLeft: material.spacing(3),
              paddingRight: material.spacing(3),
              [material.breakpoints.down('sm')]: {
                paddingTop: material.spacing(3.5),
                paddingLeft: '.2em',
                paddingRight: '.2em',
              },
            },
          },
        },
      },
    },
  },
  RaEdit: {
    styleOverrides: {
      root: {
        ...RaGlobalRoot,
        ['& .RaEdit-card']: {
          [material.breakpoints.down('sm')]: {
            backgroundColor: xxllncColor.base,
          },
          ['& .MuiGrid-container']: {
            marginTop: material.spacing(-2),
          },
          ['& .MuiCardContent-root']: {
            paddingTop: 0,
            paddingLeft: material.spacing(3),
            paddingRight: material.spacing(3),
            ['&:first-child']: {
              paddingTop: 0,
              paddingLeft: material.spacing(3),
              paddingRight: material.spacing(3),
              [material.breakpoints.down('sm')]: {
                paddingTop: material.spacing(3.5),
                paddingLeft: '.2em',
                paddingRight: '.2em',
              },
            },
          },
        },
      },
    },
  },
  RaFormInput: {
    styleOverrides: {
      root: {
        ['& .RaFormInput-input']: {
          width: '100%',
        },
      },
    },
  },
  RaFileInput: {
    styleOverrides: {
      root: {
        ['& .RaFileInput-dropZone']: {
          backgroundColor: xxllncColor.light20,
          border: border.normal + ' dashed ' + border.light,
          borderRadius: border.radius.md,
          color: xxllncColor.dark60,
          padding: material.spacing(4),
        },
        ['& .RaFileInput-preview']: {
          padding: '12px',
          paddingLeft: 0,
          width: '48px',
          maxHeight: '48px',
        },
        ['& .RaFileInput-removeButton']: {
          display: 'flex',
          background: xxllncColor.base,
          borderRadius: border.radius.md,
          boxSizing: 'border-box',
          margin: 0,
        },
      },
    },
  },
  RaFileInputPreview: {
    styleOverrides: {
      root: {
        padding: material.spacing(1.5),
        ['& .RaFileInputPreview-removeIcon']: {
          color: xxllncColor.dark80
        },
      },
    },
  },
  RaLabeled: {
    styleOverrides: {
      root: {
        ['& .RaLabeled-label']: {
          textTransform: 'uppercase',
          fontSize: 12,
        },
        ['& .RaLabeled-value']: {
          ['& .previews']: {
            display: 'flex'
          },
        },
      },
    },
  },
  RaFilterListItem: {
    styleOverrides: {
      root: {
        marginBottom: material.spacing(.5),
        ['& .RaFilterListItem-listItemButton']: {
          paddingLeft: material.spacing(2),
        },
      },
    },
  },
  RaButton: {
    styleOverrides: {
      root: {
        flexDirection: 'row-reverse',
        height: 30,
        padding: 20,
        textTransform: 'initial',
        ['&.MuiButton-contained']: {
          backgroundColor: button.primary.default.backgroundColor,
          color: button.primary.default.color,
          ['&:hover']: {
            backgroundColor: button.primary.hover.backgroundColor,
            color: button.primary.hover.color,
          },
        },
        ['&.Mui-disabled']: {
          backgroundColor: button.primary.disabled.backgroundColor,
          color: button.primary.disabled.color,
        },
        ['& .RaButton-button']: {
          padding: material.spacing(.75, 1),
        },
        ['& .RaButton-label']: {
          padding: material.spacing(0, 1),
        },
        ['& .RaButton-labelRightIcon']: {
          padding: material.spacing(0, 1),
        },
      },
    },
  },
  RaSaveButton: {
    styleOverrides: {
      root: {
        marginLeft: material.spacing(2),
        ['&.MuiButton-contained']: {
          backgroundColor: button.primary.default.backgroundColor,
          color: button.primary.default.color,
          ['&:hover']: {
            backgroundColor: button.primary.hover.backgroundColor,
            color: button.primary.hover.color,
          },
        },
        ['&.Mui-disabled']: {
          backgroundColor: button.primary.disabled.backgroundColor,
          color: button.primary.disabled.color,
        },
      },
    },
  },
  RaBulkDeleteWithConfirmButton: {
    styleOverrides: {
      root: {
        ['& .RaBulkDeleteWithConfirmButton-deleteButton']: {
          backgroundColor: button.delete.default.background,
          padding: button.delete.default.padding,
          color: button.delete.default.color,
          ['& .MuiSvgIcon-root']: {
            fill: button.delete.default.fill,
          },
          ['&:hover']: {
            backgroundColor: button.delete.hover.background,
          },
        },
      },
    },
  },
  RaCreateButton: {
    styleOverrides: {
      root: {
        ['& .RaCreateButton-floating']: {
          bottom: 20,
        },
      },
    },
  },
  RaDatagrid: {
    styleOverrides: {
      root: {
        ['& .RaDatagrid-headerCell']: {
          backgroundColor: xxllncColor.light100,
          borderTop: 0,
          boxSizing: 'border-box',
          color: xxllncColor.dark100,
          fontSize: 12,
          fontWeight: 600,
          padding: '6px 12px',
          ['&:first-of-type']: {
            borderTopLeftRadius: 0,
          },
          ['&:last-of-type']: {
            borderTopRightRadius: 0,
          },
        },
      },
      ['& .RaDatagrid-row']: {
        ['&:hover']: {
          ['& .column-changeType']: {
            ['& .MuiSvgIcon-root']: {
              backgroundColor: xxllncColor.dark80,
              color: xxllncColor.light100,
            },
          },
        },
      },
      ['& .RaDatagrid-rowCell']: {
        borderBottom: border.thin + ' solid ' + border.light,
        color: xxllncColor.dark80,
        padding: material.spacing(1.5),
        ['&:last-child']: {
          padding: material.spacing(1.5),
        },
      },
    },
  },
  RaImageField: {
    styleOverrides: {
      root: {
        ['& .RaImageField-image']: {
          margin: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  RaCardContentInner: {
    styleOverrides: {
      root: {
        ['&:last-child']: {
          [material.breakpoints.only('xs')]: {
            padding: material.spacing(0),
            paddingBottom: material.spacing(2),
          },
        },
        [material.breakpoints.down('md')]: {
          borderTopLeftRadius: border.radius.md,
          borderTopRightRadius: border.radius.md,
          backgroundColor: xxllncColor.light100,
          ['&:last-child']: {
            paddingBottom: material.spacing(3)
          },
        },
        [material.breakpoints.down('lg')]: {
          padding: material.spacing(3),
        },
        [material.breakpoints.up('md')]: {
          padding: material.spacing(3, 6),
        },
      },
    },
  },
  RaEmpty: {
    styleOverrides: {
      root: {
        ['& .RaEmpty-icon']: {
          backgroundColor: xxllncColor.light20,
          borderRadius: border.button.radius,
          boxSizing: 'border-box',
          height: material.spacing(5),
          marginBottom: material.spacing(1),
          padding: material.spacing(.75),
          width: material.spacing(5),
        },
        ['& .RaEmpty-message']: {
          padding: material.spacing(6),
          boxSizing: 'border-box',
          margin: 0,
          ['& .MuiTypography-root']: {
            fontSize: 14,
            lineHeight: '20px',
          },
        },
        ['& .RaEmpty-toolbar']: {
          marginTop: 0,
          paddingLeft: material.spacing(6),
          paddingRight: material.spacing(6),
          paddingBottom: material.spacing(6),
        },
      },
    },
  },
  RaFilterForm: {
    styleOverrides: {
      root: {
        [material.breakpoints.up('md')]: {
          flex: '0 1 60%',
        },
      }
    }
  }
}

