import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'
import { xxllncColor } from '../../layout/themes'

const PREFIX = 'xxllncFilterSidebarCard'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledCard = styled(Card, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  paddingRight: theme.spacing(6),
  boxSizing: 'border-box',
  backgroundColor: 'transparent',
  borderRadius: 0,
  order: -1,
  maxWidth: '33.7%',
  minWidth: '170px',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  ['& > div']: {
    padding: 0,
  },
  ['& .MuiTypography-overline']: {
    color: xxllncColor.dark60,
  },
  ['& .MuiInputBase-root']: {
    borderRadius: '50px',
    ['& #q']: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  ['& .MuiFilledInput-root']: {
    ['&.Mui-focused']: {
      background: xxllncColor.light100,
    },
    ['&:hover']: {
      background: xxllncColor.light100,
    },
  },
}))

interface FilterSidebarCardProps {
  children: JSX.Element | JSX.Element[];
}

export const FilterSidebarCard = ({ children }: FilterSidebarCardProps): JSX.Element =>
  <StyledCard classes={classes}>{children}</StyledCard>
