import OrganizationIcon from '@mui/icons-material/Business'
import OrganizationCreate from './Organization.create'
import OrganizationEdit from './Organization.edit'
import OrganizationList from './Organization.list'
import OrganizationShow from './Organization.show'

const resource = {
  list: OrganizationList,
  create: OrganizationCreate,
  edit: OrganizationEdit,
  show: OrganizationShow,
  icon: OrganizationIcon,
}

export default resource
