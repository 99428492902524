import { FC } from 'react'
import {
  Datagrid,
  DateField,
  Empty, SearchInput,
  TextField
} from 'react-admin'
import {
  EmptyContainer,
  ExportButton,
  ListActionsToolbar,
  StatusChipField
} from '../../components'
import { RestrictedContainer } from '../../components/containers'
import CustomList from '../../components/list/CustomList'
import { getLocale } from '../../utils/getLocale'
import AuditlogsExpand from './AuditLogs.expand'

const filters = [<SearchInput source="q" alwaysOn />,]

const AuditLogsList: FC = () => (
  <CustomList
    title="resources.auditlog.title"
    sort={{ field: 'timestamp', order: 'DESC' }}
    filters={filters}
    actions={
      <ListActionsToolbar i18n="resources.auditlog.list">
        <RestrictedContainer
          align="right"
          access={['eco.admin.auditlog:read', 'eco.user.auditlog:read']}
        >
          <ExportButton
            endpoint="as_csv"
            fileName="auditlog-export.csv"
            variant="contained"
          />
        </RestrictedContainer>
      </ ListActionsToolbar>
    }
    empty={
      <EmptyContainer>
        <Empty />
      </EmptyContainer>
    }
  >
    <Datagrid
      rowClick="expand"
      expand={<AuditlogsExpand />}
    >
      <StatusChipField />
      <TextField
        source="summary"
        label="data.description"
        emptyText="-"
      />
      <TextField
        source="displayName"
        label="data.displayName"
        emptyText="-"
      />
      <DateField
        source="timestamp"
        label="data.timestamp"
        locales={getLocale()}
        textAlign="right"
      />
    </Datagrid>
  </CustomList>
)

export default AuditLogsList
