import GetAppIcon from '@mui/icons-material/GetApp'
import { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import PropTypes from 'prop-types'
import { ReactElement } from 'react'
import { Button as RaButton } from 'react-admin'

/**
 * Downloads a file by given file path:
 *
 * @example // basic usage
 * import { DownloadButton } from '../../components';
 *
 * const FileDownloadButton = (): JSX.Element => (
 *     <DownloadButton fileName="download.txt" label="Download file" />
 * );
 */
export const DownloadButton = (props: DownloadButtonProps): JSX.Element => {
  const {
    icon = defaultIcon,
    label = 'action.download',
    fileName,
    variant,
    ...rest
  } = props

  const onDownload = (): void => {
    const link = document.createElement('a')
    link.download = fileName
    link.href = `${window.location.origin}/download/${fileName}`
    link.click()
  }

  return (
    <RaButton
      label={label}
      onClick={onDownload}
      variant={variant}
      color="primary"
      {...(rest as any)}
    >
      {icon}
    </RaButton>
  )
}

const defaultIcon = <GetAppIcon />

interface Props {
  icon?: ReactElement;
  label?: string;
  fileName: string;
}

export type DownloadButtonProps = Props & MuiButtonProps

DownloadButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  fileName: PropTypes.string,
}
