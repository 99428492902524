import { Avatar, Paper, styled, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { ModulesField } from '../../components/fields/ModulesField'
import { TeamField } from '../../components/fields/TeamField'
import { border, xxllncColor } from '../../layout/themes'
import { AppWithOrganization } from './App.list'

interface AppTableProps {
  data: AppWithOrganization[]
  title: string,
  modules?: boolean
}

const AppTable: FC<AppTableProps> = ({ data, title, modules }) => {
  const translate = useTranslate()

  return (
    <Root className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {translate(`resources.app_overview.${title}`)}
        <span className={classes.amount}>{data.length}</span>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {data.map((app) => (
              <TableRow
                key={app.id}
                component={Link}
                to={`/app_overview/${app.id}/show${app.appOrganizationId ? `?appOrganizationId=${app.appOrganizationId}` : ''}`}
              >
                <TableCell className={classes.iconCell} component="th" scope="row">
                  <Avatar className={classes.icon} src={`data:image/svg+xml;base64,${app.icon}`} />
                </TableCell>
                <TableCell className={classes.maxWidthCell}>{app.name}</TableCell>
                <TableCell className={classes.maxWidthCell}>{app.description.slice(0, 100)}</TableCell>
                { modules ? <ModulesField record={app} /> : <TeamField record={app} />}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  )
}

export default AppTable

const PREFIX = 'xxllncAppTable'

const classes = {
  root: `${PREFIX}-root`,
  amount: `${PREFIX}-amount`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  maxWidthCell: `${PREFIX}-maxWidthCell`,
  iconCell: `${PREFIX}-iconCell`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    margin: 'auto',
  },
  [`& .${classes.title}`]: {
    margin: '20px 0',
    fontSize: '20px',
    position: 'relative',
  },
  [`& .${classes.amount}`]: {
    position: 'absolute',
    bottom: '5px',
    marginLeft: '10px',
    padding: '4px 8px',
    fontSize: '10px',
    color: xxllncColor.dark80,
    backgroundColor: xxllncColor.light40,
    borderRadius: border.radius.md,
    lineHeight: '12px',
  },
  [`& .${classes.icon}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: 0,
  },
  [`& .${classes.maxWidthCell}`]: {
    width: '120px',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  [`& .${classes.iconCell}`]: {
    width: '40px',
  },
  '& .MuiTableCell-root': {
    padding: 9,
  },
  '& .MuiTableContainer-root': {
    borderRadius: 0,
  },
  '& .MuiTableRow-root': {
    'textDecoration': 'none',
    'color': 'inherit',
    '&:hover': {
      backgroundColor: xxllncColor.light40,
    },
  },
}))