import { AppOrganizationOut } from '../../types/ecoRequests'

export const mockAppOrganization: AppOrganizationOut[] = [
  {
    additionalConfiguration: undefined,
    appId: '9ab59bbb-9f83-47f2-ba8e-6bdb59651b19',
    appName: 'Ruimtop',
    applicationDesignTemplate: undefined,
    created: '2021-09-03T07:47:45.350606+00:00',
    ended: undefined,
    id: '4d35d80f-4620-41b7-8a0c-189a2c36099e',
    lastModified: '2021-09-03T07:47:45.350606+00:00',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    started: '2021-09-03T07:47:45.350606+00:00',
    systemTags: undefined,
    totalInstanceSoftLimit: 12,
    totalStorageSoftLimit: 12,
  },
  {
    additionalConfiguration: undefined,
    appId: 'd7f48ccf-6431-4f9a-bc64-6dd18e9560b5',
    appName: '4Value',
    applicationDesignTemplate: undefined,
    created: '2021-09-17T07:41:44.250196+00:00',
    ended: undefined,
    id: '18304485-2827-4f16-9c85-0452e8312174',
    lastModified: '2021-09-17T07:41:44.250196+00:00',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    started: '2021-09-17T07:41:44.250196+00:00',
    systemTags: undefined,
    totalInstanceSoftLimit: 12,
    totalStorageSoftLimit: 12,
  }
]
