import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { styled, Theme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { FC } from 'react'
import { useRecordContext } from 'react-admin'
import { border, xxllncColor } from '../../layout/themes'

export const StatusChipField: FC = () => {
  const record = useRecordContext<{ changeType: string }>()

  const changeType = record?.changeType
  const styleProps: StyleProps = {
    changeType
  }

  const GetIcon: FC = () => {
    switch (changeType) {
      case 'update':
        return <RefreshIcon className={classes.icon} />
      case 'insert':
        return <AddOutlinedIcon className={classes.icon} />
      case 'delete':
        return <DeleteOutlinedIcon className={classes.icon} />
      default:
        return <></>
    }
  }

  return (
    <Root className={classes.root} {...styleProps}>
      <GetIcon/>
    </Root>
  )
}

StatusChipField.defaultProps = {
  addLabel: true,
}

StatusChipField.propTypes = {
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  source: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  emptyText: PropTypes.string,
}

interface StyleProps {
  changeType?: string;
}

const PREFIX = 'xxllncStatusChipField'

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme, changeType }: StyleProps & { theme?: Theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.icon}`]: {
    backgroundColor: changeType === 'delete'
      ? xxllncColor.opRed10 : changeType === 'update'
        ? xxllncColor.opBlue10 : xxllncColor.opGreen10,
    height: 24,
    width: 24,
    color: changeType === 'delete'
      ? xxllncColor.red100 : changeType === 'update'
        ? xxllncColor.blue100 : xxllncColor.green100,
    padding: 4,
    boxSizing: 'border-box',
    borderRadius: border.radius.lg,
  },
}))
