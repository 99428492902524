import { FC } from 'react'
import { Grid, Skeleton } from '@mui/material'

export const AppSkeletonCard: FC = () => (
  <Grid
    item
    xs={12}
    sm={6}
    md={3}
    xl={3}
    sx={{ borderBottom: '1px solid #E0E0E0', height: '160px', marginBottom: '16px' }}
  >
    <Skeleton
      sx={{ borderRadius: '10px', margin: '16px' }}
      variant="rectangular"
      width={32}
      height={32}
    />
    <Skeleton height={14} variant="rectangular" sx={{ margin: '0 16px', marginBottom: '8px' }} />
    <Skeleton height={12} variant="rectangular" sx={{ margin: '0 16px', marginBottom: '4px' }}  />
    <Skeleton height={12} variant="rectangular" sx={{ margin: '0 16px', marginBottom: '4px' }}  />
  </Grid>
)