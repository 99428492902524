import { FC } from 'react'
import { styled } from '@mui/material/styles'
import { SaveButton, Toolbar } from 'react-admin'

export const SimpleSaveToolbar: FC<SimpleSaveToolbarProps> = ({ saveLabel }) => (
  <StyledToolbar className={classes.root}>
    <SaveButton label={saveLabel || 'ra.action.save'} className={classes.button} />
  </StyledToolbar>
)

const PREFIX = 'xxllncSimpleSaveToolbar'

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  [`& .${classes.button}`]: {},
}))

interface SimpleSaveToolbarProps {
  saveLabel?: string;
}