import { FC } from 'react'
import { Create, useGetList } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { AppOut } from '../../types/ecoRequests'
import AppLicenceForm from './AppLicenceForm'

const AppLicenceCreate: FC = () => {
  const search = new URLSearchParams(location?.search)
  const appId = search.get('appId')
  const redirect = appId ? `/app/${appId}/show/licences` : undefined

  const { data, isLoading } = useGetList<AppOut>(
    'app',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
    }
  )

  if (!data || data.length === 0)
    return null

  const relateToApp = data.find((record) => record.id === appId)

  return !isLoading ? (
    <Create
      actions={<ActionsToolbar record={relateToApp} source="name" i18n="resources.app_licence.create" />}
      redirect={redirect}
      title={<HeaderTitle i18n="resources.app_licence.title" />}
    >
      <AppLicenceForm appId={appId} />
    </Create>
  ) : null
}

export default AppLicenceCreate
