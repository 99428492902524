import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  CreateButton,
  Datagrid,
  EditButton,
  Empty,
  List, ReferenceField,
  TextField
} from 'react-admin'
import {
  EmptyContainer,
  ListActionsToolbar,
  RestrictedContainer
} from '../../components'

const TicketConfigList: FC = () => (
  <StyledList
    exporter={false}
    className={classes.root}
    actions={
      <ListActionsToolbar i18n="resources.ticketConfig.list">
        <RestrictedContainer
          access={['eco.admin.ticketConfig:write']}
          align="right"
        >
          <CreateButton variant="contained" />
        </RestrictedContainer>
      </ ListActionsToolbar>
    }
    empty={
      <EmptyContainer>
        <Empty />
      </EmptyContainer>
    }
  >
    <Datagrid rowClick="show">
      <TextField source="description" label="data.description" />
      <ReferenceField source="category_id" reference="tickets/incidents/categories">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="sub_category_id" reference="tickets/incidents/sub_categories" emptyText="-">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="ticket_type" />
      <RestrictedContainer
        access={['eco.admin.ticketConfig:write']}
        align="right"
      >
        <EditButton label="" />
      </RestrictedContainer>
    </Datagrid>
  </StyledList>
)

const PREFIX = 'xxllncTicketConfigList'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`&.${classes.root}`]: {},
}))

export default TicketConfigList
