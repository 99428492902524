import { styled } from '@mui/material'
import { List } from 'react-admin'
import AppGrid from './AppGrid'

const AppList = props => (
  <StyledList {...props} pagination={null} perPage={99} exporter={false} className={classes.root}>
    <AppGrid />
  </StyledList>
)

export default AppList

const PREFIX = 'aipDashboardAppList'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`&.${classes.root}`]: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
    ['& .MuiToolbar-root']: {
      [theme.breakpoints.up('md')]: {
        minHeight: '8px',
      },
    },
  },
}))