import { OrganizationAuthOut, Provider } from '../../types/ecoRequests'

export const mockOrganizationAuth: OrganizationAuthOut[] = [
  {
    id: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    auth0Identifier: 'org_bhoijahsifjdksnvo',
    provider: Provider.Auth0
  },
]
