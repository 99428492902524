import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Card, CardContent, CardHeader, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, MouseEvent, useState } from 'react'
import { Button, useGetList, useRecordContext, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { RestrictedContainer } from '../../components'
import { AppInstanceOut, HostAliasOut } from '../../types/ecoRequests'
import SetDefaultMenuItem from './SetDefaultMenuItem'

const HostAliases: FC = () => {
  const translate = useTranslate()
  const instanceRecord = useRecordContext<AppInstanceOut>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedId, setSelectedId] = useState<null | string>(null)

  const { data } = useGetList<HostAliasOut>(
    'hostalias',
    {
      pagination: { page: 1, perPage: 99 },
      sort: { field: 'id', order: 'ASC' },
      filter: { appInstanceId: instanceRecord?.id }
    }
  )

  const handleClick = (event: MouseEvent<HTMLButtonElement>, id: string): void => {
    setAnchorEl(event.currentTarget)
    setSelectedId(id)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <StyledCard className={classes.root}>
      <CardHeader
        className={classes.header}
        action={
          <RestrictedContainer
            access={['eco.admin.appinstance:write']}
          >
            <Button
              component={Link}
              to={{
                pathname: '/hostalias/create',
                search: `?appInstanceId=${instanceRecord?.id}`,
              }}
              label="actions.create"
              variant="contained"
            >
              <AddIcon />
            </Button>
          </RestrictedContainer>
        }
        title={translate('resources.app_instance.hosts')}
      />
      <CardContent className={classes.content}>
        {data && data.map(record => (
          <div key={record.id} className={classes.item}>
            <div className={classes.title}>
              <Typography variant="subtitle1">
                {record.hostname}
              </Typography>
              {record.primaryHostalias && <Typography variant="caption" color="secondary">
                {translate('resources.app_instance.default')}
              </Typography>
              }
            </div>
            <RestrictedContainer
              access={['eco.admin.appinstance:write']}
              align="center"
            >
              <>
                <IconButton
                  onClick={(event): void => handleClick(event, record.id)}
                  className={classes.icon}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="host-alias-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl) && selectedId === record.id}
                  onClose={handleClose}
                >
                  {!record.primaryHostalias &&
                    <SetDefaultMenuItem onClose={handleClose} {...record} />
                  }
                  <MenuItem
                    component={Link}
                    to={`/hostalias/${record.id}`}
                  >
                    {translate('actions.edit')}
                  </MenuItem>
                </Menu>
              </>
            </RestrictedContainer>
          </div>
        ))}
      </CardContent>
    </StyledCard>
  )
}

const PREFIX = 'xxllncHostAliases'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  item: `${PREFIX}-item`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    borderRadius: 0,
    marginBottom: 0,
    paddingTop: theme.spacing(3),
  },
  [`& .${classes.header}`]: {
    padding: theme.spacing(0, 0, 2, 0),
    ['& .MuiCardHeader-action']: {
      margin: 0,
    }
  },
  [`& .${classes.content}`]: {
    padding: 0,
  },
  [`& .${classes.item}`]: {
    display: 'flex',
    marginBottom: '0.5rem',
    maxWidth: '100%',
  },
  [`& .${classes.icon}`]: {
    height: '40px',
    width: '40px',
  },
  [`& .${classes.title}`]: {
    flexGrow: 2,
  },
}))

export default HostAliases
