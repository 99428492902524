import { FC } from 'react'
import { styled } from '@mui/material/styles'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  CreateButton,
} from 'react-admin'
import { ListActionsToolbar } from '../../components'

const externalAppsList: FC<ListProps> = (props) => (
  <StyledList
    {...props}
    exporter={false}
    actions={
      <ListActionsToolbar i18n="resources.externalApps.list">
        <CreateButton variant="contained" />
      </ListActionsToolbar>
    }
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField
        source="id"
        label="data.appName"
        sortable={false}
      />
      <TextField
        source="refresh_token"
        label="data.refresh_token"
        emptyText="-"
        sortable={false}
      />
    </Datagrid>
  </StyledList>
)

const PREFIX = 'xxllncExternalAppsList'

const classes = {
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default externalAppsList
