import { FC } from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { ActionsToolbar, HeaderTitle, SimpleSaveToolbar } from '../../components'
import { AppModuleOut } from '../../types/ecoRequests'

const AppModuleEdit: FC = () => {
  const redirect = (basePath, _id, data) => {
    const appId = (data as AppModuleOut)?.appId
    return appId ? `app/${appId}/show/modules` : `${basePath || '/'}`
  }

  return (
    <Edit
      title={<HeaderTitle i18n="resources.app_module.edit" />}
      actions={<ActionsToolbar source="appName" i18n="resources.app_module.edit" />}
      redirect={redirect}
    >
      <SimpleForm
        toolbar={<SimpleSaveToolbar />}
        sanitizeEmptyValues
      >
        <TextInput
          source="name"
          label="data.name"
          helperText=""
          multiline
        />
        <TextInput
          source="description"
          label="data.description"
          helperText=""
          multiline
        />
      </SimpleForm>
    </Edit>
  )
}


export default AppModuleEdit
