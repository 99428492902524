import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FC } from 'react'
import { BulkActionsToolbarProps, EditButton, RaRecord, useRecordContext } from 'react-admin'
import { ActionsToolbar, LocationCreateButton } from '../../components'
import { AppOrganizationOut } from '../../types/ecoRequests'
import { getTabPath } from '../../utils/getTabPath'

interface AppOrganizationShowActionsProps extends BulkActionsToolbarProps {
  data?: RaRecord
}

const resourceForPath = (path: string): string => {
  switch (path) {
    case 'environments': return 'app_instance'
    case 'modules': return 'app_organization_module'
    case 'licences': return 'app_organization_licence'
    case 'integrations': return 'app_organization_integration'
    default: return 'app_organization'
  }
}

const AppOrganizationShowButtons: FC = () => {
  const record = useRecordContext<AppOrganizationOut>()
  if (!record) return <></>

  const { id, organizationId, appId } = record
  const tabPath = getTabPath()
  const search = `?appOrganizationId=${id}&organizationId=${organizationId}&appId=${appId}`
  const resource = resourceForPath(tabPath)

  if (resource === 'app_organization') return (
    <EditButton
      record={record}
      icon={<EditOutlinedIcon />}
      variant="contained"
    />
  )

  return (<LocationCreateButton
    access={['eco.admin.appinstance:write']}
    align="right"
    resource={resource}
    search={search}
    label="actions.create"
    disabled={!record || !!record.ended}
  />)
}

export const AppOrganizationShowActions: FC<AppOrganizationShowActionsProps> = () => {
  const record = useRecordContext<AppOrganizationOut>()

  return (
    <ActionsToolbar source="appName" to={record ? `/organization/${record.organizationId}/show/apps` : undefined}>
      <AppOrganizationShowButtons />
    </ActionsToolbar>
  )
}
