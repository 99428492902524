import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Menu } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, MouseEvent, useState } from 'react'
import { Button, DeleteButton, EditButton, useRecordContext, useTranslate } from 'react-admin'
import { GenerateTokenButton, RestrictedButton } from '../../components'
import { MemberOut } from '../../types/ecoRequests'

const SignonDropdown: FC<MemberDropdownProps> = ({ redirect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedId, setSelectedId] = useState<null | string>(null)
  const translate = useTranslate()
  const record = useRecordContext<MemberOut>()

  const handleClick = (event: MouseEvent<HTMLButtonElement>, id: string): void => {
    setAnchorEl(event.currentTarget)
    setSelectedId(id)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <StyledRestrictedButton
      access={['eco.admin.apporganization:write']}
      align="right"
      cellClassName={classes.smallCell}
      headerClassName={classes.smallCell}
    >
      {() => (
        <div key={record?.id}>
          <Button onClick={(event) => handleClick(event, record?.id || '0')}>
            <MoreVertIcon />
          </Button>
          <Menu
            anchorEl={anchorEl}
            id="auth-signon-menu"
            keepMounted
            onClose={handleClose}
            open={Boolean(anchorEl) && selectedId === record?.id}
          >
            <EditButton record={record} resource="auth_connection" icon={<EditOutlinedIcon />} fullWidth />
            <GenerateTokenButton record={record} resource="auth_connection" onClose={handleClose} {...record} />
            <RestrictedButton
              access={['eco.admin.apporganization:delete']}
              align="right"
              fullWidth
            >
              {(): JSX.Element => (
                <DeleteButton
                  confirmContent="resources.auth_connection.deleteContent"
                  confirmTitle={translate('resources.auth_connection.deleteTitle', { name: record?.name })}
                  fullWidth
                  icon={<DeleteOutlinedIcon />}
                  mutationMode="pessimistic"
                  record={record}
                  redirect={redirect}
                  resource="auth_connection"
                />
              )}
            </RestrictedButton>
          </Menu>
        </div>
      )}
    </StyledRestrictedButton>
  )
}

interface MemberDropdownProps {
  redirect: string;
}

const PREFIX = 'xxllncSignonDropdown'

const classes = {
  smallCell: `${PREFIX}-smallCell`,
}

const StyledRestrictedButton = styled(RestrictedButton)(({ theme }) => ({
  [`& .${classes.smallCell}`]: {
    paddingRight: 0,
    width: 24,
  },
}))

export default SignonDropdown
