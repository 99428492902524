import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, useEffect, useState } from 'react'
import { Datagrid, SimpleShowLayout, SortPayload, TextField, useDataProvider, useGetList, useNotify, useRecordContext } from 'react-admin'
import { Empty, LocationCreateButton } from '../../components'
import { AuthConnectionOut, OrganizationAuthOut, OrganizationOut } from '../../types/ecoRequests'
import SignonDropdown from './SignonDropdown'

const OrganizationConnection: FC = () => {
  const record = useRecordContext<OrganizationAuthOut>()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [partnership, setPartnership] = useState<boolean | undefined | null>()
  const [organizationName, setOrganizationName] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  if (!record) return <div/>

  const { id, organizationId } = record
  const sort: SortPayload = { field: 'name', order: 'ASC' }
  const search = () => `?organizationAuthId=${id}&organizationName=${organizationName}`
  const redirect = `/organization_auth/${id}/show/signOn`

  useEffect(() => {
    dataProvider.getOne('organization', { id: organizationId })
      .then((response) => {
        const { isPartnership, name } = response.data as OrganizationOut
        setPartnership(isPartnership)
        setOrganizationName(name)
        setLoading(false)
      }).catch(err => {
        setError(err)
        setLoading(false)
      })
  }, [organizationId])

  const { data, total, isLoading } = useGetList<AuthConnectionOut>(
    'auth_connection',
    {
      pagination: { page: 1, perPage: 99 },
      sort,
      filter: { organizationAuthId: id }
    }
  )

  if (error) notify('notification.warning', {type: 'warning'})

  return !loading ? (
    <Root container spacing={1}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <Datagrid
            sort={sort}
            data={data}
            selectedIds={[]}
            isLoading={isLoading}
            bulkActionButtons={false}
            total={total}
            resource="auth_connection"
            empty={
              <Empty
                resource="auth_connection"
                createButton={
                  <LocationCreateButton
                    access={[
                      'eco.admin.organization:write',
                      'eco.admin.apporganization:write'
                    ]}
                    align="center"
                    resource="auth_connection"
                    search={search()}
                    label="actions.add"
                    disabled={!record}
                  />
                }
              />
            }
          >
            <TextField label="data.name" source="name" sortable={false} />
            <TextField label="data.strategy" source="strategy" sortable={false} />
            {!partnership && <SignonDropdown redirect={redirect} />}
          </Datagrid>
        </SimpleShowLayout>
      </Grid>
    </Root>
  ) : <></>
}

const PREFIX = 'xxllncOrganizationConnection'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
}))

export default OrganizationConnection
