import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Grid } from '@mui/material'
import { FC } from 'react'
import {
  Datagrid,
  DeleteButton,
  EditButton,
  SimpleShowLayout,
  SortPayload,
  TextField,
  useGetList,
  useRecordContext,
  useTranslate
} from 'react-admin'
import { Empty, LocationCreateButton, RestrictedButton } from '../../components'
import CopyToClipboardButton from '../../components/buttons/CopyToClipboardButton'
import { AppLicenceOut, AppOut } from '../../types/ecoRequests'

export const AppLicences: FC = () => {
  const translate = useTranslate()
  const appRecord = useRecordContext<AppOut>()
  const search = `?appId=${appRecord?.id}`
  const sort: SortPayload = { field: 'name', order: 'ASC' }

  const { data, total, isLoading } = useGetList<AppLicenceOut>(
    'app_licence',
    {
      pagination: { page: 1, perPage: 99 },
      sort,
      filter: { appId: appRecord?.id }
    }
  )

  return !isLoading ? (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <Datagrid
            sort={sort}
            data={data}
            selectedIds={[]}
            isLoading={isLoading as boolean}
            bulkActionButtons={false}
            total={total}
            empty={
              <Empty
                resource="app_licence"
                createButton={
                  <LocationCreateButton
                    access={['eco.admin.app:write']}
                    align="center"
                    resource="app_licence"
                    search={search}
                    label="actions.licences"
                    disabled={!appRecord}
                  />
                }
              />
            }
          >
            <TextField source="name" label="data.name" sortable={false} />
            <TextField source="description" label="data.description" sortable={false} />
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <RestrictedButton
                access={['eco.admin.app:write']}
                align="right"
              >
                {(): JSX.Element | null => {
                  const record = useRecordContext<AppLicenceOut>()
                  if (!record) return null

                  return (
                    <>
                      <CopyToClipboardButton id={record?.id} hideLabel />
                      <EditButton
                        record={record}
                        resource="app_licence"
                        label=""
                        icon={<EditOutlinedIcon />}
                      />
                      <DeleteButton
                        redirect={`/app/${appRecord?.id}/show/licences`}
                        mutationMode="pessimistic"
                        record={record}
                        resource="app_licence"
                        confirmTitle={translate('message.deleteTitle', { name: record.name })}
                        label=""
                        icon={<DeleteOutlinedIcon />}
                      />
                    </>
                  )
                }}
              </RestrictedButton>
            </div>
          </Datagrid>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  ) : <></>
}
