import { styled } from '@mui/material/styles'
import { ListActionsProps, TopToolbar } from 'react-admin'
import { HeaderTitle } from '../../components'

export const ListActionsToolbar = ({ i18n, children }: ListActionsToolbarProps): JSX.Element => (
  <StyledTopToolbar className={classes.root}>
    <HeaderTitle i18n={i18n} className={classes.title} />
    <span className={classes.spacer} />
    {children}
  </StyledTopToolbar>
)

interface ListActionsToolbarProps extends ListActionsProps {
  i18n: string;
  children?: React.ReactNode;
}

const PREFIX = 'xxllncListActionsToolbar'

const classes = {
  root: `${PREFIX}-root`,
  spacer: `${PREFIX}-spacer`,
  title: `${PREFIX}-title`,
}

const StyledTopToolbar = styled(TopToolbar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  [`& .${classes.title}`]: {
    flex: 1,
    fontSize: 28,
    lineHeight: '40px',
    marginRight: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [`& .${classes.spacer}`]: {},
}))
