import { FC } from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { required } from '../../utils/validation'

const ExternalAppsEdit: FC = () => (
  <Edit
    actions={<ActionsToolbar i18n="resources.externalApps.edit" />}
    title={<HeaderTitle i18n="resources.externalApps.edit" />}
  >
    <SimpleForm>
      <TextInput
        source="id"
        disabled
      />
      <TextInput
        source="refresh_token"
        label="data.refresh_token"
        validate={required()}
        disabled
      />
    </SimpleForm>
  </Edit>
)

export default ExternalAppsEdit
