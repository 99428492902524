export interface IReview {
  /** @format uuid */
  id: string;
  user: string;
  /** @format date-time */
  date: string;
  text: string;
  /** @format uuid */
  appId: string;
}

/**
 * An enumeration.
 */
export enum ChangeType {
  Insert = 'insert',
  Update = 'update',
  Delete = 'delete',
}

export interface AuditLogOut {
  /** @format uuid */
  id: string;
  /** @format uuid */
  appId: string;
  /** @format uuid */
  organizationId: string;
  displayName: string;
  /** @format uuid */
  userId: string;
  summary: string;
  /** @format date-time */
  timestamp: string;
  changeType: ChangeType;
}
