import { FC } from 'react'
import {
  Create, FormDataConsumer, ReferenceInput, required, SelectInput, SimpleForm
} from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'

const AuthMemberRoleCreate: FC = () => {
  const search = new URLSearchParams(location?.search)
  const authMemberId = search.get('authMemberId') as string

  const redirect = (): string | undefined => authMemberId ? `/auth_member_roles?authMemberId=${authMemberId}` : undefined

  return (
    <Create
      actions={<ActionsToolbar i18n="resources.auth_member_role.create" />}
      redirect={redirect()}
      title={<HeaderTitle i18n="resources.auth_member_role.create" />}
    >
      <SimpleForm
        defaultValues={{ authMemberId: 'auth0|60b8b386239e8b0070230c9b||9ba03d75-0d33-4e19-8cc9-6f53f1c3c967' }}
        sanitizeEmptyValues
      >
        <ReferenceInput label="data.app" source="appId" reference="app" isRequired>
          <SelectInput validate={[required()]} optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>{({formData}: { formData: { appId: string }}) => formData.appId ?
          <ReferenceInput
            label="data.role"
            source="auth0RoleId"
            reference="app_role"
            filter={{ appId: formData.appId }}
            isRequired
          >
            <SelectInput validate={[required()]} optionText="label" />
          </ReferenceInput>
          : null}</FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default AuthMemberRoleCreate
