import { Grid } from '@mui/material'
import { FC } from 'react'
import { Create, RaRecord, SelectInput, SimpleForm, useGetList } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { AppOut } from '../../types/ecoRequests'
import AppOrganizationForm from './AppOrganizationForm'

interface Choices extends RaRecord {
  name: string
}

const AppOrganizationCreate: FC = () => {
  const search = new URLSearchParams(location?.search)
  const organizationId = search.get('organizationId')
  const redirect = organizationId ? `/organization/${organizationId}/show/apps` : undefined

  const { data } = useGetList<AppOut>(
    'app',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: { notUsedByOrganizationId: organizationId, system: 0 }
    }
  )

  const choices = data ? data.filter((record: AppOut) => !record.ended)
    .map(({ id, name }: Choices) => ({ id, name })) : []

  return (
    <Create
      title={<HeaderTitle i18n="resources.app_organization.create" />}
      actions={<ActionsToolbar i18n="resources.app_organization.create" />}
      redirect={redirect}
    >
      <SimpleForm
        defaultValues={{ organizationId }}
        sanitizeEmptyValues
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <SelectInput
              choices={choices.sort()}
              helperText=""
              label="data.appName"
              source="appId"
              isRequired
            />
          </Grid>
          <AppOrganizationForm />
        </Grid>
      </SimpleForm>
    </Create>
  )
}

export default AppOrganizationCreate
