import { Grid } from '@mui/material'
import { BooleanInput, NumberInput, TextInput } from 'react-admin'

const Auth0Form = ({view}: Auth0FormProps): JSX.Element => (
  <>
    <Grid item xs={12}>
      <BooleanInput
        label="data.mfaEnabled"
        source="options.mfaEnabled"
        helperText="data.mfaEnabledHelper"
      />
    </Grid>
    {view === 'edit' && (
      <>
        <Grid item xs={12}>
          <NumberInput
            label="data.strategyVersion"
            source="options.strategy_version"
            helperText=""
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="data.passwordPolicy"
            source="options.passwordPolicy"
            helperText=""
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <BooleanInput
            label="data.mfaReturnEnrollSettings"
            source="options.mfa.return_enroll_settings"
            helperText=""
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <BooleanInput
            label="data.bruteForceProtection"
            source="options.brute_force_protection"
            helperText=""
            disabled
          />
        </Grid>
      </>
    )}
  </>
)

interface Auth0FormProps {
  view: 'create' | 'edit'
}

export default Auth0Form
