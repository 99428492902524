import { Grid } from '@mui/material'
import { FC } from 'react'
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput
} from 'react-admin'
import { ChipInput } from '../../components'
import { TopDeskTicketType } from '../../types/topdesk.types'
import { required } from '../../utils/validation'

const availableTypes = ['text', 'multiline', 'select', 'datetime']

interface QuestionProps {
  type: string
}

const TicketConfigForm: FC = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextInput
        isRequired
        label="data.description"
        source="description"
        validate={required()}
      />
    </Grid>
    <Grid item xs={12} sm={6} >
      <ReferenceInput
        source="category_id"
        reference="tickets/incidents/categories"
      >
        <SelectInput
          optionText="name"
          validate={required()}
          isRequired
        />
      </ReferenceInput>
    </Grid>
    <Grid item xs={12} sm={6} >
      <FormDataConsumer>
        {({ formData }) => (
          <ReferenceInput
            source="sub_category_id"
            reference="tickets/incidents/sub_categories"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, camelcase
            filter={{ category_id: formData.category_id }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
      </FormDataConsumer>
    </Grid>
    <Grid item xs={12}>
      <ReferenceInput
        source="operator_group_id"
        reference="tickets/incidents/operator_groups"
      >
        <AutocompleteInput
          optionText="groupName"
          validate={required()}
          isRequired
        />
      </ReferenceInput>
    </Grid>
    <Grid item xs={12}>
      <ChipInput
        source="tags"
        label="data.tags"
        helperText=""
        validate={required()}
        isRequired
      />
    </Grid>
    <Grid item xs={12}>
      <SelectInput
        source="ticket_type"
        choices={Object.keys(TopDeskTicketType)
          .filter(value => isNaN(Number(value)))
          .map(key => ({ id: key, name: key }))}
        validate={required()}
        isRequired
      />
    </Grid>
    <Grid item xs={12}>
      <ArrayInput label="show.questions" source="questions">
        <SimpleFormIterator>
          <TextInput source="label" validate={required()} isRequired />
          <SelectInput
            choices={availableTypes.map((app) => ({ id: app, name: app }))}
            helperText=""
            label="data.type"
            source="type"
            validate={required()}
            isRequired
          />
          <FormDataConsumer>{({ scopedFormData }) => {
            const data = scopedFormData as QuestionProps
            return data.type === 'select' ?
              <TextInput
                source={'options'}
                helperText="data.devideWithComma"
                validate={required()}
                isRequired
              /> : null
          }}
          </FormDataConsumer>
          <SelectInput
            choices={[{ id: 'True', name: 'data.yes' }, { id: 'False', name: 'data.no' }]}
            helperText=""
            label="data.isRequired"
            source="isRequired"
            validate={required()}
            isRequired
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  </Grid>
)

export default TicketConfigForm
