import { FC } from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { ActionsToolbar, HeaderTitle, SimpleSaveToolbar } from '../../components'
import { OrganizationAuthOut } from '../../types/ecoRequests'
import OrganizationAuthForm from './OrganizationAuthForm'

const OrganizationAuthEdit: FC = () => {
  const redirect = (basePath, _id, data) => {
    const organizationId = (data as OrganizationAuthOut)?.organizationId
    return organizationId ? `organization/${organizationId}/show/auth` : `${basePath || '/'}`
  }

  return (
    <Edit
      actions={<ActionsToolbar i18n="resources.organization_auth.edit" />}
      redirect={redirect}
      title={<HeaderTitle i18n="resources.organization_auth.edit" />}
    >
      <SimpleForm
        toolbar={<SimpleSaveToolbar />}
        sanitizeEmptyValues
      >
        <OrganizationAuthForm />
      </SimpleForm>
    </Edit>
  )
}

export default OrganizationAuthEdit
