import { FC } from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { ActionsToolbar, ConfirmDeleteToolbar, HeaderTitle } from '../../components'
import { activateTransform } from '../../utils/activateTransform'
import OrganizationForm from './OrganizationForm'

const OrganizationEdit: FC = () => (
  <Edit
    title={<HeaderTitle source="name" i18n="edit.title" />}
    actions={<ActionsToolbar source="name" i18n="resources.organization.edit" />}
    transform={activateTransform}
    redirect="show"
  >
    <SimpleForm
      toolbar={<ConfirmDeleteToolbar access={['eco.admin.organization:delete']} />}
      sanitizeEmptyValues
    >
      <OrganizationForm view="edit" />
    </SimpleForm>
  </Edit>
)

export default OrganizationEdit
