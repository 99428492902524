import AppInstanceIcon from '@mui/icons-material/Category'
import AppInstanceCreate from './AppInstance.create'
import AppInstanceEdit from './AppInstance.edit'
import AppInstanceList from './AppInstance.list'
import AppInstanceShow from './AppInstance.show'

const resource = {
  list: AppInstanceList,
  create: AppInstanceCreate,
  edit: AppInstanceEdit,
  show: AppInstanceShow,
  icon: AppInstanceIcon,
}

export default resource
