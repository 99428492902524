import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { styled } from '@mui/material/styles'
import {
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  Empty,
  List,
  ListProps,
  TextField, useTranslate
} from 'react-admin'
import { ImportButton, ImportConfig } from 'react-admin-import-csv'
import {
  DownloadButton,
  EmptyContainer,
  ListActionBulkDelete,
  ListActionsToolbar,
  RestrictedButton,
  RestrictedContainer
} from '../../components'
import { MemberOut } from '../../types/ecoRequests'
import { getFromToken } from '../../utils/fromToken'

const AuthMemberList = (props: ListProps): JSX.Element => {
  const translate = useTranslate()

  const config: ImportConfig = {
    logging: false,
    parseConfig: {
      delimiter: '',	// auto-detect
      newline: '',	// auto-detect
      quoteChar: '"',
      escapeChar: '"',
      header: false,
      transformHeader: undefined,
      dynamicTyping: false,
      preview: 0,
      encoding: '',
      worker: false,
      comments: false,
      step: undefined,
      complete: undefined,
      error: undefined,
      download: false,
      downloadRequestHeaders: undefined,
      downloadRequestBody: undefined,
      skipEmptyLines: false,
      chunk: undefined,
      chunkSize: undefined,
      fastMode: undefined,
      beforeFirstChunk: undefined,
      withCredentials: undefined,
      transform: undefined,
      delimitersToGuess: [',', '\t', '|', ';']
    }
  }

  return (
    <StyledList
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      exporter={false}
      actions={
        <ListActionsToolbar i18n="resources.auth_member.list">
          <DownloadButton
            label="actions.downloadExampleCSV"
            fileName="user_import_example.csv"
          />
          <RestrictedContainer
            access={['eco.admin.member:write', 'eco.user.member:write']}
            align="right"
            className={classes.spacer}
          >
            <ImportButton {...config} {...props} />
          </RestrictedContainer>
          <RestrictedButton
            access={['eco.admin.member:write', 'eco.user.member:write']}
            align="right"
            className={classes.spacer}
          >
            {(): JSX.Element => <CreateButton variant="contained" />}
          </RestrictedButton>
        </ ListActionsToolbar>
      }
      empty={
        <EmptyContainer>
          <Empty />
        </EmptyContainer>
      }
    >
      <Datagrid
        bulkActionButtons={
          ListActionBulkDelete({
            access:['eco.admin.member:delete', 'eco.user.member:delete'],
            mutationMode: 'pessimistic'
          })}
        rowClick="show"
      >
        <TextField source="name" label="data.name" />
        <TextField label="data.email" source="email" />
        {/* <AppField label="data.apps" source="apps" /> */}
        <RestrictedButton
          access={['eco.admin.member:write', 'eco.user.member:write']}
          align="right"
          cellClassName={classes.smallCell}
          headerClassName={classes.smallCell}
        >
          {(): JSX.Element => <EditButton label="" icon={<EditOutlinedIcon />} />}
        </RestrictedButton>
        <>
          {(record: MemberOut): JSX.Element => {
            const name = record.name
            return (
              <RestrictedButton
                access={['eco.admin.member:write', 'eco.user.member:write']}
                align="right"
                cellClassName={classes.smallCell}
                headerClassName={classes.smallCell}
                style={{display: getFromToken('name') === name ? 'none' : 'flex'}}
              >
                {(): JSX.Element => (
                  <DeleteButton
                    confirmContent="resources.auth_member.deleteContent"
                    confirmTitle={translate('resources.auth_member.deleteTitle', { name })}
                    icon={<DeleteOutlinedIcon />}
                    label=""
                    mutationMode="pessimistic"
                    record={record}
                    resource="auth_member"
                  />
                )}
              </RestrictedButton>
            )}}
        </>
      </Datagrid>
    </StyledList>
  )
}

const PREFIX = 'xxllncAuthMemberList'

const classes = {
  smallCell: `${PREFIX}-smallCell`,
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.smallCell}`]: {
    paddingRight: 0,
    width: 24,
  },
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default AuthMemberList
