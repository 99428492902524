import AddIcon from '@mui/icons-material/Add'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FC } from 'react'
import { Button, EditButton, useRecordContext } from 'react-admin'
import { Link } from 'react-router-dom'
import { ActionsToolbar, CreateOrganizationAuthButton, RestrictedButton } from '../../components'
import CopyToClipboardButton from '../../components/buttons/CopyToClipboardButton'
import { OrganizationOut } from '../../types/ecoRequests'
import { getTabPath } from '../../utils/getTabPath'

const OrganizationShowButtons: FC = () => {
  const record = useRecordContext<OrganizationOut>()
  const tabPath = getTabPath()

  if (record) {
    switch (tabPath) {
      case 'apps':
        return (
          <RestrictedButton
            access={[
              'eco.admin.organization:write',
              'eco.admin.apporganization:write'
            ]}
            align="right"
          >
            {(): JSX.Element => (
              <Button
                component={Link}
                to={{
                  pathname: '/app_organization/create',
                  search: `?organizationId=${record?.id}`,
                }}
                disabled={!!record?.ended}
                label="actions.create"
                variant="contained"
              >
                <AddIcon />
              </Button>
            )}
          </RestrictedButton>
        )
      case 'auth':
        return (
          <RestrictedButton
            access={[
              'eco.admin.organization:write',
              'eco.admin.apporganization:write'
            ]}
            align="right"
          >
            {(): JSX.Element => <CreateOrganizationAuthButton disabled={!!record?.ended} />}
          </RestrictedButton>
        )
      default:
        return (
          <RestrictedButton
            access={['eco.admin.organization:write']}
            align="right"
          >
            {(): JSX.Element => (
              <>
                <CopyToClipboardButton id={record?.id}/>
                <EditButton style={{ marginLeft: '8px'}} record={record} icon={<EditOutlinedIcon />} variant="contained" />
              </>
            )}
          </RestrictedButton>
        )
    }
  }

  return <></>
}

export const OrganizationShowActions: FC = () => (
  <ActionsToolbar source="name" to="/organization">
    <OrganizationShowButtons />
  </ActionsToolbar>
)
