/* eslint-disable camelcase */
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { FilterList, FilterListItem } from 'react-admin'

export const IsActiveFilter: FC = () => (
  <Root className={classes.root} label="filters.isActive" icon={undefined}>
    <FilterListItem
      className={classes.item}
      label="filters.true"
      value={{ is_active: true }}
    />
    <FilterListItem
      className={classes.item}
      label="filters.false"
      value={{ is_active: false }}
    />
  </Root>
)

const PREFIX = 'xxllncIsActiveFilter'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
}

const Root = styled(FilterList)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.item}`]: {},
}))
