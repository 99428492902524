import { FC } from 'react'
import { Create, SimpleForm } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import HostAliasesForm from './HostAliasesForm'

const HostAliasesCreate: FC = () => {
  const search = new URLSearchParams(location?.search)
  const appInstanceId = search.get('appInstanceId')
  const redirect = appInstanceId ?  `/app_instance/${appInstanceId}/show` : undefined

  return (
    <Create
      actions={<ActionsToolbar i18n="resources.hostalias.create" />}
      redirect={redirect}
      title={<HeaderTitle i18n="resources.hostalias.create" />}
    >
      <SimpleForm
        defaultValues={{ appInstanceId }}
        sanitizeEmptyValues
      >
        <HostAliasesForm />
      </SimpleForm>
    </Create>
  )
}

export default HostAliasesCreate
