/* eslint-disable */
import { Box, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { MenuItemLink, MenuProps, useResourceDefinitions, useSidebarState } from 'react-admin'
import { Dot, Xxllnc } from '../components/Icons'
import { border, color, xxllncColor } from '../layout/themes'
import { getOrganizationName } from '../utils/userDetails'
import SubMenu from './SubMenu'

const Menu = ({ dense = false }: MenuProps): JSX.Element => {
  const resourcesDefinitions = useResourceDefinitions()
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name])
  const isSmall = useMediaQuery('(min-width:600px)')
  const [open, setOpen] = useSidebarState()
  const [state, setState] = useState({ menuSettings: false })

  useEffect(() => {
    if (!isSmall) setOpen(true)
  }
  , [isSmall, setOpen])

  const handleToggle = (menu: MenuName) => {
    setState(stateItem => ({ ...stateItem, [menu]: !stateItem[menu] }))
  }

  const getMenuItems = (object) => {
    let values = object.filter(resource => resource.icon && !resource.options?.submenu)
    const submenu = object.filter(resource => resource.icon && resource.options?.submenu)

    if (submenu.length > 0) {
      values = [
        ...values,
        {
          name: 'submenu.settings',
          resources: submenu.map(prop => prop.name),
          submenu: [
            ...submenu
          ]
        }
      ]
    }

    return values
  }

  const updateMenuItemLink = (url) => {
    const needsOrganization = url?.includes('{organization}')
    
    if (needsOrganization) {
      return url.replace('{organization}', getOrganizationName())
    }
    return url
  }

  return (
    <StyledBox mt={1} className={classes.root}>
      <div className={classes.branding}>
        <Xxllnc className={classes.logo}  />
        <Typography
          component="span"
          color="textPrimary"
          className={classes.lab}
        >
          mijn.xxllnc
        </Typography>
      </div>
      <div className={classes.spacer}>&nbsp;</div>
      <StyledMenuItemLink
        className={classes.item}
        dense={dense}
        key={'dashboard'}
        primaryText="Home"
        sidebarIsOpen={!open}
        to={`/`}
      />
      {getMenuItems(resources).map(resource => {
        if ('submenu' in resource) {
          return (
            <SubMenu
              key={resource.name}
              handleToggle={() => handleToggle('menuSettings')}
              isOpen={state.menuSettings}
              sidebarIsOpen={!open}
              name={resource.name}
              dense={dense}
            >
              {resource && resource.submenu && resource.submenu.map(item => (
                <StyledMenuItemLink
                  className={classes.link}
                  classes={classes}
                  dense={dense}
                  key={item.name}
                  leftIcon={<Dot fill={ isSmall ? 'rgba(71,71,70,1)' : '#fff' } />}
                  primaryText={item.options?.label || item.name}
                  sidebarIsOpen={!open}
                  to={`/${item.name}`}
                />
              ))}
            </SubMenu>
          )}
        
        if (!!resource.options.to) {
          return (
            <StyledMenuItemLink
              className={classes.item}
              dense={dense}
              key={resource.name}
              primaryText={resource.options?.label || resource.name}
              target="_blank"
              sidebarIsOpen={!open}
              to={updateMenuItemLink(resource.options?.to)}
            />
          )
        }
        return (
          <StyledMenuItemLink
            className={classes.item}
            dense={dense}
            key={resource.name}
            primaryText={resource.options?.label || resource.name}
            sidebarIsOpen={!open}
            to={`/${resource.name}`}
          />
        )
      })}
    </StyledBox>
  )
}

type MenuName = 'menuSettings'

const PREFIX = 'xxllncMenu'

const classes = {
  root: `${PREFIX}-root`,
  link: `${PREFIX}-link`,
  branding: `${PREFIX}-branding`,
  logo: `${PREFIX}-logo`,
  lab: `${PREFIX}-lab`,
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
  item: `${PREFIX}-item`,
  spacer: `${PREFIX}-spacer`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    overflow: 'hidden',
  },
  [`& .${classes.link}`]: {
    [theme.breakpoints.up('sm')]: {
      color: xxllncColor.light100,
      ['&:hover']: {
        backgroundColor: xxllncColor.dark100,
      }
    }
  },
  [`& .${classes.item}`]: {
    padding: '12px 20px',
    ['&:hover']: {
      [theme.breakpoints.up('sm')]: {
        backgroundColor: xxllncColor.dark100,
      }
    }
  },
  [`& .${classes.branding}`]: {
    display: 'flex',
    flexDirection: 'row',
  },
  [`& .${classes.logo}`]: {
    fill: color.common.inactive,
    width: theme.spacing(10),
    marginTop: theme.spacing(1.3),
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(1.5),
  },
  [`& .${classes.lab}`]: {
    backgroundColor: xxllncColor.opDark80,
    color: xxllncColor.opLight40,
    borderRadius: border.radius.lg,
    fontSize: 8,
    marginTop: '14px',
    padding: '5px 8px',
  },
  [`& .${classes.spacer}`]: {
    height: 40,
  },
}))

const StyledMenuItemLink = styled(MenuItemLink)(({ theme }) => ({
  ['&.RaMenuItemLink-active']: {
    backgroundColor: xxllncColor.opDark80,
    color: xxllncColor.light100,
  },
  ['& .RaMenuItemLink-icon']: {
    color: xxllncColor.opDark80,
    minWidth: theme.spacing(3),
  },
}))

export default Menu
