import ManageAppIcon from '@mui/icons-material/Apps'
import ManageAppCreate from './ManageApp.create'
import ManageAppEdit from './ManageApp.edit'
import ManageAppList from './ManageApp.list'
import ManageAppShow from './ManageApp.show'

const resource = {
  list: ManageAppList,
  create: ManageAppCreate,
  edit: ManageAppEdit,
  show: ManageAppShow,
  icon: ManageAppIcon,
}

export default resource
