import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FC, useEffect, useState } from 'react'
import { BulkActionsToolbarProps, EditButton, RaRecord, useDataProvider, useRecordContext } from 'react-admin'
import { ActionsToolbar, ConnectButton, LocationCreateButton, RestrictedButton } from '../../components'
import { OrganizationAuthOut, OrganizationOut } from '../../types/ecoRequests'
import { getTabPath } from '../../utils/getTabPath'

interface OrganizationAuthShowActionsProps extends BulkActionsToolbarProps {
  data?: RaRecord
}

const OrganizationAuthButtons: FC = () => {
  const [organizationName, setOrganizationName] = useState('')
  const dataProvider = useDataProvider()
  const record = useRecordContext<OrganizationAuthOut>()
  const tabPath = getTabPath()
  const id = record?.id
  const organizationId = record?.organizationId
  const search = () => `?organizationAuthId=${id}&organizationName=${organizationName}`

  useEffect(() => {
    if (organizationId) dataProvider.getOne('organization', { id: organizationId })
      .then((response) => {
        console.log({ response })
        const { name } = response.data as OrganizationOut
        setOrganizationName(name)
      }).catch(() => { console.log('Error fetching organization') })
  }, [organizationId])

  if (record) {
    switch (tabPath) {
      case 'signOn':
        return (
          <>
            <ConnectButton
              access={[
                'eco.admin.organization:write',
                'eco.admin.apporganization:write'
              ]}
              align="right"
              resource="auth_connection"
              label="actions.connect"
              disabled={!record}
              title="resources.auth_connection.connect.title"
              record={record}
            />
            <LocationCreateButton
              access={[
                'eco.admin.organization:write',
                'eco.admin.apporganization:write'
              ]}
              align="right"
              resource="auth_connection"
              search={search()}
              label="actions.create"
              disabled={!record}
            />
          </>
        )
      case 'member':
        return (
          <LocationCreateButton
            access={[
              'eco.admin.member:write',
              'eco.user.member:write'
            ]}
            align="right"
            resource="auth_member"
            search={search()}
            label="actions.addMember"
            disabled={!record}
          />
        )
      default:
        return (
          <RestrictedButton
            access={['eco.admin.organization:write']}
            align="right"
          >
            {(): JSX.Element => <EditButton record={record} icon={<EditOutlinedIcon />} variant="contained" />}
          </RestrictedButton>
        )
    }
  }
  return <></>
}

export const OrganizationAuthShowActions = (props: OrganizationAuthShowActionsProps): JSX.Element => {
  const record = useRecordContext<OrganizationAuthOut>(props)

  return (
    <ActionsToolbar source="provider" to={record ? `/organization/${record?.organizationId}/show/auth` : undefined}>
      <OrganizationAuthButtons />
    </ActionsToolbar>
  )
}
