import { Chip, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { useGetList, useRecordContext, useTranslate } from 'react-admin'
import { AppInstanceLogOut, AppInstanceOut } from '../../types/ecoRequests'

const AppUsage: FC = () => {
  const translate = useTranslate()
  const record = useRecordContext<AppInstanceOut>()

  const { data } = useGetList<AppInstanceLogOut>(
    'app_instance_log',
    {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'created', order: 'DESC' },
      filter: { appInstanceIds: [record?.id] }
    }
  )

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return ''
    const date = new Date(dateString)
    const formattedDate = new Intl.DateTimeFormat('nl-NL', { day: '2-digit', month: 'short', year: 'numeric' }).format(date)
    return `(${formattedDate})`
  }

  return (
    <Root className={classes.root}>
      <Grid item xs={12}>
        <p className={classes.title}>
          { translate('data.activeUsage', { date: formatDate(data?.[0]?.lastModified) }) }
        </p>
        <Chip
          className={classes.chip}
          label={ translate('data.activeUsers', { amount: data?.[0]?.activeUsers || '-' }) }
        />
        <Chip
          className={classes.chip}
          label={ translate('data.usedMemory', { amount: data?.[0]?.usedMemory || '-' }) }
        />
      </Grid>
    </Root>
  )
}

const PREFIX = 'xxllncAppUsage'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  chip: `${PREFIX}-chip`,
}

const Root = styled('span')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'inline',
    paddingLeft: '10px',
  },
  [`& .${classes.title}`]: {
    textTransform: 'uppercase',
    fontSize: '12px',
    marginBottom: '0.2em',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  [`& .${classes.chip}`]: {
    marginRight: '10px',
    fontSize: '12px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))

export default AppUsage
