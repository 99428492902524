import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { AppBar as RaAppBar, AppBarProps } from 'react-admin'
import { UserMenu } from './UserMenu'

const AppBar: FC<AppBarProps> = (props) => (
  <StyledAppBar
    className={classes.root}
    alwaysOn
    container='div'
    elevation={1}
    userMenu={<UserMenu />}
    {...props}
  >
    <span className={classes.spacer} />
  </StyledAppBar>
)

const PREFIX = 'xxllncAppBar'

const classes = {
  root: `${PREFIX}-root`,
  spacer: `${PREFIX}-spacer`,
}

const StyledAppBar = styled(RaAppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  ['& > header']: {
    borderBottom: 'unset',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 248px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ['& .RaAppBar-toolbar']: {
    height: theme.spacing(8),
  },
  [`& .${classes.spacer}`]: {
    flex: 1,
  },
}))

export default AppBar
