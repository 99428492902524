import { FC } from 'react'
import { Create, SimpleForm } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import OrganizationAuthForm from './OrganizationAuthForm'

const OrganizationAuthCreate: FC = () => {
  const search = new URLSearchParams(location?.search)
  const organizationId = search.get('organizationId')
  const redirect = organizationId ? `/organization/${organizationId}/show/auth` : undefined

  return (
    <Create
      actions={<ActionsToolbar i18n="resources.organization_auth.create" />}
      redirect={redirect}
      title={<HeaderTitle i18n="resources.organization_auth.create" />}
    >
      <SimpleForm
        defaultValues={{ organizationId }}
        sanitizeEmptyValues
      >
        <OrganizationAuthForm />
      </SimpleForm>
    </Create>
  )
}

export default OrganizationAuthCreate
