import OrganizationAuthCreate from './OrganizationAuth.create'
import OrganizationAuthEdit from './OrganizationAuth.edit'
import OrganizationAuthShow from './OrganizationAuth.show'

const resource = {
  create: OrganizationAuthCreate,
  edit: OrganizationAuthEdit,
  show: OrganizationAuthShow,
}

export default resource
