import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { xxllncColor } from '../../layout/themes'

import { AppCardActions } from './AppCard.actions'

export interface AppOverview {
  name?: string | null
  description?: string | null
  icon: string
}

export const AppCard = ({
  name,
  description,
  icon,
}: AppOverview): JSX.Element => (
  <Root className={classes.root}>
    <CardHeader
      className={classes.header}
      action={<AppCardActions icon={icon} />}
    />
    <CardContent className={classes.content}>
      <Typography variant="h6" component="h6" className={classes.title}>
        {name}
      </Typography>
      <Typography variant="body2" component="p" className={classes.description}>
        {description}
      </Typography>
    </CardContent>
  </Root>
)

const PREFIX = 'xxllncAppCard'

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  header: `${PREFIX}-header`,
}

const Root = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    textAlign: 'left',
    borderBottom: `1px solid ${xxllncColor.dark20}`,
    borderRadius: 0,
    ['&:hover']: {
      backgroundColor: xxllncColor.dark10,
    },
  },
  [`& .${classes.content}`]: {
    padding: '8px 16px',
  },
  [`& .${classes.title}`]: {
    fontWeight: 'bold',
    fontSize: 14,
    ['&:first-letter']: {
      textTransform: 'uppercase',
    },
  },
  [`& .${classes.description}`]: {
    color: xxllncColor.dark60,
    fontSize: 12,
  },
  [`& .${classes.header}`]: {
    boxSizing: 'border-box',
    height: 'auto',
    marginBottom: 0,
    position: 'relative',
    width: '100%',
    padding: '16px 16px 4px 8px',
  },
  ['& .MuiCardHeader-action']: {
    height: '100%',
    marginLeft: 0,
    marginTop: 0,
    width: '100%',
  },
}))
