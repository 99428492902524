import { Grid } from '@mui/material'
import { FC } from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { ActionsToolbar, ConfirmDeleteToolbar, HeaderTitle } from '../../components'
import { AuthConnectionOut } from '../../types/ecoRequests'
import { connectionsTransform } from '../../utils/connectionsTransform'
import AuthConnectionForm from './AuthConnectionForm'

const AuthConnectionEdit: FC = () => {
  const redirect = (_basePath, _id, data) => {
    const id = (data as AuthConnectionOut)?.organizationAuthId
    return id ? `organization_auth/${id}/show/signOn` : 'organization'
  }

  return (
    <Edit
      actions={<ActionsToolbar source="name" i18n="resources.auth_connection.edit" />}
      mutationMode="pessimistic"
      redirect={redirect}
      title={<HeaderTitle i18n="resources.auth_connection.edit" />}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      transform={connectionsTransform}
    >
      <SimpleForm
        toolbar={
          <ConfirmDeleteToolbar
            access={['eco.admin.connection:delete']}
            returnPath="organization_auth/{id}/show/signOn"
            returnPropName="organizationAuthId"
          />
        }
        sanitizeEmptyValues
      >
        <Grid container spacing={2}>
          <AuthConnectionForm view="edit" />
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default AuthConnectionEdit
