import { styled, TableCell } from '@mui/material'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { AppCategories, AppOverview } from '../../types/ecoRequests'

const TeamCircle = styled('span')`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  margin-bottom: 1px;
`
interface TeamFieldProps {
  record: AppOverview
}

export const TeamField: FC<TeamFieldProps> = ({ record }) => {
  const translate = useTranslate()
  let color = 'transparent'

  switch (record.category) {
    case AppCategories.Belastingen: color = '#31679B'; break
    case AppCategories.Omgeving: color = '#006450'; break
    case AppCategories.Productiviteit: color = '#E15B32'; break
    case AppCategories.Sociaal: color = '#F0C952'; break
    case AppCategories.ZaakgerichtWerken: color = '#E59925'; break
    default: color = 'transparent'
  }
  return record ? (
    <TableCell>
      <span style={{ whiteSpace: 'nowrap' }}>
        <TeamCircle style={{ backgroundColor: color }} />
        {translate(`domains.${record.category}`)}
      </span>
    </TableCell>) : null
}