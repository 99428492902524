import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { Edit, PasswordInput, SimpleForm, usePermissions } from 'react-admin'
import { ActionsToolbar, ConfirmDeleteToolbar, HeaderTitle } from '../../components'
import { IPermissions } from '../../types/ecoTypes'
import AuthMemberForm from './AuthMemberForm'

const AuthMemberEdit = (props: AuthMemberEditProps): JSX.Element => {
  const { id, resource } = props
  const { isLoading, permissions } = usePermissions() as IPermissions
  const [redirect, setRedirect] = useState<string | undefined>()
  const orgAuthId = id?.substring(id?.lastIndexOf('|') + 1)

  const path = (): string | undefined => {
    if (
      orgAuthId &&
      (permissions !== undefined && permissions.scopes?.some((i: string) => i.includes('eco.admin')))
    ) {
      return `/organization_auth/${orgAuthId}/show/member`
    } else {
      return resource ? `/${resource}` : '/'
    }
  }

  useEffect(() => setRedirect(path()), [path()])

  return !isLoading ? (
    <Edit
      actions={<ActionsToolbar source="name" i18n="resources.auth_member.edit" />}
      mutationMode="pessimistic"
      redirect={redirect}
      title={<HeaderTitle i18n="resources.auth_member.edit" />}
    >
      <SimpleForm
        toolbar={
          <ConfirmDeleteToolbar
            access={['eco.admin.member:delete', 'eco.user.member:delete']}
            redirect={redirect as string}
          />
        }
        sanitizeEmptyValues
      >
        <Grid container spacing={2}>
          <AuthMemberForm />
          <Grid item xs={12}>
            <PasswordInput
              source="password"
              label="data.password"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  ) : <></>
}

interface AuthMemberEditProps {
  id: string;
  resource: string;
}

export default AuthMemberEdit
