import { FC } from 'react'
import {
  ListProps,
  Datagrid,
  TextField,
  BooleanField,
  FunctionField,
} from 'react-admin'
import CustomList from '../../components/list/CustomList'

interface OpenLinkButtonProps {
  record: {
    signLink: string
  }
}

const OpenLinkButton: FC<OpenLinkButtonProps> = ({ record }) => (
  <a href={record.signLink} target="_blank" rel="noopener noreferrer">
    Ondertekenen
  </a>
)

const OffersList: FC<ListProps> = (props) => (
  <CustomList
    {...props}
    title="resources.offers.list"
    sort={{ field: 'id', order: 'ASC' }}
    exporter={false}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField
        source="number"
        label="data.number"
        sortable={false}
      />
      <TextField
        source="subject"
        label="data.subject"
        emptyText="-"
        sortable={false}
      />
      <BooleanField
        source="isClosed"
        label="data.isClosed"
        sortable={false}
      />
      <FunctionField
        label="Ondertekenen"
        render={(record) => (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          <OpenLinkButton record={record} />
        )}
      />
    </Datagrid>
  </CustomList>
)

export default OffersList
