import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { FilterList, useGetList } from 'react-admin'
import { AppOut } from '../../types/ecoRequests'
import { FilterMore } from './FilterMore'

export const AppFilter: FC = () => {
  const { data } = useGetList<AppOut>(
    'app',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
    }
  )

  return (
    <Root className={classes.root} label="filters.apps" icon={undefined}>
      <FilterMore data={data} />
    </Root>
  )
}

const PREFIX = 'xxllncAppFilter'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled(FilterList)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
