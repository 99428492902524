import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  Datagrid,
  DateField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField
} from 'react-admin'
import { DataField, HeaderTitle, NestedShowLayout, RestrictedTab } from '../../components'
import { ConditionalTextField } from '../../components/fields/ConditionalTextField'
import { getLocale } from '../../utils/getLocale'
import { OrganizationShowActions } from './Organization.actions'

const OrganizationShow: FC = () => (
  <StyledShow
    actions={<OrganizationShowActions />}
    title={<HeaderTitle i18n="resources.organization.title" />}
  >
    <TabbedShowLayout>
      <Tab label="show.details">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <NestedShowLayout>
              <TextField source="name" label="data.name" />
            </NestedShowLayout>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NestedShowLayout>
              <DateField
                source="started"
                label="data.started"
                locales={getLocale()}
              />
            </NestedShowLayout>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NestedShowLayout>
              <DateField
                source="ended"
                label="data.ended"
                emptyText="-"
                locales={getLocale()}
              />
            </NestedShowLayout>
          </Grid>
          <Grid item xs={12}>
            <NestedShowLayout>
              <TextField
                source="logoUrl"
                label="data.logoUrl"
                emptyText="-"
              />
            </NestedShowLayout>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NestedShowLayout>
              <TextField
                source="primaryColor"
                label="data.primaryColor"
                emptyText="-"
              />
            </NestedShowLayout>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NestedShowLayout>
              <TextField
                source="secondaryColor"
                label="data.secondaryColor"
                emptyText="-"
              />
            </NestedShowLayout>
          </Grid>
        </Grid>
      </Tab>
      <RestrictedTab
        access={['eco.admin.apporganization:read']}
        label="show.apps"
        path="apps"
      >
        <Grid container spacing={1} className={classes.table}>
          <Grid item xs={12}>
            <ReferenceManyField
              label=""
              reference="app_organization"
              target="organizationId"
              sort={{ field: 'id', order: 'ASC' }}
            >
              <Datagrid rowClick="show">
                <ConditionalTextField source="appName" label="data.appName" />
                <TextField source="totalInstanceSoftLimit" label="data.totalInstanceSoftLimit" />
                <DataField source="totalStorageSoftLimit" label="data.totalStorageSoftLimit" />
              </Datagrid>
            </ReferenceManyField>
          </Grid>
        </Grid>
      </RestrictedTab>
      <RestrictedTab
        access={['eco.admin.organization:read']}
        label="show.auth"
        path="auth"
      >
        <Grid container spacing={1} className={classes.table}>
          <Grid item xs={12}>
            <ReferenceManyField
              label=""
              reference="organization_auth"
              target="organizationId"
              sort={{ field: 'id', order: 'ASC' }}
            >
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="provider" label="data.provider" />
              </Datagrid>
            </ReferenceManyField>
          </Grid>
        </Grid>
      </RestrictedTab>
    </TabbedShowLayout>
  </StyledShow>
)

const PREFIX = 'xxllncOrganizationShow'

const classes = {
  table: `${PREFIX}-table`,
}

const StyledShow = styled(Show)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default OrganizationShow
