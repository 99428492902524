import { FC } from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { ActionsToolbar, ConfirmDeleteToolbar, HeaderTitle } from '../../components'
import { AppInstanceOut } from '../../types/ecoRequests'
import { activateTransform } from '../../utils/activateTransform'
import AppInstanceForm from './AppInstanceForm'

const AppInstanceEdit: FC = () => {
  const redirect = (_basePath, _id, data) => {
    const orgId = (data as AppInstanceOut)?.appOrganizationId
    return orgId ? `app_organization/${orgId}/show/environments` : 'app_instance'
  }

  return (
    <Edit
      actions={<ActionsToolbar source="appName" i18n="resources.app_instance.edit" />}
      redirect={redirect}
      transform={activateTransform}
      title={<HeaderTitle source="appName" i18n="resources.app_instance.edit" />}
    >
      <SimpleForm
        toolbar={<ConfirmDeleteToolbar access={['eco.admin.appinstance:delete']} />}
      >
        <AppInstanceForm />
      </SimpleForm>
    </Edit>
  )
}

export default AppInstanceEdit
