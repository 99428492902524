import { Grid } from '@mui/material'
import { FC } from 'react'
import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin'
import { hostnameValidation, required } from '../../../utils/validation'

const GoogleAppsForm: FC = () => (
  <>
    <Grid item xs={12}>
      <TextInput
        helperText=""
        label="data.domain"
        source="options.domain"
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        helperText=""
        label="data.clientId"
        source="options.clientId"
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        helperText=""
        label="data.clientSecret"
        source="options.clientSecret"
      />
    </Grid>
    <Grid item xs={12}>
      <ArrayInput
        source="options.identityProviderDomains"
        label="data.identityProviderDomains"
        validate={required()}
      >
        <SimpleFormIterator>
          <TextInput
            helperText=""
            label="data.url"
            source=""
            validate={hostnameValidation}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  </>
)

export default GoogleAppsForm
