import { FC } from 'react'
import { Create, SimpleForm } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import TicketConfigForm from './TicketConfigForm'

const TicketConfigCreate: FC = () => {
  const handleTransform = (data: { tags: string[] }) => {
    data.tags = data.tags?.filter(tag => tag)
    return data
  }

  return (
    <Create
      title={<HeaderTitle i18n="resources.ticketConfig.create" />}
      actions={<ActionsToolbar i18n="resources.ticketConfig.create" />}
      transform={handleTransform}
      redirect="show"
    >
      <SimpleForm sanitizeEmptyValues>
        <TicketConfigForm />
      </SimpleForm>
    </Create>
  )
}

export default TicketConfigCreate
