import { styled } from '@mui/material/styles'
import { Layout, LayoutProps } from 'react-admin'
import AppBar from './AppBar'
import Menu from './Menu'
import Sidebar from './Sidebar'

const layout = ({ children, ...props }: LayoutProps): JSX.Element => (
  <StyledLayout
    {...props}
    appBar={AppBar}
    sidebar={Sidebar}
    menu={Menu}
    className={classes.content}
  >
    {children}
    {/* <CheckForApplicationUpdate /> */}
  </StyledLayout>
)

const PREFIX = 'xxllncLayout'

const classes = {
  content: `${PREFIX}-content`,
}

const StyledLayout = styled(Layout)(({ theme }) => ({
  [`&.${classes.content}`]: {
    position: 'relative',
    padding: 0,
    ['& #main-content']: {
      position: 'relative',
    },
  },
}))

export default layout
