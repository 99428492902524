import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Grid, Typography } from '@mui/material'
import { useGetList, useRedirect, useTranslate } from 'react-admin'
import { AuditLogOut, ChangeType } from '../../mocks/mockTypes'

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp)
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).format(date)
}

export const RecentLogs = () => {
  const translate = useTranslate()
  const redirect = useRedirect()

  const { data: recentLogsData } = useGetList<AuditLogOut>(
    'auditlog',
    {
      pagination: { page: 1, perPage: 30 },
      sort: { field: 'timestamp', order: 'DESC' },

    }
  )

  return (
    <>
      <Box>
        <Box
          height='50px'
          display="flex"
          justifyContent='center'
          borderBottom={'1px solid #28282C'}
          p="15px"
        >
          <Typography color='#28282C' variant="h6" fontWeight="bold">
            { translate('data.recentLogs')}
          </Typography>
        </Box>
      </Box>

      <Grid
        maxHeight='585px'
        overflow='auto'
        sx={{
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#DDDDE0',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#31679B',
          },
        }}
      >
        {recentLogsData?.map((log: AuditLogOut) => (
          <Grid
            key={log.id}
            container
            display="flex"
            direction="row"
            justifyContent="space-between"
            sx={{
              'borderBottom': '2px solid #DDDDE0',
              'flexWrap': 'nowrap',
              'cursor': 'pointer',
              '&:hover': {
                backgroundColor: '#FFFFFF',
              },
            }}
            onClick={() => redirect('list', 'auditlog')}
          >
            <Box pl="15px" pr="15px" display="flex" alignItems="center" justifyContent="center">
              {log.changeType === ChangeType.Insert ? (
                <AddOutlinedIcon sx={{ color: '#006E5B', fontSize: '22px' }} />
              ) : log.changeType === ChangeType.Delete ? (
                <DeleteOutlinedIcon sx={{ color: '#E59925', fontSize: '22px' }} />
              ) : (
                <RefreshIcon sx={{ color: '#DD3228', fontSize: '22px' }} />
              )}
            </Box>

            <Box pt="5px" sx={{ flexGrow: 1, flexBasis: '50%' }}>
              <Typography color='#28282C' variant="body2" fontSize='12px' fontWeight='bold'>
                {log.summary}
              </Typography>
              <Typography variant="body2" color='#8E8E93' fontSize='12px'>
                {log.displayName}
              </Typography>
            </Box>

            <Box p="5px 15px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Typography color='#28282C' variant="body2" fontSize='12px' fontWeight='bold' noWrap>
                {formatTimestamp(log.timestamp)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default RecentLogs