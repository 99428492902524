import DateRangeIcon from '@mui/icons-material/DateRange'
import HourglassEmptySharpIcon from '@mui/icons-material/HourglassEmptySharp'
import { Box, Grid, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { useRecordContext, useTranslate } from 'react-admin'
import { Contract } from '../../types/ecoRequests'
import { dateFormatter } from '../../utils/dateFormatter'

const ContractStatus: FC = () => {
  const translate = useTranslate()
  const record = useRecordContext<Contract>()

  if (!record) return null

  return (
    <StyledGrid container spacing={2} className={classes.root}>
      <Grid item xs={12} sm={6}>
        <Box className={classes.green}>
          <HourglassEmptySharpIcon />
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
            <Typography variant="body1" sx={{ color: '#28282C', fontSize: '10pt', fontWeight: 600 }}>
              { translate('data.status') }
            </Typography>
            <Typography variant="body1" sx={{ color: '#6E6E74', fontSize: '10pt' }}>
              { record.status }
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.gray}>
          <DateRangeIcon />
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
            <Typography variant="body1" sx={{ fontSize: '10pt', fontWeight: 600 }}>
              { translate('data.endDate') }
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '8pt' }}>
              { dateFormatter(record.endDate, false) }
            </Typography>
          </Box>
        </Box>
      </Grid>
    </StyledGrid>
  )
}

const PREFIX = 'xxllncContractStatus'

const classes = {
  root: `${PREFIX}-root`,
  green: `${PREFIX}-green`,
  gray: `${PREFIX}-gray`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: { },
  [`& .${classes.green}`]: {
    background: '#D1F0D6',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    height: '60px',
    ['& .MuiSvgIcon-root']: {
      color: '#006E5B',
    },
  },
  [`& .${classes.gray}`]: {
    background: '#F2F2F2',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    height: '60px',
  },
  '& .MuiSvgIcon-root': {
    alignSelf: 'center',
  },
}))

export default ContractStatus