import { Grid } from '@mui/material'
import { FC } from 'react'
import { Datagrid, SimpleShowLayout, SortPayload, TextField, useGetList, useRecordContext } from 'react-admin'
import { Empty, LocationCreateButton } from '../../components'
import { MemberOut, OrganizationAuthOut } from '../../types/ecoRequests'
import MemberDropdown from './MemberDropdown'

const Member: FC = () => {
  const record = useRecordContext<OrganizationAuthOut>()
  const sort: SortPayload = { field: 'name', order: 'ASC' }
  const redirect = `/organization_auth/${record?.id}/show/member`
  const search = `?organizationAuthId=${record?.id}`

  const { data, total, isLoading } = useGetList<MemberOut>(
    'auth_member',
    {
      pagination: { page: 1, perPage: 99 },
      sort,
      filter: { organizationAuthId: record?.id }
    }
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <Datagrid
            sort={sort}
            data={data}
            selectedIds={[]}
            isLoading={isLoading}
            total={total}
            bulkActionButtons={false}
            resource="auth_member"
            empty={
              <Empty
                resource="auth_member"
                createButton={
                  <LocationCreateButton
                    access={[
                      'eco.admin.member:write',
                      'eco.user.member:write'
                    ]}
                    align="center"
                    resource="auth_member"
                    search={search}
                    label="actions.add"
                  />
                }
              />
            }
          >
            <TextField label="data.name" source="name" />
            <TextField label="data.email" source="email" />
            <MemberDropdown redirect={redirect} />
          </Datagrid>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  )
}

export default Member
