import { Grid } from '@mui/material'
import { FC } from 'react'
import { TextInput } from 'react-admin'
import { required } from '../../../utils/validation'

interface WaadFormProps {
  edit?: boolean
}

const WaadForm: FC<WaadFormProps> = ({ edit = false }) => (
  <>
    <Grid item xs={12}>
      <TextInput
        helperText={edit ? 'strategy.requiredDuringEdit' : ''}
        label="data.azureDomain"
        source="options.domain"
        validate={required()}
        isRequired
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        helperText={edit ? 'strategy.requiredDuringEdit' : ''}
        label="data.applicationId"
        source="options.applicationId"
        validate={required()}
        isRequired
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        helperText={edit ? 'strategy.requiredDuringEdit' : ''}
        label="data.secretValue"
        source="options.secretValue"
        validate={required()}
        isRequired
      />
    </Grid>
  </>
)

export default WaadForm
