import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Grid } from '@mui/material'
import { FC, useState } from 'react'
import {
  Datagrid,
  DeleteButton,
  SimpleShowLayout,
  SortPayload,
  TextField,
  useGetList,
  useRecordContext,
  useTranslate
} from 'react-admin'
import { RestrictedButton } from '../../components'
import { AppOrganizationModuleOut, AppOrganizationOut } from '../../types/ecoRequests'

const ModuleInstances: FC = () => {
  const translate = useTranslate()
  const [sort, setSort] = useState<SortPayload>({ field: 'id', order: 'ASC' })
  const record = useRecordContext<AppOrganizationOut>()

  const { data, total, isLoading } = useGetList<AppOrganizationModuleOut>(
    'app_organization_module',
    {
      pagination: { page: 1, perPage: 100 },
      sort,
      filter: { appOrganizationId: record?.id }
    }
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <Datagrid
            sort={sort}
            data={data}
            isLoading={isLoading}
            resource="app_instance"
            rowClick={false}
            bulkActionButtons={false}
            selectedIds={[]}
            setSort={() => setSort({ field: sort.field, order: sort.order || 'ASC' })}
            total={total}
          >
            <TextField source="appModuleName" label="data.name" />
            <TextField source="appModuleDescription" label="data.description" />
            <RestrictedButton
              access={['eco.admin.app:delete']}
              align="right"
            >
              {(): JSX.Element | null => {
                const moduleRecord = useRecordContext<AppOrganizationModuleOut>()
                return (
                  <DeleteButton
                    redirect={false}
                    mutationMode="pessimistic"
                    record={moduleRecord}
                    resource="app_organization_module"
                    confirmTitle={translate('message.deleteTitle', { name: moduleRecord?.appModuleName })}
                    label=""
                    icon={<DeleteOutlinedIcon />}
                  />
                )
              }}
            </RestrictedButton>
          </Datagrid>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  )
}

export default ModuleInstances
