import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { xxllncColor } from '../../layout/themes'
import { AppOverview } from '../../types/ecoRequests'
import { AppCardActions } from './AppCard.actions'

export const AppCard: FC<AppOverview> = ({
  name,
  isActiveForMyOrganization,
  category,
  icon,
}) => {
  const translate = useTranslate()

  return (
    <Root className={classes.root}>
      <CardHeader
        className={classes.header}
        action={<AppCardActions isActiveForMyOrganization={isActiveForMyOrganization} icon={icon} />}
      />
      <CardContent>
        <Typography variant="h6" component="h6" className={classes.title}>
          {name}
        </Typography>
        <Typography variant="body2" component="p" className={classes.description}>
          {translate(`domains.${category}`)}
        </Typography>
      </CardContent>
    </Root>
  )
}

const PREFIX = 'xxllncAppCard'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  header: `${PREFIX}-header`,
}

const Root = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    position: 'relative',
    textAlign: 'center',
  },
  [`& .${classes.title}`]: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(.5),
  },
  [`& .${classes.description}`]: {
    color: xxllncColor.dark60,
    fontSize: 14,
  },
  [`& .${classes.header}`]: {
    boxSizing: 'border-box',
    height: 'auto',
    marginBottom: 0,
    padding: theme.spacing(4, 4, 0, 4),
    position: 'relative',
    width: '100%',
  },
  ['& .MuiCardHeader-action']: {
    height: '100%',
    marginRight: 0,
    marginTop: 0,
    width: '100%',
  },
}))
