import ImageIcon from '@mui/icons-material/Image'
import { Grid, styled, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { Show, SimpleShowLayout, useGetList, useRecordContext, useTranslate } from 'react-admin'
import { HeaderTitle } from '../../components'
import { DataUsageList } from '../../components/list/DataUsageList'
import { GenericOrganizationList } from '../../components/list/GenericOrganizationList'
import { LicenceList } from '../../components/list/LicencesList'
import { ReportList } from '../../components/list/ReportList'
import { AppInstanceOut, AppOrganizationOut, AppOverview } from '../../types/ecoRequests'
import AppShowActions from './App.actions'

const AppShowDetails: FC = () => {
  const record = useRecordContext<AppOverview>()
  const translate = useTranslate()
  const [totalUsage, setTotalUsage] = useState<number>(0)

  const { data: appInstances } = useGetList<AppInstanceOut>(
    'app_instance',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { appId: record?.id }
    }
  )

  const { data: appOrganization } = useGetList<AppOrganizationOut>(
    'app_organization',
    {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'ASC' },
      filter: { appId: record?.id }
    }
  )

  const storageSoftLimit = appOrganization?.[0]?.totalInstanceSoftLimit || 0 * (appOrganization?.[0]?.totalStorageSoftLimit || 0) || 0

  return (
    <SimpleShowLayout>
      <Grid container>
        <Grid item xs={12} className={classes.teams}>
          <Typography variant="body1">
            <b>{translate('resources.app_overview.team')}</b>
            <span>{record?.category ? translate(`domains.${record?.category}`) : '-'}</span>
            { record?.isActiveForMyOrganization && <>
              <b style={{ paddingLeft: '20px', marginLeft: '20px', borderLeft: '1px solid #C5C5C4', }}>
                {translate('resources.app_overview.fairUse')}
              </b>
              <span >{translate('data.usageInGb', { amount: totalUsage, max: storageSoftLimit })}</span>
            </>}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body1" style={{ marginBottom: '30px' }}>
        {record?.imageUrl ? (
          <img src={record?.imageUrl} alt={record?.name || ''} className={classes.image} />
        ) : (
          <div className={classes.fallback}><ImageIcon sx={{ fontSize: 80 }} /></div>
        )}
        <span>{record?.description}</span>
      </Typography>
      <GenericOrganizationList record={record} resource="module" />
      <GenericOrganizationList record={record} resource="integration" />
      { appInstances && <LicenceList /> }
      <ReportList record={record} />
      { appInstances && <DataUsageList appInstances={appInstances} setTotalUsage={setTotalUsage} /> }
    </SimpleShowLayout>
  )
}

const AppShow: FC = () => (
  <StyledShow
    className={classes.root}
    actions={<AppShowActions icon="icon" source="name" />}
    title={<HeaderTitle i18n="resources.app_overview.title" />}
  >
    <AppShowDetails />
  </StyledShow>
)

const PREFIX = 'xxllncAppShow'

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  text: `${PREFIX}-text`,
  teams: `${PREFIX}-teams`,
  image: `${PREFIX}-image`,
  fallback: `${PREFIX}-fallback`,
}

const StyledShow = styled(Show)(({ theme }) => ({
  [`&.${classes.root}`]: { },
  [`& .${classes.card}`]: { },
  [`& .${classes.text}`]: {
    lineHeight: '24px',
    whiteSpace: 'pre-wrap',
  },
  [`& .${classes.teams}`]: {
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    ['& span']: {
      marginLeft: theme.spacing(1),
    },
  },
  [`& .${classes.image}`]: {
    width: '200px',
    height: 'auto',
    float: 'right',
    margin: '0 0 10px 20px'
  },
  [`& .${classes.fallback}`]: {
    width: '200px',
    height: '125px',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'right',
    margin: '0 0 10px 20px'
  },
}))

export default AppShow
