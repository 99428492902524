import { FC } from 'react'
import { Edit } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { AppLicenceOut } from '../../types/ecoRequests'
import AppLicenceForm from './AppLicenceForm'

const AppModuleEdit: FC = () => {
  const redirect = (basePath, _id, data) => {
    const appId = (data as AppLicenceOut)?.appId
    return appId ? `app/${appId}/show/licences` : `${basePath || '/'}`
  }

  return (
    <Edit
      title={<HeaderTitle i18n="resources.app_licence.edit" />}
      actions={<ActionsToolbar source="name" i18n="resources.app_licence.edit" />}
      redirect={redirect}
    >
      <AppLicenceForm />
    </Edit>
  )
}


export default AppModuleEdit
