import TokenIcon from '@mui/icons-material/Token'
import { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { ReactElement, useCallback, useState } from 'react'
import { Button as RaButton, RaRecord, useNotify, useTranslate } from 'react-admin'
import { Dialog } from '../../components'
import { getApiUrl } from '../../providers/data'
import { debug } from '../../test'
import authToken from '../../utils/authToken'

/**
 * Generates token.
 *
 * @example
 * <GenerateTokenButton
 *   content={content}
 *   errorText="Something went wrong when generating token..."
 *   label={label}
 *   onClose={onClose}
 *   record={record}
 *   resource={resource}
 *   title={title}
 * />
 */
export const GenerateTokenButton = (props: GenerateTokenButtonProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false)
  const [token, setToken] = useState('')
  const translate = useTranslate()
  const {
    icon = defaultIcon,
    content = translate('notification.token.content', { token }),
    label = 'actions.connect',
    onClose,
    record,
    resource,
    title = 'notification.token.title',
    variant,
    ...rest
  } = props
  const authenticationToken = authToken.getToken() || ''
  const notify = useNotify()

  const url = `${getApiUrl(resource)}/${resource}/${record?.id}/generate_token`

  const handleGenerateToken = useCallback(
    () => {
      fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: authenticationToken,
          ContentType: 'text/plain'
        },
      }).then((response) => {
        if (!response.ok) {
          // make the promise be rejected if we didn't get a 2xx response
          throw new Error('Not 2xx response', { cause: response })
        } else {
          return response.text()
        }
      }).then((text) => {
        setToken(text.replaceAll('"', '').trim())
        setOpen(true)
      }).catch(error => {
        debug && console.error('GenerateTokenButton error:', error)
        notify('notification.token.warning', { type: 'error' })
      })
      setOpen(false)
    },
    [resource, url]
  )

  const handleDialogClose = (): void => {
    setOpen(false)
    onClose()
  }

  return (
    <>
      <RaButton
        label={label}
        variant={variant}
        style={{ width: '100%' }}
        color="primary"
        onClick={handleGenerateToken}
        {...(rest as any)}
      >
        {icon}
      </RaButton>
      <Dialog
        close="actions.done"
        content={content}
        isOpen={isOpen}
        onClose={handleDialogClose}
        title={title}
      />
    </>
  )
}

const PREFIX = 'xxllncGenerateTokenButton'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.item}`]: {},
}))

const defaultIcon = <TokenIcon />

interface Props {
  icon?: ReactElement;
  label?: string;
  onClose: () => void;
  record?: RaRecord;
  resource: string;
  variant?: string;
  confirmTitle?: string;
  confirmContent?: string;
}

type GenerateTokenButtonProps = Props & MuiButtonProps

GenerateTokenButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  variant: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
}
