/* eslint-disable max-len */
export const Xxllnc = ({ className, fill }: SvgType): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={fill} className={className} viewBox="0 0 175.3973389 42.227169">
    <path d="M78.2620544,40.7328224h7.1602783V0.3559315h-7.1602783V40.7328224z M94.7875061,40.7328224h7.1602783
	V0.3559315h-7.1602783V40.7328224z M64.4803162,9.4169054L53.4260559,20.8089352L42.3717957,9.4169054l-4.7658081,4.911438
	l11.0542603,11.3919687L37.6059875,37.112339l4.7658081,4.911438l11.0542603-11.3920269L64.4803162,42.023777l4.7658081-4.911438
	L58.191864,25.7203121l11.0542603-11.3919687L64.4803162,9.4169054z M27.1794128,9.4169054L16.1251526,20.8089352
	L5.0708923,9.4169054l-4.7658081,4.911438l11.0542603,11.3919687L0.3050841,37.112339l4.7658081,4.911438l11.0542603-11.3920269
	L27.1794128,42.023777l4.7658081-4.911438L20.8909607,25.7203121l11.0542603-11.3919687L27.1794128,9.4169054z
	 M124.8292542,10.6589708c-8.2484741,0-14.2631836,5.1537476-14.2631836,14.1484385v15.9254131h7.1602783V24.9796505
	c0-4.7544556,3.0348511-7.3325806,7.1029053-7.3325806c4.0681152,0,7.1029663,2.578125,7.1029663,7.3325806v15.7531719h7.1602783
	V24.8074093C139.0924988,15.8127184,133.0203552,10.6589708,124.8292542,10.6589708z M160.3836365,34.7610817
	c-4.5479736,0-8.2351074-3.9302349-8.2351074-8.7791119c0-4.8459473,3.6871338-8.7761841,8.2351074-8.7761841
	c3.3699341,0,6.2583618,2.1628418,7.5334473,5.2529297h7.1751709c-1.2601929-6.9884043-7.1054077-11.7994394-14.6101074-11.7994394
	c-8.7623901,0-15.1791992,6.4709482-15.1791992,15.3517466c0,8.822691,6.4168091,15.2965679,15.1791992,15.2965679
	c7.5046997,0,13.2924805-4.758728,14.6101074-11.7471294h-7.203125
	C166.6020203,32.6214943,163.7328796,34.7610817,160.3836365,34.7610817z"/>
  </svg>
)

export const DigiD = ({ className, fill }: SvgType): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={fill} className={className} viewBox="0 0 140 140">
    <title>DigiD inloggen flow v4</title>
    <g id="content">
      <rect width="140" height="140" rx="19.75" ry="19.75"/>
      <path fill="#fff" d="M15.56,106.92V73.84H25c11.2,0,17.4,5.6,17.4,15.58,0,12-6.76,17.5-17.6,17.5Zm6-5h3.48c6.66,0,11-3.58,11-12.2,0-7.17-4.34-10.9-11.45-10.9h-3Z"/>
      <path fill="#fff" d="M50.61,71.62A3.1,3.1,0,0,1,54,74.9a3.14,3.14,0,0,1-3.38,3.23,3,3,0,0,1-3.33-3.23A3.13,3.13,0,0,1,50.61,71.62Zm2.87,35.3H47.79V82.11l5.69-.25Z"/>
      <path fill="#fff" d="M67.3,102.08h6c5.35,0,7.62,2.12,7.62,6.15,0,4.75-3.94,8.58-13,8.58-7.11,0-10.39-2.52-10.39-6.41A5.72,5.72,0,0,1,61.25,105v-.16a3.73,3.73,0,0,1-2-3.32,4,4,0,0,1,2.78-3.74v-.15c-2.27-1.26-3.58-3.38-3.58-6.6,0-6.05,4.08-9.33,10.39-9.33a13.88,13.88,0,0,1,3.83.5h8.07V86H76.93v.15a6,6,0,0,1,2,4.69c0,5.5-3.73,8.38-10.59,8.38a12,12,0,0,1-3.13-.41,1.87,1.87,0,0,0-1.05,1.57C64.13,101.48,65,102.08,67.3,102.08Zm1.06,10.49c5.45,0,7.41-1.36,7.41-3.33,0-1.46-.9-2.27-3.38-2.32l-8.26-.25a2.89,2.89,0,0,0-1.37,2.62C62.76,111.26,64.48,112.57,68.36,112.57Zm5.25-22.19c0-3-1.67-4.64-4.85-4.64-2.82,0-4.84,1.62-4.84,5s1.82,4.59,4.74,4.59C71.79,95.32,73.61,93.86,73.61,90.38Z"/>
      <path fill="#e17000" d="M87.47,71.62a3.1,3.1,0,0,1,3.38,3.28,3.14,3.14,0,0,1-3.38,3.23,3,3,0,0,1-3.32-3.23A3.13,3.13,0,0,1,87.47,71.62Zm2.88,35.3h-5.7V82.11l5.7-.25Z"/>
      <path fill="#e17000" d="M97.56,106.92V73.84H107c11.2,0,17.4,5.6,17.4,15.58,0,12-6.76,17.5-17.6,17.5Zm6-5h3.48c6.66,0,11-3.58,11-12.2,0-7.17-4.34-10.9-11.45-10.9h-3Z"/>
    </g>
  </svg>
)

export const Dot = ({ className, fill }: SvgType): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} height="5px" width="5px" viewBox="0 0 1 1">
    <circle fill={fill} cx="0.5" cy="0.5" r="0.5" />
  </svg>
)

export const moreAppsBase64 = 'PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yXzM1KSI+DQo8cGF0aCBkPSJNMjIuNSAwSDBWMjIuNUgyMi41VjBaIiBmaWxsPSIjMzE2NzlCIi8+DQo8L2c+DQo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDFfMl8zNSkiPg0KPHBhdGggZD0iTTIyLjUgMjcuNUgwVjUwSDIyLjVWMjcuNVoiIGZpbGw9IiNFNTk5MjUiLz4NCjwvZz4NCjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMl8yXzM1KSI+DQo8cGF0aCBkPSJNNTAgMEgyNy41VjIyLjVINTBWMFoiIGZpbGw9IiMwMDZFNUIiLz4NCjwvZz4NCjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwM18yXzM1KSI+DQo8cGF0aCBkPSJNNTAgMjcuNUgyNy41VjUwSDUwVjI3LjVaIiBmaWxsPSIjRTE1QjMyIi8+DQo8L2c+DQo8ZGVmcz4NCjxjbGlwUGF0aCBpZD0iY2xpcDBfMl8zNSI+DQo8cmVjdCB3aWR0aD0iMjIuNSIgaGVpZ2h0PSIyMi41IiByeD0iOCIgZmlsbD0id2hpdGUiLz4NCjwvY2xpcFBhdGg+DQo8Y2xpcFBhdGggaWQ9ImNsaXAxXzJfMzUiPg0KPHJlY3QgeT0iMjcuNSIgd2lkdGg9IjIyLjUiIGhlaWdodD0iMjIuNSIgcng9IjgiIGZpbGw9IndoaXRlIi8+DQo8L2NsaXBQYXRoPg0KPGNsaXBQYXRoIGlkPSJjbGlwMl8yXzM1Ij4NCjxyZWN0IHg9IjI3LjUiIHdpZHRoPSIyMi41IiBoZWlnaHQ9IjIyLjUiIHJ4PSI4IiBmaWxsPSJ3aGl0ZSIvPg0KPC9jbGlwUGF0aD4NCjxjbGlwUGF0aCBpZD0iY2xpcDNfMl8zNSI+DQo8cmVjdCB4PSIyNy41IiB5PSIyNy41IiB3aWR0aD0iMjIuNSIgaGVpZ2h0PSIyMi41IiByeD0iOCIgZmlsbD0id2hpdGUiLz4NCjwvY2xpcFBhdGg+DQo8L2RlZnM+DQo8L3N2Zz4NCg=='

interface SvgType {
  className?: string;
  fill?: string;
}
