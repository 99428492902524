import { Grid } from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'
import { ArrayInput, FormDataConsumer, RadioButtonGroupInput, SimpleFormIterator, TextInput } from 'react-admin'
import { hostnameValidation, required } from '../../utils/validation'
import AuthConnectionOptions from './AuthConnection.options'

const options = [
  { id: 'adfs', name: 'strategy.adfs' },
  { id: 'auth0', name: 'strategy.auth0' },
  { id: 'googleapps', name: 'strategy.googleapps' },
  { id: 'oauth2', name: 'strategy.oauth2' },
  { id: 'waad', name: 'strategy.waad' },
]

const AuthConnectionForm: FC<AuthConnectionFormProps> = ({ view, organizationName }) => {
  const [type, setType] = useState<string>('auth0')

  const onTypeChange = (event: ChangeEvent<HTMLInputElement>) => setType(event.target.value)

  return (
    <>
      {view === 'create' && (
        <Grid item xs={12}>
          <RadioButtonGroupInput
            source="strategy"
            defaultValue={type}
            choices={options}
            onChange={onTypeChange}
            helperText=""
            isRequired
          />
        </Grid>
      )}
      {view === 'edit' && (
        <Grid item xs={12}>
          <TextInput
            helperText=""
            source="id"
            label="data.id"
            disabled
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormDataConsumer>{({formData}) =>
          formData.strategy ? <TextInput
            helperText=""
            source="name"
            label="data.name"
            defaultValue={`${organizationName}-${formData.strategy}`}
            isRequired={view === 'create' ? true : false}
            InputProps={{ disabled: view === 'edit' ? true : false }}
          /> : null
        }</FormDataConsumer>
      </Grid>
      <AuthConnectionOptions type={view === 'create' ? type : undefined} />
      <Grid item xs={12}>
        <ArrayInput
          source="hostnames"
          validate={required()}
        >
          <SimpleFormIterator>
            <TextInput
              helperText=""
              label="data.url"
              source=""
              validate={hostnameValidation}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </>
  )
}

interface AuthConnectionFormProps {
  view: 'create' | 'edit';
  organizationName?: string;
}

export default AuthConnectionForm
