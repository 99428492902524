import { FC } from 'react'
import { Create, SimpleForm } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import OrganizationForm from './OrganizationForm'

const OrganizationCreate: FC = () => (
  <Create
    title={<HeaderTitle i18n="resources.organization.create" />}
    actions={<ActionsToolbar i18n="resources.organization.create" />}
    redirect="show"
  >
    <SimpleForm
      sanitizeEmptyValues
    >
      <OrganizationForm view="create" />
    </SimpleForm>
  </Create>
)

export default OrganizationCreate
