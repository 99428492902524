/* eslint-disable */
import authToken from './authToken'

const matchKey = (objectToSearch: string[], keyToFind: string): string | null => {
  for (const k in objectToSearch) {
    if (k.toLowerCase().includes(keyToFind.toLowerCase()))
      return objectToSearch[k]
  }
  return null
}

export const getFromToken = (value: string): string | null => {
  try {
    const token = authToken.getToken() || ''
    const parsed = JSON.parse(window.atob(token.split('.')[1])) as string[]
    return matchKey(parsed, value)
  } catch (err) {
    return null
  }
}
