import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { FC, useCallback } from 'react'
import { Button as RaButton, useNotify, useRecordContext, useRefresh } from 'react-admin'
import { getApiUrl } from '../../providers/data'
import { debug } from '../../test'
import authToken from '../../utils/authToken'

export const DeleteAuthMemberRoleButton: FC<DeleteAuthMemberRoleButtonProps> = (props) => {
  const refresh = useRefresh()
  const record = useRecordContext()
  const { authMemberId } = props
  const authenticationToken = authToken.getToken() || ''
  const notify = useNotify()

  const url = `${getApiUrl('auth_member_roles')}/auth_member_roles/${record?.id}::${authMemberId}`

  const deleteAuthMemberRole = useCallback(
    () => {
      fetch(url, {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: {
          Authorization: authenticationToken,
          ContentType: 'text/plain'
        },
      }).then((response) => {
        if (!response.ok) {
          throw new Error('Not 2xx response', { cause: response })
        } else {
          return response.text()
        }
      }).catch(error => {
        debug && console.error('Delete authMemberRole error:', error)
        notify('notification.authMemberRole.warning', { type: 'error' })
      }).finally(() => {
        refresh()
      })
    },
    [url]
  )

  return (
    <RaButton
      label={''}
      color="primary"
      onClick={deleteAuthMemberRole}
    >
      <DeleteOutlinedIcon />
    </RaButton>
  )
}

interface Props {
  authMemberId: string;
}

type DeleteAuthMemberRoleButtonProps = Props & MuiButtonProps
