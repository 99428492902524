import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DateField, ImageField, ShowProps, SimpleShowLayout, TextField, UrlField, useRecordContext, useTranslate } from 'react-admin'
import { AppOut } from '../../types/ecoRequests'
import { getLocale } from '../../utils/getLocale'

const Details = (props: ShowProps): JSX.Element => {
  const record = useRecordContext<AppOut>(props)
  const translate = useTranslate()

  return (
    <StyledGrid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <TextField
            source="name"
            label="data.name"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            component="span"
            className={classes.label}
          >
            {translate('data.category')}
          </Typography>
          <Typography variant="body2" component="span" className={classes.value}>
            { record?.category ? translate(`domains.${record.category}`) : '-'}
          </Typography>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <TextField
            source="description"
            label="data.description"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <UrlField
            source="orderUrl"
            label="data.orderUrl"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <UrlField
            source="demoUrl"
            label="data.demoUrl"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <TextField
            source="totalInstanceSoftLimit"
            label="data.totalInstanceSoftLimit"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <TextField
            source="totalStorageSoftLimit"
            label="data.totalStorageSoftLimit"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <TextField
            source="vendor"
            label="data.vendor"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <UrlField
            source="vendorUrl"
            label="data.vendorUrl"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <UrlField
            source="manifestUrl"
            label="data.manifestUrl"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <ImageField
            source="imageUrl"
            label="data.imageUrl"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <TextField
            source="hexColor"
            label="data.hexColor"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <UrlField
            source="eLearningUrl"
            label="data.eLearningUrl"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <UrlField
            source="userGuideUrl"
            label="data.userGuideUrl"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <UrlField
            source="administratorGuideUrl"
            label="data.administratorGuideUrl"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <UrlField
            source="apiDocumentationUrl"
            label="data.apiDocumentationUrl"
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <DateField
            source="started"
            label="data.started"
            locales={getLocale()}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <DateField
            source="ended"
            label="data.ended"
            locales={getLocale()}
            emptyText="-"
          />
        </SimpleShowLayout>
      </Grid>
    </StyledGrid>
  )
}

const PREFIX = 'xxllncManageAppShowDetails'

const classes = {
  label: `${PREFIX}-label`,
  value: `${PREFIX}-value`,
  svgField: `${PREFIX}-svgField`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.label}`]: {
    fontSize: 12,
    textTransform: 'uppercase',
    padding: '0',
  },
  [`& .${classes.value}`]: {
    lineHeight: 1,
    padding: '0',
    margin: '0',
  },
  [`& .${classes.svgField}`]: {
    ['& svg']: {
      width: '100%'
    }
  },
}))

export default Details
