import { styled } from '@mui/material/styles'
import { usePermissions } from 'react-admin'
import { IPermissions } from '../../types/ecoTypes'
import AdminDashboard from './AdminDashboard'
import OrganizationDashboard from './OrganizationDashboard'

const Dashboard = () => {
  const { permissions } = usePermissions() as IPermissions
  const isAdmin = (permissions !== undefined && permissions.scopes?.some((i: string) => i.includes('eco.admin')))

  return (
    <Root className={classes.root}>
      {isAdmin ? <AdminDashboard /> : <OrganizationDashboard />}
    </Root>
  )
}

export default Dashboard

const PREFIX = 'xxllncDashboard'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    [theme.breakpoints.down('md')]: {
      margin: '0 10px',
    }
  },
}))