import { Grid } from '@mui/material'
import { FC } from 'react'
import { TextInput } from 'react-admin'
import { required } from '../../utils/validation'

const AuthMemberForm: FC = () => (
  <>
    <Grid item xs={12}>
      <TextInput
        helperText=""
        label="data.name"
        source="name"
        validate={required()}
        isRequired
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        helperText=""
        label="data.email"
        source="email"
        validate={required()}
        isRequired
      />
    </Grid>
  </>
)

export default AuthMemberForm
