import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { CardContent, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  Datagrid,
  EditButton,
  Empty,
  FilterLiveSearch,
  List,
  ListProps,
  TextField,
  useTranslate
} from 'react-admin'
import {
  AppFilter,
  EmptyContainer,
  FilterSidebarCard,
  HasOrganizationFilter,
  LinkRecordButton,
  ListActionBulkDelete, RestrictedButton
} from '../../components'
import { ConditionalTextField } from '../../components/fields/ConditionalTextField'

const FilterSidebar: FC = () => {
  const translate = useTranslate()

  return (
    <FilterSidebarCard>
      <CardContent>
        <Typography variant="h6" component="div">
          {translate('filters.title')}
        </Typography>
        <FilterLiveSearch source="q" />
        <HasOrganizationFilter />
        <AppFilter />
      </CardContent>
    </FilterSidebarCard>
  )
}

const AppInstanceList: FC<ListProps> = (props) =>(
  <StyledList
    {...props}
    sort={{ field: 'id', order: 'ASC' }}
    aside={<FilterSidebar />}
    exporter={false}
    empty={
      <EmptyContainer>
        <Empty />
      </EmptyContainer>
    }
  >
    <Datagrid
      bulkActionButtons={
        ListActionBulkDelete({
          access:['eco.admin.organization:delete'],
          mutationMode: 'pessimistic'
        })
      }
      rowClick="show"
    >
      <ConditionalTextField
        source="appName"
        label="data.name"
        emptyText="-"
        sortBy="app.name"
      />
      <TextField
        source="description"
        label="data.description"
        emptyText="-"
      />
      <RestrictedButton
        access={['eco.admin.organization:write']}
        align="right"
      >
        {(): JSX.Element => (
          <>
            <LinkRecordButton label="" source="organizationId" />
            <EditButton label="" icon={<EditOutlinedIcon />} />
          </>
        )}
      </RestrictedButton>
    </Datagrid>
  </StyledList>
)

const PREFIX = 'xxllncAppInstanceList'

const classes = {
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default AppInstanceList
