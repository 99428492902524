import Inbox from '@mui/icons-material/Inbox'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  CreateButton,
  useGetResourceLabel,
  useResourceContext,
  useResourceDefinition,
  useTranslate
} from 'react-admin'
import { border, xxllncColor } from '../layout/themes'

export const Empty = (props: EmptyProps) => {
  const {
    className,
    createButton = <CreateButton variant="contained" />
  } = props
  const { hasCreate } = useResourceDefinition(props)
  const resource = useResourceContext(props)

  const translate = useTranslate()

  const getResourceLabel = useGetResourceLabel()
  const resourceName = translate(`resources.${resource}.forcedCaseName`, {
    // eslint-disable-next-line camelcase
    smart_count: 0,
    _: getResourceLabel(resource || '', 0),
  })

  const emptyMessage = translate('ra.page.empty', { name: resourceName })
  const inviteMessage = translate('ra.page.invite')

  return (
    <Root className={className}>
      <div className={EmptyClasses.message}>
        <Inbox className={EmptyClasses.icon} />
        <Typography variant="h4" paragraph>
          {translate(`resources.${resource}.empty`, {
            _: emptyMessage,
          })}
        </Typography>
        {hasCreate && (
          <Typography variant="body1">
            {translate(`resources.${resource}.invite`, {
              _: inviteMessage,
            })}
          </Typography>
        )}
      </div>
      {hasCreate && (
        <div className={EmptyClasses.toolbar}>
          {createButton}
        </div>
      )}
    </Root>
  )
}

export interface EmptyProps {
  resource?: string;
  hasCreate?: boolean;
  className?: string;
  createButton?: JSX.Element;
}

const PREFIX = 'xxllncEmpty'

export const EmptyClasses = {
  message: `${PREFIX}-message`,
  icon: `${PREFIX}-icon`,
  toolbar: `${PREFIX}-toolbar`,
}

const Root = styled('span', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  flex: 1,
  [`& .${EmptyClasses.icon}`]: {
    backgroundColor: xxllncColor.light20,
    borderRadius: border.button.radius,
    boxSizing: 'border-box',
    height: theme.spacing(5),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(.75),
    width: theme.spacing(5),
  },
  [`& .${EmptyClasses.message}`]: {
    boxSizing: 'border-box',
    color: theme.palette.mode === 'light' ? 'inherit' : theme.palette.text.primary,
    margin: 0,
    opacity: theme.palette.mode === 'light' ? 0.5 : 0.8,
    padding: theme.spacing(6),
    textAlign: 'center',
    ['& .MuiTypography-root']: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  [`& .${EmptyClasses.toolbar}`]: {
    marginTop: 0,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    textAlign: 'center',
  },
}))
