import { FC } from 'react'
import { required, SelectInput, SimpleForm, TextInput } from 'react-admin'
import { LicenceTypes } from '../../types/ecoRequests'
import { toReadableString } from '../../utils/textFormatter'

interface AppLicenceFormProps {
  appId?: string | null
}

const AppLicenceForm: FC<AppLicenceFormProps> = ({ appId }) => {
  const getChoicesArray = Object.values(LicenceTypes)
  const choices = getChoicesArray.map(choice => ({
    id: choice,
    name: toReadableString(choice || '-'),
  }))

  return (
    <SimpleForm
      defaultValues={{ appId }}
      sanitizeEmptyValues
    >
      <TextInput
        source="name"
        label="data.name"
        helperText=""
        validate={required()}
        multiline
      />
      <TextInput
        source="description"
        label="data.description"
        helperText=""
        validate={required()}
        multiline
      />
      <SelectInput
        choices={choices}
        helperText=""
        label="data.type"
        source="type"
        validate={required()}
        isRequired
      />
    </SimpleForm>
  )
}

export default AppLicenceForm
