import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceField,
  Show, Tab,
  TabbedShowLayout,
  TextField,
  useTranslate
} from 'react-admin'
import { ChipArrayField, HeaderTitle, NestedShowLayout } from '../../components'
import { getLocale } from '../../utils/getLocale'
import { TicketConfigShowActions } from './TicketConfig.actions'

const TicketConfigShow: FC = () => {
  const translate = useTranslate()

  return (
    <StyledShow
      actions={<TicketConfigShowActions/>}
      title={<HeaderTitle i18n="resources.ticketConfig.title" />}
    >
      <TabbedShowLayout>
        <Tab label="show.details">
          <NestedShowLayout>
            <TextField source="description" label="data.description" />
            <DateField
              source="created"
              label="data.created"
              locales={getLocale()}
            />
            <ReferenceField source="category_id" reference="tickets/incidents/categories">
              <TextField
                source="name"
                label="data.category"
                emptyText="-"
              />
            </ReferenceField>
            <ReferenceField source="sub_category_id" reference="tickets/incidents/sub_categories">
              <TextField
                source="name"
                label="data.subCategory"
                emptyText="-"
              />
            </ReferenceField>
            <ReferenceField source="operator_group_id" reference="tickets/incidents/operator_groups">
              <TextField
                source="groupName"
                label="data.group"
                emptyText="-"
              />
            </ReferenceField>
            <ChipArrayField
              label="data.tags"
              source="tags"
              emptyText="-"
            />
          </NestedShowLayout>
        </Tab>
        <Tab label="show.questions">
          <NestedShowLayout>
            <ArrayField source="questions" label="show.questions">
              <Datagrid bulkActionButtons={false}>
                <TextField source="label" />
                <TextField source="type" />
                <FunctionField
                  label="data.isRequired"
                  render={({ isRequired }) => translate(isRequired === 'True' ? 'data.yes' : 'data.no')} />
              </Datagrid>
            </ArrayField>
          </NestedShowLayout>
        </Tab>
      </TabbedShowLayout>
    </StyledShow>
  )
}

const PREFIX = 'xxllncTicketConfigShow'

const classes = {
  table: `${PREFIX}-table`,
}

const StyledShow = styled(Show)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default TicketConfigShow
