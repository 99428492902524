import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import TicketConfigCreate from './TicketConfig.create'
import TicketConfigEdit from './TicketConfig.edit'
import TicketConfigList from './TicketConfig.list'
import TicketConfigShow from './TicketConfig.show'

const resource = {
  list: TicketConfigList,
  show: TicketConfigShow,
  create: TicketConfigCreate,
  edit: TicketConfigEdit,
  icon: ConfirmationNumberIcon,
}

export default resource
