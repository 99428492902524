import { MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useNotify, useRefresh, useTranslate, useUpdate } from 'react-admin'
import { HostAliasOut } from '../../types/ecoRequests'

/**
 * Sets default menu item
 *
 * @example
 * <SetDefaultMenuItem
 *   onClose={handleClose}
 *   {...record}
 * />
 */
const SetDefaultMenuItem = forwardRef<any, any>(({id, onClose, ...props}: Props, ref) => {
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const [update, { isPending, error }] = useUpdate()

  const handleClick = async (): Promise<void> => {
    await update(
      'hostalias',
      {
        id,
        data: { ...props, primaryHostalias: true },
        previousData: props,
      },
      {
        onSuccess: () => {
          notify(
            'resources.hostalias.notification.setDefault.approved_success',
            {
              type: 'info',
              undoable: false
            }
          )
          refresh()
        },
        onError: () => {
          notify(
            'resources.reviews.notification.setDefault.approved_error',
            {
              type: 'warning',
            }
          )
        },
      }
    )
    onClose()
  }

  if (error) { return <p>ERROR</p> }

  return (
    <StyledMenuItem
      className={classes.root}
      disabled={isPending}
      onClick={() => handleClick}
      ref={ref}
    >
      {translate('resources.app_instance.setDefault')}
    </StyledMenuItem>
  )
})

const PREFIX = 'xxllncSetDefaultMenuItem'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`&.${classes.root}`]: {},
}))

interface Props extends HostAliasOut {
  onClose: () => void;
}

SetDefaultMenuItem.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default SetDefaultMenuItem
