import ReceiptIcon from '@mui/icons-material/Receipt'
import ContractList from './Contracts.list'
import ContractsShow from './Contracts.show'

const resource = {
  list: ContractList,
  show: ContractsShow,
  icon: ReceiptIcon,
}

export default resource
