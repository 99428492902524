import { Divider, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { FileInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { SvgField } from '../../components'
import { ActivateForm } from '../../components/ActivateForm'
import { xxllncColor } from '../../layout/themes'
import { AppCategories } from '../../types/ecoRequests'
import { toReadableString } from '../../utils/textFormatter'
import { colorValidation, required } from '../../utils/validation'

const ManageAppForm: FC = () => {
  const translate = useTranslate()
  const getChoicesArray = Object.values(AppCategories)
  const choices = getChoicesArray.map(choice => ({
    id: choice,
    name: toReadableString(choice || '-'),
  }))

  return (
    <StyledGrid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" component="div">
          {translate('data.information')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          helperText=""
          source="name"
          label="data.name"
          validate={required()}
          isRequired
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          choices={choices}
          helperText=""
          label="data.category"
          source="category"
          validate={required()}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          source="description"
          label="data.description"
          validate={required()}
          multiline
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.orderUrl"
          source="orderUrl"
          type="url"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.demoUrl"
          source="demoUrl"
          type="url"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.totalInstanceSoftLimit"
          source="totalInstanceSoftLimit"
          type="number"
          validate={required()}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.totalStorageSoftLimit"
          source="totalStorageSoftLimit"
          type="number"
          validate={required()}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          source="vendor"
          label="data.vendor"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.vendorUrl"
          source="vendorUrl"
          type="url"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.manifestUrl"
          source="manifestUrl"
          type="url"
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" component="div">
          {translate('data.style')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FileInput
          source="icon"
          label="data.icon"
          accept={{ 'image/*': ['.jpg', '.jpeg', '.png', '.svg'] }}
          placeholder={
            <Typography variant="subtitle2" color="secondary">
              {translate('ra.input.file.upload_single')}
            </Typography>
          }
          multiple={false}
          validate={required()}
          helperText=""
          isRequired
        >
          <SvgField
            source="icon"
            label="data.icon"
            emptyText="-"
          />
        </FileInput>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText="data.bannerImageHelper"
          label="data.imageUrl"
          source="imageUrl"
          type="url"
          validate={required()}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText="data.hexColorHelper"
          label="data.hexColor"
          source="hexColor"
          validate={colorValidation}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" component="div">
          {translate('data.documentation')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.eLearningUrl"
          source="eLearningUrl"
          type="url"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.userGuideUrl"
          source="userGuideUrl"
          type="url"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.administratorGuideUrl"
          source="administratorGuideUrl"
          type="url"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          helperText=""
          label="data.apiDocumentationUrl"
          source="apiDocumentationUrl"
          type="url"
        />
      </Grid>
      <ActivateForm description="app" />
    </StyledGrid>
  )
}

const PREFIX = 'xxllncManageAppForm'

const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.divider}`]: {
    backgroundColor: xxllncColor.opDark20,
    display: 'flex',
  },
}))

export default ManageAppForm
