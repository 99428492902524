import { FC } from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { ActionsToolbar, HeaderTitle, SimpleSaveToolbar } from '../../components'
import { AppReportOut } from '../../types/ecoRequests'

const AppReportEdit: FC = () => {
  const redirect = (basePath, _id, data) => {
    const appId = (data as AppReportOut)?.appId
    return appId ? `app/${appId}/show/report` : `${basePath || '/'}`
  }

  return (
    <Edit
      title={<HeaderTitle i18n="resources.app_report.edit" />}
      actions={<ActionsToolbar source="appName" i18n="resources.app_report.edit" />}
      redirect={redirect}
    >
      <SimpleForm
        toolbar={<SimpleSaveToolbar />}
        sanitizeEmptyValues
      >
        <TextInput
          source="name"
          label="data.name"
          helperText=""
          multiline
        />
        <TextInput
          source="url"
          label="data.url"
          helperText=""
          multiline
        />
      </SimpleForm>
    </Edit>
  )
}


export default AppReportEdit
