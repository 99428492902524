import { Grid } from '@mui/material'
import { FC } from 'react'
import { Show, SimpleShowLayout, Tab, TabbedShowLayout, TextField } from 'react-admin'
import { HeaderTitle, RestrictedTab } from '../../components'
import Member from './Member'
import { OrganizationAuthShowActions } from './OrganizationAuth.actions'
import OrganizationConnection from './OrganizationConnection'

const OrganizationAuthShow: FC = () => (
  <Show
    actions={<OrganizationAuthShowActions />}
    title={<HeaderTitle source="name" i18n="resources.organization_auth.title" />}
  >
    <TabbedShowLayout>
      <Tab label="show.details">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <SimpleShowLayout>
              <TextField source="provider" label="data.provider" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </Tab>
      <RestrictedTab
        access={['eco.admin.connection:read']}
        label="show.signOn"
        path="signOn"
      >
        <OrganizationConnection />
      </RestrictedTab>
      <RestrictedTab
        access={['eco.admin.member:read']}
        label="show.member"
        path="member"
      >
        <Member />
      </RestrictedTab>
    </TabbedShowLayout>
  </Show>
)

export default OrganizationAuthShow
