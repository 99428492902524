import { Grid } from '@mui/material'
import { FC } from 'react'
import { DateField, Show, Tab, TabbedShowLayout, TextField, Title } from 'react-admin'
import { ChipArrayField, NestedShowLayout, RestrictedTab } from '../../components'
import { getLocale } from '../../utils/getLocale'
import { AppInstanceShowActions } from './AppInstance.actions'
import AppUsage from './AppUsage'
import AuditLog from './AuditLog'
import HostAliases from './HostAliases'

const AppInstanceShow: FC = () => (
  <Show
    title={<Title title="resources.app_instance.title" />}
    actions={<AppInstanceShowActions />}
  >
    <TabbedShowLayout>
      <Tab label="show.details">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <NestedShowLayout>
              <TextField
                source="appName"
                label="data.appName"
              />
            </NestedShowLayout>
          </Grid>
          <Grid item xs={12}>
            <NestedShowLayout>
              <TextField
                source="description"
                label="data.description"
              />
            </NestedShowLayout>
          </Grid>
          <Grid item xs={12}>
            <NestedShowLayout>
              <DateField
                source="started"
                label="data.started"
                locales={getLocale()}
              />
            </NestedShowLayout>
          </Grid>
          <Grid item xs={12}>
            <NestedShowLayout>
              <DateField
                source="ended"
                label="data.ended"
                emptyText="-"
                locales={getLocale()}
              />
            </NestedShowLayout>
          </Grid>
          <Grid item xs={12}>
            <NestedShowLayout>
              <ChipArrayField
                label="data.appOrganizationSystemTags"
                source="appOrganizationSystemTags"
                emptyText="-"
              />
            </NestedShowLayout>
          </Grid>
          <Grid item xs={12}>
            <NestedShowLayout>
              <ChipArrayField
                label="data.systemTags"
                source="systemTags"
                emptyText="-"
              />
            </NestedShowLayout>
          </Grid>
          <AppUsage />
          <Grid item xs={12}>
            <NestedShowLayout>
              <HostAliases />
            </NestedShowLayout>
          </Grid>
        </Grid>
      </Tab>
      <RestrictedTab
        access={['eco.admin.auditlog:read', 'eco.admin.audit_log:read']}
        label="show.auditLog"
        path="auditlog"
      >
        <AuditLog />
      </RestrictedTab>
    </TabbedShowLayout>
  </Show>
)

export default AppInstanceShow
