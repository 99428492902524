import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import { ListItemIcon, ListItemText, MenuItem, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useTranslate } from 'react-admin'
import { getEnvVariable } from 'xxllnc-react-components'

const endUserUrl = (): string => getEnvVariable('REACT_APP_ENDUSER_URL', 'url_not_set')

export const EndUserButton = () => {
  const translate = useTranslate()
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  )
  const openUrl = () => {
    window.open(endUserUrl(), '_blank')
  }

  return (
    <MenuItem
      onClick={openUrl}
      component={isXSmall ? 'span' : 'li'}
    >
      <ListItemIcon>
        <AssignmentIndIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        {translate('data.endUserMenuItem')}
      </ListItemText>
    </MenuItem>
  )
}