import { FC } from 'react'
import { Show, SimpleShowLayout } from 'react-admin'
import ContractForm from './ContractForm'

const ContractsShow: FC = () => (
  <Show>
    <SimpleShowLayout>
      <ContractForm />
    </SimpleShowLayout>
  </Show>
)

export default ContractsShow
