import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Grid } from '@mui/material'
import { FC } from 'react'
import { Datagrid, DeleteButton, SimpleShowLayout, SortPayload, TextField, useGetList, useRecordContext, useTranslate } from 'react-admin'
import { Empty, LocationCreateButton, RestrictedButton } from '../../components'
import { AppOut, AppRoleOut } from '../../types/ecoRequests'

export const AppRoles: FC = () => {
  const translate = useTranslate()
  const appRecord = useRecordContext<AppOut>()
  const search = `?appId=${appRecord?.id}`
  const sort: SortPayload = { field: 'label', order: 'ASC' }

  const { data, total, isLoading } = useGetList<AppRoleOut>(
    'app_role',
    {
      pagination: { page: 1, perPage: 99 },
      sort,
      filter: { appId: appRecord?.id }
    }
  )

  return !isLoading ? (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <Datagrid
            sort={sort}
            data={data}
            selectedIds={[]}
            isLoading={isLoading as boolean}
            bulkActionButtons={false}
            total={total}
            empty={
              <Empty
                resource="app_role"
                createButton={
                  <LocationCreateButton
                    access={['eco.admin.app:write']}
                    align="center"
                    resource="app_role"
                    search={search}
                    label="actions.role"
                    disabled={!appRecord}
                  />
                }
              />
            }
          >
            <TextField source="label" label="data.name" sortable={false} />
            <TextField source="description" label="data.description" sortable={false} />
            <RestrictedButton
              access={['eco.admin.organization:write']}
              align="right"
            >
              {(): JSX.Element | null => {
                const record = useRecordContext<AppRoleOut>()

                if (!record) return null

                return (
                  <DeleteButton
                    redirect={`/app/${appRecord?.id}/show/role`}
                    mutationMode="pessimistic"
                    record={record}
                    resource="app_role"
                    confirmTitle={translate('message.deleteTitle', { name: record.label })}
                    label=""
                    icon={<DeleteOutlinedIcon />}
                  />
                )
              }}
            </RestrictedButton>
          </Datagrid>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  ) : <></>
}
