export enum TopDeskTags {
  'Mijn xxllnc',
  'Zaaksysteem.nl',
  'Koppel App',
  'RIS Matching',
  'Inforing',
  'Zaakgericht werken',
  'MintLab',
  'xxllnc Productiviteit',
  'Tercera',
  'Tercera GO!',
  'xxllnc Omgeving',
  'Regie Informatie Systemen',
  'Kafea',
}

export interface DynamicQuestion {
  label: string,
  source: string,
  type: string,
  options?: string[]
  isRequired: boolean
}

export interface TopDeskQuestionType {
  name: string,
  tags: TopDeskTags[],
  category: TopDeskIssueCategory,
  subCategory?: string,
  operatorGroup: TopDeskOperatorGroup,
  ticketType: TopDeskTicketType
  specifications: {
    description?: string,
    questions: DynamicQuestion[]
  }
}

export enum TopDeskTicketType {
  'Vraag',
  'Incident',
  'Dienstaanvraag',
}

export enum TopDeskIssueCategory {
	'xxllnc Zaaksysteem',
	'Tercera-GO! (ALG)',
	'Tercera-GO! (DSO)',
	'Tercera-GO! (WRO)',
	'LBA',
	'LLV',
	'Onderwijsloket',
	'PRO Prognose',
	'GBpro',
	'RMCnet',
	'4VAL Sales',
	'4VAL Software gerelateerd',
	'4VAL Taxatietechnisch',
	'Berkeley Publisher',
	'Berkeley Modeler',
	'Workspace',
	'RIS matching',
	'RIS VEOA',
	'SOLO',
	'RIS MKB',
	'RIS Toeslagenaffaire',
	'RIS Inburgering',
	'RIS Overige applicaties',
	'COMPET&T 1.0',
	'COMPET&T 2.0',
	'Ztestwouter'
}

export interface TopDeskEntryType {
  id: string,
  name: string
}

export interface TopDeskOperatorGroupProps {
  id: string,
  groupName: TopDeskOperatorGroup,
  branch: {
    id: string
    name: string
  }
}

export enum TopDeskOperatorGroup {
    '4Value Sales',
    '4WOZ support',
    'Amsterdam On/Off boarding',
    'Berkeley Support',
    'Berkeley Support DSO',
    'Eindhoven: On/Offboarding',
    'Koppel.app Developer',
    'Koppel.app Product Owner',
    'Koppel.app Support',
    'P&P',
    'Pronexus Administratief',
    'Pronexus LBA',
    'Pronexus LLV',
    'Pronexus Onderwijsloket',
    'Pronexus Prognose',
    'Pronexus RMCnet',
    'RIS Accountmanagement Inforing',
    'RIS Development Inforing',
    'RIS Servicedesk Inforing',
    'T&T Beheer',
    'T&T Helpdesk',
    'T&T Product Owner',
    'T&T Sales',
    'T&T Secretariaat',
    'Tercera 1st line Support',
    'Tercera 2nd line support',
    'Tercera 3rd line support',
    'Tercera Wensenlijst Gemeenten',
    'Tercera Wensenlijst Provincies',
    'Veenendaal: On/Offboarding',
    'Workspace',
    'Zaaksysteem DevOPS',
    'Zaaksysteem xxllnc Support',
    'Ztestgroep Wouter'
}