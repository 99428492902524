import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { FieldProps, useRecordContext } from 'react-admin'
import { border, xxllncColor } from '../../layout/themes'
import { InjectedFieldProps, PublicFieldProps, sanitizeFieldRestProps } from './types'

export const ChipArrayField = ({
  className,
  emptyText,
  source,
  ...props
}: ChipArrayFieldProps): JSX.Element | null => {
  const record = useRecordContext()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const value = source ? get(record, source) as string[] : undefined

  if (!record || value == null || value.length <= 0 || value === undefined) {
    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(props)}
      >
        {emptyText || '-'}
      </Typography>
    )
  }

  return (
    <Root className={classes.root}>
      {value && value.map((item: string): JSX.Element => (
        <div className={classes.item}>{item}</div>
      ))}
    </Root>
  )
}

ChipArrayField.propTypes = {
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
}

ChipArrayField.displayName = 'ChipArrayField'

interface ChipArrayFieldProps
    extends PublicFieldProps,
        Omit<InjectedFieldProps, 'record'>,
        FieldProps {
  className?: string;
  emptyText?: string;
  source: string;
  record?: any;
}


const PREFIX = 'xxllncChipArrayField'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.item}`]: {
    backgroundColor: xxllncColor.opDark10,
    border: border.thin + ' solid ' + xxllncColor.opDark10,
    borderRadius: border.radius.lg,
    fontSize: 14,
    marginRight: theme.spacing(1),
    padding: theme.spacing(.5, 1),
  },
}))
