/* eslint-disable */
import authToken from '../utils/authToken'

export const getScope = (): [] => {
  try {
    const token = authToken.getToken() || ''
    const parsed = JSON.parse(atob(token.split('.')[1]))
    return parsed.scope.split(' ')
  } catch (err) {
    return []
  }
}
