import AuthMemberIcon from '@mui/icons-material/People'
import AuthMemberCreate from './AuthMember.create'
import AuthMemberEdit from './AuthMember.edit'
import AuthMemberList from './AuthMember.list'

const resource = {
  icon: AuthMemberIcon,
  list: AuthMemberList,
  create: AuthMemberCreate,
  edit: AuthMemberEdit
}

export default resource
