import { AuthConnectionOut, Strategy } from '../../types/ecoRequests'

export const mockAuthConnection: AuthConnectionOut[] = [
  {
    id: 'CONNECTION-00001',
    organizationAuthId: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056',
    name: 'Signicat',
    strategy: Strategy.Oauth2,
    hostnames: [],
  },
  {
    id: 'CONNECTION-00002',
    organizationAuthId: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056',
    name: 'Auth0',
    strategy: Strategy.Auth0,
    hostnames: [],
  },
  {
    id: 'CONNECTION-00003',
    organizationAuthId: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056',
    name: 'Adfs',
    strategy: Strategy.Adfs,
    hostnames: [],
  },
  {
    id: 'CONNECTION-00004',
    organizationAuthId: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056',
    name: 'GoogleApps',
    strategy: Strategy.Googleapps,
    hostnames: [],
  },
  {
    id: 'CONNECTION-00005',
    organizationAuthId: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056',
    name: 'Waad',
    strategy: Strategy.Waad,
    hostnames: [],
  },
]
