const getInitials = (name: string | undefined | null): string => {
  let initials = ''
  if (!name) return initials

  let words = name.split(' ')
  if (words.length === 1) words = name.split('_')
  if (words.length > 0) {
    initials += words[0].charAt(0)
    if (words.length > 1) {
      initials += words[1].charAt(0)
    }
  }
  return initials.toUpperCase()
}

export default getInitials