import { Link, Typography } from '@mui/material'
import React, { FC } from 'react'
import { RaRecord, useGetList, useTranslate } from 'react-admin'
import { AppReportOut } from '../../types/ecoRequests'
import { StartIcon, XxllncAccordion, XxllncAccordionDetails, XxllncAccordionSummary } from './GenericOrganizationList'

interface ReportProps {
  record?: RaRecord
}

export const ReportList: FC<ReportProps> = ({ record }) => {
  const translate = useTranslate()
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const { data: reports } = useGetList<AppReportOut>(
    'app_report',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { appId: record?.id },
    },
  )

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return reports && reports.length > 0 ? (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
      <Typography variant="h6" style={{ marginBottom: '10px' }}>
        {translate('show.report')}
      </Typography>
      { reports?.map((report) => (
        <XxllncAccordion
          key={report.name}
          expanded={expanded === report.name}
          onChange={handleChange(report.name)}
        >
          <XxllncAccordionSummary aria-controls={`${report.name}-content`} id={`${report.name}-header`}>
            <Typography sx={{ marginLeft: '20px', lineHeight: '27px' }}>
              <StartIcon />
              { report.name }
            </Typography>
          </XxllncAccordionSummary>
          <XxllncAccordionDetails>
            <Link
              href={report.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                'textDecoration': 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Typography sx={{ whiteSpace: 'pre-line', cursor: 'pointer' }}>{ report.url }</Typography>
            </Link>
          </XxllncAccordionDetails>
        </XxllncAccordion>
      )) }
    </div>) : null
}