import { ResponsiveLine } from '@nivo/line'
import { FC, useMemo } from 'react'
import { AppInstanceOut } from '../../types/ecoRequests'

interface Props {
  appInstances: AppInstanceOut[] | undefined
}

export const NewInstancesChart: FC<Props> = ({ appInstances }) => {
  const getLast12Months = (): string[] => {
    const months: string[] = []
    const date = new Date()
    date.setMonth(date.getMonth() - 11)

    for (let i = 0; i < 12; i++) {
      months.push(date.toLocaleString('default', { month: 'long' }).toLowerCase())
      date.setMonth(date.getMonth() + 1)
    }

    return months
  }

  const processData = (data: AppInstanceOut[] | undefined) => {
    if (!data) return []

    const last12Months = getLast12Months()
    const monthCounts: Record<string, number> = last12Months.reduce((acc, month: string) => {
      acc[month.toLowerCase()] = 0
      return acc
    }, {})

    data.forEach(instance => {
      const monthName = new Date(instance.created).toLocaleString('default', { month: 'long' }).toLowerCase()
      if (monthCounts.hasOwnProperty(monthName)) {
        // eslint-disable-next-line security/detect-object-injection
        monthCounts[monthName]++
      }
    })

    return last12Months.map((month: string) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      x: month.toLowerCase(),
      y: monthCounts[month.toLowerCase()],
    }))
  }

  const processedData = useMemo(() => {
    const array = processData(appInstances)
    return [
      {
        id: 'Nieuwe omgevingen',
        color: 'hsl(342, 70%, 50%)',
        data: array,
      },
    ]
  }, [appInstances])

  return (
    <ResponsiveLine
      data={processedData}
      margin={{ top: 30, right: 50, bottom: 50, left: 80 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 36,
        legendPosition: 'middle'
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      colors={['#31679B']}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      enableArea={true}
      areaBaselineValue={100000}
      useMesh={true}
      legends={[
        {
          anchor: 'bottom',
          direction: 'column',
          justify: false,
          translateX: 0,
          translateY: 50,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  )
}

export default NewInstancesChart