import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { styled } from '@mui/material/styles'
import { FC, useState } from 'react'
import { FilterListItem, RaRecord, useTranslate } from 'react-admin'
import { border, xxllncColor } from '../../layout/themes'

const SHOW_BY_DEFAULT = 4

export const FilterMore: FC<FilterMoreProps> = ({ data }) => {
  const translate = useTranslate()
  const [showAll, setShowAll] = useState(false)
  const count = data ? data.length : 0

  if (!data || count <= 0)
    return null

  const visibleOptions = showAll ? count : SHOW_BY_DEFAULT
  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  const ShowMore = () => {
    if (!showAll) {
      return (
        <>
          <ExpandMoreOutlinedIcon />
          {translate('actions.showAllApps', { count: count - SHOW_BY_DEFAULT })}
        </>
      )
    } else {
      return (
        <>
          <ExpandLessOutlinedIcon />
          {translate('actions.showLess')}
        </>
      )
    }
  }

  return data && (
    <Root className={classes.root}>
      {data?.slice(0, visibleOptions).map((record): JSX.Element => (
        <FilterListItem
          className={classes.item}
          label={record.name || '-'}
          key={record.id}
          value={{ appId: record.id }}
        />
      ))}
      {data.length > SHOW_BY_DEFAULT &&
        <div className={classes.showMore} onClick={toggleShowAll}>
          <ShowMore />
        </div>
      }
    </Root>
  )
}

interface RecordProps extends RaRecord {
  name?: string | null;
  id: string;
}

interface FilterMoreProps {
  data?: RecordProps[];
}

const PREFIX = 'xxllncFilterMore'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  showMore: `${PREFIX}-showMore`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.item}`]: {
    borderRadius: border.button.radius,
  },
  [`& .${classes.showMore}`]: {
    alignItems: 'center',
    color: xxllncColor.dark60,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 14,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(.5),
    ['& > svg']: {
      marginRight: theme.spacing(.5),
      width: 20,
    },
  },
}))
