import DownloadIcon from '@mui/icons-material/GetApp'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import {
  Button as RaButton,
  ButtonProps,
  downloadCSV,
  useListContext,
  useNotify,
  useResourceContext
} from 'react-admin'
import { getApiUrl } from '../../providers/data'
import authToken from '../../utils/authToken'

export const ExportButton = (props: ExportButtonProps): JSX.Element => {
  const {
    endpoint,
    onClick,
    label = 'ra.action.export',
    fileName = 'export',
    icon = defaultIcon,
    ...rest
  } = props
  const { total } = useListContext()
  const resource = useResourceContext(props)
  const notify = useNotify()
  const token = authToken.getToken() || ''
  const resourceEndpoint = endpoint ? resource + '/' + endpoint : resource
  const url = `${getApiUrl(resource || '')}/${resourceEndpoint}`

  const handleClick = useCallback(
    event => {
      fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: token,
          ContentType: 'text/csv'
        },
      }).then((response) => response.text())
        .then((data) => downloadCSV(data, fileName))
        .catch(error => {
          console.error(error)
          notify('ra.notification.http_error', { type: 'warning' })
        })
      if (typeof onClick === 'function') {
        onClick(event)
      }
    },
    [
      notify,
      onClick,
      resource
    ]
  )

  return (
    <RaButton
      onClick={handleClick}
      label={label}
      disabled={total === 0}
      {...sanitizeRestProps(rest)}
    >
      {icon}
    </RaButton>
  )
}

const defaultIcon = <DownloadIcon />

const sanitizeRestProps = ({
  resource,
  ...rest
}: Omit<ExportButtonProps, 'label'>) => rest

interface Props {
    label?: string;
    fileName?: string;
    resource?: string;
    endpoint?: string;
    icon?: JSX.Element;
    onClick?: (e: Event) => void;
}

export type ExportButtonProps = Props & ButtonProps;

ExportButton.propTypes = {
  label: PropTypes.string,
  fileName: PropTypes.string,
  resource: PropTypes.string,
  endpoint: PropTypes.string,
  icon: PropTypes.element,
}
