import { FC } from 'react'
import { Create, SimpleForm } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import ManageAppForm from './ManageAppForm'

const ManageAppCreate: FC = () => (
  <Create
    title={<HeaderTitle i18n="resources.app.create" />}
    actions={<ActionsToolbar i18n="resources.app.create" />}
    redirect="show"
  >
    <SimpleForm
      sanitizeEmptyValues
    >
      <ManageAppForm />
    </SimpleForm>
  </Create>
)

export default ManageAppCreate
