import { FC } from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { ActionsToolbar, ConfirmDeleteToolbar, HeaderTitle } from '../../components'
import { activateTransform } from '../../utils/activateTransform'
import ManageAppForm from './ManageAppForm'

const ManageAppEdit: FC = () => (
  <Edit
    actions={<ActionsToolbar source="name" i18n="resources.app.edit" />}
    title={<HeaderTitle source="name" i18n="resources.app.edit" />}
    transform={activateTransform}
    redirect="show"
  >
    <SimpleForm
      toolbar={<ConfirmDeleteToolbar access={['eco.admin.app:delete']} />}
      sanitizeEmptyValues
    >
      <ManageAppForm />
    </SimpleForm>
  </Edit>
)

export default ManageAppEdit
