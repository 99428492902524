import { Grid } from '@mui/material'
import { FC } from 'react'
import { Show, SimpleShowLayout, Tab, TabbedShowLayout, TextField } from 'react-admin'
import { DataField, HeaderTitle, RestrictedTab } from '../../components'
import AppInstances from './AppInstance'
import { AppOrganizationShowActions } from './AppOrganization.actions'
import IntegrationInstances from './IntegrationInstance'
import LicenceInstances from './LicenceInstance'
import ModuleInstances from './ModuleInstance'

const AppOrganizationShow: FC = () => (
  <Show
    title={<HeaderTitle source="appName" i18n="resources.app_organization.title" />}
    actions={<AppOrganizationShowActions />}
  >
    <TabbedShowLayout>
      <Tab label="show.details">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <SimpleShowLayout>
              <TextField
                source="totalInstanceSoftLimit"
                label="data.totalInstanceSoftLimit"
              />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SimpleShowLayout>
              <DataField
                source="totalStorageSoftLimit"
                label="data.totalStorageSoftLimit"
              />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </Tab>
      <RestrictedTab
        access={['eco.admin.appinstance:read']}
        label="show.environments"
        path="environments"
      >
        <AppInstances />
      </RestrictedTab>
      <RestrictedTab
        access={['eco.admin.appinstance:read']}
        label="show.module"
        path="modules"
      >
        <ModuleInstances />
      </RestrictedTab>
      <RestrictedTab
        access={['eco.admin.appinstance:read']}
        label="show.integration"
        path="integrations"
      >
        <IntegrationInstances />
      </RestrictedTab>
      <RestrictedTab
        access={['eco.admin.appinstance:read']}
        label="show.licence"
        path="licences"
      >
        <LicenceInstances />
      </RestrictedTab>
    </TabbedShowLayout>
  </Show>
)

export default AppOrganizationShow
