import { Typography } from '@mui/material'
import { FC } from 'react'
import {
  Create,
  FormDataConsumer,
  NumberInput,
  RaRecord,
  SelectInput,
  SimpleForm,
  useGetList,
  useTranslate
} from 'react-admin'
import { HeaderTitle } from '../../components'
import { AppLicenceOut } from '../../types/ecoRequests'

interface Choices extends RaRecord {
  name: string
}

const AppOrganizationLicenceCreate: FC = () => {
  const translate = useTranslate()
  const search = new URLSearchParams(location?.search)
  const appOrganizationId = search.get('appOrganizationId')
  const redirect = appOrganizationId ? `/app_organization/${appOrganizationId}/show/licences` : undefined
  const appId = search.get('appId')

  const { data } = useGetList<AppLicenceOut>(
    'app_licence',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: { appId }
    }
  )

  const choices = data?.map(({ id, name }: Choices) => ({ id, name }))

  const getTypeForLicence = (licenceId: string): string => {
    const licence = data?.find((record: Choices) => record.id === licenceId)
    return licence?.type || ''
  }

  return (
    <Create
      title={<HeaderTitle i18n="resources.app_organization_licence.title" />}
      redirect={redirect}
    >
      <SimpleForm defaultValues={{ appOrganizationId }}>
        { !choices ? (
          <Typography>
            { translate('resources.app_licence.notAvailable') }
          </Typography>) :
          (<>
            <SelectInput
              choices={choices.sort()}
              helperText=""
              label="data.name"
              source="appLicenceId"
              isRequired
            />
            <FormDataConsumer>{({ formData }) => {
              const type = getTypeForLicence(formData?.appLicenceId)
              return type === 'quantityScales' || type === 'users' ?
                <NumberInput
                  label="data.amount"
                  source="amount"
                /> : null
            }}
            </FormDataConsumer>

          </>)
        }

      </SimpleForm>
    </Create>
  )
}

export default AppOrganizationLicenceCreate
