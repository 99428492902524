import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  Datagrid,
  DateField,
  Empty, ReferenceManyField, SortPayload, TextField
} from 'react-admin'
import { StatusChipField } from '../../components'
import { getLocale } from '../../utils/getLocale'
import AuditLogsExpand from '../AuditLogs/AuditLogs.expand'

const AuditLog: FC = () => {
  const sort: SortPayload = { field: 'timestamp', order: 'ASC' }

  return (
    <Root className={classes.root}>
      <Grid container spacing={1} className={classes.table}>
        <Grid item xs={12}>
          <ReferenceManyField
            reference="auditlog"
            target="appInstanceId"
            sort={sort}
          >
            <Datagrid
              sort={sort}
              rowClick="expand"
              expand={<AuditLogsExpand />}
              empty={<Empty resource="auditlog" />}
            >
              <StatusChipField />
              <TextField source="summary" label="data.description" />
              <TextField source="displayName" label="data.displayName" emptyText="-" />
              <DateField
                source="timestamp"
                label="data.timestamp"
                locales={getLocale()}
              />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
      </Grid>
    </Root>
  )
}

const PREFIX = 'xxllncAuditLog'

const classes = {
  root: `${PREFIX}-root`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  table: `${PREFIX}-table`,
  item: `${PREFIX}-item`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  [`& .${classes.buttonContainer}`]: {
    minHeight: '30px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  [`& .${classes.table}`]: {
    marginBottom: theme.spacing(2)
  },
  [`& .${classes.table}`]: {
    display: 'flex',
    maxWidth: '100%',
    marginBottom: '0.5rem'
  },
}))

export default AuditLog
