import { ResponsiveBar } from '@nivo/bar'
import { FC } from 'react'
import { AppInstanceOut, AppOut } from '../../types/ecoRequests'

interface Props {
  appInstances: AppInstanceOut[] | undefined
  apps: AppOut[] | undefined
}

const ActiveInstancesBar: FC<Props> = ({ appInstances, apps }) => {
  const processData = () => {
    if (!apps || !appInstances) return []

    const appNameById = apps.reduce((acc, app) => {
      acc[app.id] = app.name
      return acc
    }, {} as Record<string, string>)

    const instancesCount = appInstances.reduce((acc, instance) => {
      const appName = appNameById[instance.appId]
      if (appName) {
        // eslint-disable-next-line security/detect-object-injection
        acc[appName] = (acc[appName] || 0) + 1
      }
      return acc
    }, {} as Record<string, number>)

    return Object.keys(instancesCount).map(appName => ({
      app: appName,
      // eslint-disable-next-line security/detect-object-injection
      appInstances: instancesCount[appName],
    }))
  }

  const data = processData()

  return (
    <ResponsiveBar
      data={data}
      keys={['appInstances']}
      indexBy="app"
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#31679B']}
      defs={[
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      fill={[]}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 30,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 40,
        truncateTickAt: 0
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Actieve omgevingen',
        legendPosition: 'middle',
        legendOffset: -40,
        truncateTickAt: 0
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      legends={[]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={e=>e.id+': '+e.formattedValue+' in country: '+e.indexValue}
    />
  )
}

export default ActiveInstancesBar