import { Chip, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { AutocompleteArrayInput, AutocompleteArrayInputProps, FieldTitle, useInput } from 'react-admin'
import { xxllncColor } from '../../layout/themes'

export const ChipInput: FC<ChipInputProps> = (props) => {
  const {
    className,
    disabled,
    helperText,
    label,
    margin = 'dense',
    source,
    variant = 'filled',
    isRequired: isRequiredOverride,
  } = props

  const {
    field,
    isRequired,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted }
  } = useInput(props)

  const chips = field.value as string[] || undefined

  const [choices, setChoices] = useState(() =>
    chips ? chips.map(choice => ({
      id: choice,
      name: choice,
    })) : []
  )

  const handleCreate = filter => {
    const newChoice = {
      id: filter as string,
      name: filter as string,
    }
    setChoices(currentChoices => [...currentChoices, newChoice])
    return newChoice
  }

  if (disabled) {
    return (
      <Root>
        <TextField
          InputProps={{
            classes: {
              root: clsx(classes.inputRoot, {
                [classes.inputRootFilled]:
                variant === 'filled',
              }),
              input: classes.inputInput,
            },
            startAdornment: (
              <div
                className={clsx({
                  [classes.chipContainerFilled]:
                  variant === 'filled',
                  [classes.chipContainerOutlined]:
                  variant === 'outlined',
                })}
              >
                {chips && chips.length !== 0 ? chips.filter(item => !!item)
                  .map((item, index) => (
                    <Chip
                      key={index}
                      tabIndex={-1}
                      label={item}
                      className={classes.chip}
                    />
                  )) : '-'}
              </div>
            )
          }}
          error={(isTouched || isSubmitted) && invalid}
          label={
            <FieldTitle
              label={label}
              source={source}
              isRequired={
                typeof isRequiredOverride !==
                'undefined'
                  ? isRequiredOverride
                  : isRequired
              }
            />
          }
          helperText={(isTouched || isSubmitted) && invalid ? error?.message : helperText}
          disabled={disabled}
          margin={margin}
          variant={variant}
          fullWidth
        />
      </Root>
    )
  }

  return (
    <AutocompleteArrayInput
      {...props}
      choices={choices}
      className={className}
      label={label}
      onCreate={handleCreate}
      shouldRenderSuggestions={(value: string) => value.trim().length > 2}
      source={source}
      isRequired={
        typeof isRequiredOverride !==
          'undefined'
          ? isRequiredOverride
          : isRequired
      }
    />
  )
}

export interface ChipInputProps extends AutocompleteArrayInputProps {
  className?: string;
  disabled?: boolean;
  helperText?: string;
  label?: string;
  margin?: 'none' | 'normal' | 'dense';
  source: string;
  variant?: 'standard' | 'outlined' | 'filled';
  isRequired?: boolean;
}

const PREFIX = 'xxllncChipInput'

const classes = {
  inputRoot: `${PREFIX}-inputRoot`,
  inputRootFilled: `${PREFIX}-inputRootFilled`,
  inputInput: `${PREFIX}-inputInput`,
  chipContainerFilled: `${PREFIX}-chipContainerFilled`,
  chipContainerOutlined: `${PREFIX}-chipContainerOutlined`,
  chip: `${PREFIX}-chip`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.inputRoot}`]: {
    flexWrap: 'wrap',
  },
  [`& .${classes.inputRootFilled}`]: {
    flexWrap: 'wrap',
    ['& $chip']: {
      backgroundColor:
        theme.palette.mode === 'light'
          ? xxllncColor.opDark10
          : xxllncColor.opLight10,
    },
  },
  [`& .${classes.inputInput}`]: {
    width: 'auto',
    flexGrow: 1,
  },
  [`& .${classes.chipContainerFilled}`]: {
    margin: theme.spacing(3.5, 1.5, 1.25, 0)
  },
  [`& .${classes.chipContainerOutlined}`]: {
    margin: theme.spacing(1.5, 1.5, 1.25, 0)
  },
  [`& .${classes.chip}`]: {
    color: xxllncColor.dark60,
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}))
