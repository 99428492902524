import { Show, ShowProps, Tab, TabbedShowLayout } from 'react-admin'
import { styled } from '@mui/material'
import { HeaderTitle, RestrictedTab } from '../../components'
import { AppIntegrations } from './AppIntegrations'
import { AppModules } from './AppModules'
import Details from './Details'
import { ManageAppShowActions } from './ManageApp.actions'
import { AppRoles } from './AppRoles'
import { AppReport } from './AppReport'
import { AppLicences } from './AppLicences'

const ManageAppShow = (props: ShowProps): JSX.Element => (
  <StyledShow
    className={classes.root}
    actions={<ManageAppShowActions />}
    title={<HeaderTitle i18n="resources.app.title" />}
  >
    <TabbedShowLayout>
      <Tab label="show.details">
        <Details {...props} />
      </Tab>
      <RestrictedTab
        access={['eco.admin.app:read']}
        label="show.integration"
        path="integrations"
      >
        <AppIntegrations />
      </RestrictedTab>
      <RestrictedTab
        access={['eco.admin.app:read']}
        label="show.module"
        path="modules"
      >
        <AppModules />
      </RestrictedTab>
      <RestrictedTab
        access={['eco.admin.app:read']}
        label="show.licence"
        path="licences"
      >
        <AppLicences />
      </RestrictedTab>
      <RestrictedTab
        access={['eco.admin.app:read']}
        label="show.roles"
        path="role"
      >
        <AppRoles />
      </RestrictedTab>
      <RestrictedTab
        access={['eco.admin.app:read']}
        label="show.report"
        path="report"
      >
        <AppReport />
      </RestrictedTab>
    </TabbedShowLayout>
  </StyledShow>
)

const PREFIX = 'xxllncManageAppShow'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledShow = styled(Show)(({ theme }) => ({
  [`& .${classes.root}`]: { },
}))

export default ManageAppShow
