/* eslint-disable max-len */
import { AppCategories, AppOut } from '../../types/ecoRequests'
import { app } from '../mockInit'

export const mockApp: AppOut[] = [
  {
    hexColor: '#d5e9d4',
    demoUrl: undefined,
    imageUrl: 'https://www.ortecfinance.com/-/media/project/ortec/shared/images/content/vrisa.jpg',
    category: AppCategories.Belastingen,
    orderUrl: 'https://zaken.exxellence.nl/aanvragen/199/onbekend/aanvragen-app',
    name: 'Belasting',
    description: 'Veelzijdig Revolutionair innovatief en Saas, ook wel VRiS hebben we samen met gemeenten ontwikkeld en dit doen we nog. In dit flexibele en unieke pakket komt meer dan 40 jaar aan praktijkervaring op het gebied van belastingen, vastgoed basis registraties en taxaties samen.',
    icon: app,
    vendor: 'xxllnc',
    vendorUrl: 'https://www.tognederland.nl/software',
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 100,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-12-08T08:56:18.152484+00:00',
    ended: undefined,
    created: '2021-12-08T08:56:18.152484+00:00',
    lastModified: '2021-12-08T08:56:18.152484+00:00',
    id: '19dd121b-3728-4b33-b861-391b68edda1b'
  },
  {
    hexColor: '#b3d6e3',
    demoUrl: undefined,
    imageUrl: 'https://www.berkeleybridge.nl/wp-content/uploads/PublisherZoom3.png',
    category: AppCategories.ZaakgerichtWerken,
    orderUrl: 'https://zaken.exxellence.nl/aanvragen/199/onbekend/aanvragen-app',
    name: 'Beslisbomen',
    description: 'xxllnc biedt een platform voor het automatiseren van kennis in de vorm van webapplicaties of webservices op basis van beslisbomen. De visie die daarbij leidend is houdt in dat de inhoudsdeskundigen zonder technische kennis zelfstandig kennisintensieve webapplicaties kunnen ontwikkelen. Dit verhoogt de snelheid waarmee dergelijke applicaties online in productie kunnen worden genomen en leidt tot betere kwaliteit',
    icon: app,
    vendor: 'xxllnc',
    vendorUrl: 'https://www.berkeleybridge.nl/',
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 100,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-12-08T08:48:25.646342+00:00',
    ended: undefined,
    created: '2021-12-08T08:48:25.646342+00:00',
    lastModified: '2021-12-08T09:17:57.665488+00:00',
    id: '0dbf2dbe-5b74-4bbd-ab52-c96994576344'
  },
  {
    hexColor: '#fff2cc',
    demoUrl: undefined,
    imageUrl: 'https://exxellence.nl/wp-content/uploads/2018/10/headers-website-qhd-7-1080x400.jpg',
    category: AppCategories.ZaakgerichtWerken,
    orderUrl: 'https://contactmomenten.exxellence.nl',
    name: 'Contactmomenten',
    description: 'xxllnc Contactmomenten',
    icon: app,
    vendor: undefined,
    vendorUrl: undefined,
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 2,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-10-20T13:31:04.930320+00:00',
    ended: undefined,
    created: '2021-10-20T13:31:04.930320+00:00',
    lastModified: '2021-12-07T10:56:51.907615+00:00',
    id: '78a54df0-f1f2-403f-89c6-1fcc2f5ecc1a'
  },
  {
    hexColor: '#dae8fc',
    demoUrl: 'https://formulierenbeheer.exxellence.nl',
    imageUrl: 'https://i.stack.imgur.com/ZNwVt.png',
    category: AppCategories.ZaakgerichtWerken,
    orderUrl: 'https://formulierenbeheer.exxellence.nl',
    name: 'Formulierenbeheer',
    description: 'xxllnc Formulierenbeheer',
    icon: app,
    vendor: undefined,
    vendorUrl: undefined,
    totalInstanceSoftLimit: 10,
    totalStorageSoftLimit: 10,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-11-22T08:16:42.603422+00:00',
    ended: undefined,
    created: '2021-11-22T08:16:42.603422+00:00',
    lastModified: '2021-12-07T10:57:26.769120+00:00',
    id: '3bb303e8-10ec-4749-97e6-560586d5955d'
  },
  {
    hexColor: '#dae8fc',
    demoUrl: 'https://formuliereninwoner.exxellence.nl',
    imageUrl: 'https://i.stack.imgur.com/ZNwVt.png',
    category: AppCategories.ZaakgerichtWerken,
    orderUrl: 'https://formuliereninwoner.exxellence.nl',
    name: 'Formuliereninwoner',
    description: 'xxllnc Formuliereninwoner',
    icon: app,
    vendor: undefined,
    vendorUrl: undefined,
    totalInstanceSoftLimit: 10,
    totalStorageSoftLimit: 10,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-11-22T08:15:29.863848+00:00',
    ended: undefined,
    created: '2021-11-22T08:15:29.863848+00:00',
    lastModified: '2021-12-07T10:57:42.294175+00:00',
    id: '18143184-9909-41a1-acb1-b422412d6486'
  },
  {
    hexColor: '#f6e0b6',
    demoUrl: undefined,
    imageUrl: 'https://xxllnc.nl/wp-content/uploads/2021/05/xxllnccloud-blog-10.svg',
    category: AppCategories.ZaakgerichtWerken,
    orderUrl: 'https://zaken.exxellence.nl/aanvragen/199/onbekend/aanvragen-app',
    name: 'Gegevens',
    description: 'Met xxllnc Gegevens krijg je snel en gemakkelijk toegang tot gegevensbronnen om veilig digitale (privacygevoelige) gegevens uit te wisselen. Denk hierbij aan de Basisregistratie Personen van de Nederlandse Overheid of het Handelsregister van de Kamer van Koophandel. Werken met de bronnen kan vanuit de SaaS-applicatie die functionaliteiten biedt zoals logging, routering, distributie of autorisatie. Of we integreren de bronnen naadloos met je centrale softwarepakket. xxllnc Gegevens werkt voor organisaties van allerlei soorten en maten. Begin snel en gemakkelijk door de bronnen via het portaal te bedienen. Grootverbruiker? Bouw aan een volledige integratie met jouw softwarepakket met bijvoorbeeld (automatische, periodieke) batches.',
    icon: app,
    vendor: 'xxllnc',
    vendorUrl: 'https://xxllnc.nl/apps#gegevens',
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 100,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-12-06T14:11:59.348345+00:00',
    ended: undefined,
    created: '2021-12-06T14:11:59.348345+00:00',
    lastModified: '2021-12-06T14:11:59.348345+00:00',
    id: '47acf3bb-5321-4a86-aa75-d49c6301f92d'
  },
  {
    hexColor: '#ffded5',
    demoUrl: undefined,
    imageUrl: 'https://xxllnc.nl/wp-content/uploads/2021/09/nieuwe-xxllnc3.png',
    category: AppCategories.ZaakgerichtWerken,
    orderUrl: 'https://zaken.exxellence.nl/aanvragen/199/onbekend/aanvragen-app',
    name: 'Koppelen',
    description: 'xxllnc Koppelen is een SaaS-oplossing die werkt op basis van API’s. Met xxllnc Koppelen realiseer en beheer je gemakkelijk en snel koppelingen. En je houdt het overzicht van alle koppelingen. Samen met onze community ontwikkelen we Open Connectors, wat ervoor zorgt dat de keuze uit integraties steeds groter wordt. Want eenmaal gelegd, kunnen de ingerichte koppelingen worden hergebruikt. Met deze standaard koppelprofielen knoop je applicatie A gemakkelijk aan applicatie B. Toch een specifiekere koppeling? Dan pas je de mapping zelf aan. Wens je een andere koppeling dan beschikbaar? We bouwen deze graag.',
    icon: app,
    vendor: 'xxllnc',
    vendorUrl: 'https://xxllnc.nl/apps#koppelen',
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 10,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-12-06T14:07:05.353191+00:00',
    ended: undefined,
    created: '2021-12-06T14:07:05.353191+00:00',
    lastModified: '2021-12-06T14:07:05.353191+00:00',
    id: '828a89de-3cd6-41cc-ba38-b4716798cda9'
  },
  {
    hexColor: '#f6dfb9',
    demoUrl: undefined,
    imageUrl: 'https://llv.pronexus.nl/img/screens/indienen.png',
    category: AppCategories.Sociaal,
    orderUrl: 'https://zaken.exxellence.nl/aanvragen/199/onbekend/aanvragen-app',
    name: 'Leerlingenvervoer',
    description: 'In xxllnc Leerlingenvervoer zijn alle criteria opgenomen uit de modelverordening leerlingenvervoer. Ook de nieuwe verordening met passend onderwijs is in de software verwerkt. xxllnc Leerlingenvervoer gebruikt alle beschikbare basisinformatie van leerlingen met in- en uitschrijvingen en scholen met alle locaties in Nederland.',
    icon: app,
    vendor: 'xxllnc',
    vendorUrl: 'https://llv.pronexus.nl/',
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 100,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-12-08T08:51:35.714724+00:00',
    ended: undefined,
    created: '2021-12-08T08:51:35.714724+00:00',
    lastModified: '2021-12-08T08:51:35.714724+00:00',
    id: '90db87f9-2085-4358-ac57-88968e450e3f'
  },
  {
    hexColor: '#b3d6e3',
    demoUrl: undefined,
    imageUrl: 'https://lba.pronexus.nl/img/screens/dashboard.png',
    category: AppCategories.Sociaal,
    orderUrl: 'https://zaken.exxellence.nl/aanvragen/199/onbekend/aanvragen-app',
    name: 'Leerplicht',
    description: 'Met xxllnc Leerplicht kunnen gemeenten, regionale leerplichtbureau\'s en RMC regio\'s jongeren registreren, volgen en begeleiden. Het sterke punt van xxllnc Leerplicht is de integratie van eigen werkprocessen. Jongeren worden hiermee automatisch op de voet gevolgd en kunnen nooit uit beeld raken.',
    icon: app,
    vendor: 'xxllnc',
    vendorUrl: 'https://lba.pronexus.nl/',
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 100,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-12-08T08:50:00.075464+00:00',
    ended: undefined,
    created: '2021-12-08T08:50:00.075464+00:00',
    lastModified: '2021-12-08T08:50:00.075464+00:00',
    id: '537887e2-ce7e-4414-a9f3-0b4b248da4b0'
  },
  {
    hexColor: '#ffdbf1',
    demoUrl: undefined,
    imageUrl: 'https://www.tercera.nl/wp-content/uploads/2019/02/1.png',
    category: AppCategories.Omgeving,
    orderUrl: 'https://zaken.exxellence.nl/aanvragen/199/onbekend/aanvragen-app',
    name: 'Omgevingsplannen',
    description: 'Met xxllnc Omgevingsplannen kunt u o.a. uw Omgevingsplan en Omgevingsvisie maken, wijzigen, valideren en publiceren. Met de ingebouwde digikoppeling-adapter kunt u besluiten bekendmaken en beschikbaar stellen via het LVBB. xxllnc Omgevingsplannen werkt conform de meest recente versie van de STOP/TPOD-standaarden.',
    icon: app,
    vendor: 'xxllnc',
    vendorUrl: 'https://www.tercera.nl/',
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 100,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-12-08T08:46:47.504737+00:00',
    ended: undefined,
    created: '2021-12-08T08:46:47.504737+00:00',
    lastModified: '2021-12-08T09:00:07.102382+00:00',
    id: 'bf2aff28-d2d0-4e28-ac0a-df47db3be0f5'
  },
  {
    hexColor: '#f8cecc',
    demoUrl: undefined,
    imageUrl: 'https://exxellence.nl/wp-content/uploads/2018/10/headers-website-qhd-7-1080x400.jpg',
    category: AppCategories.ZaakgerichtWerken,
    orderUrl: 'https://terugbelverzoeken.exxellence.nl',
    name: 'Terugbelverzoeken',
    description: 'xxllnc Terugbelverzoeken',
    icon: app,
    vendor: undefined,
    vendorUrl: undefined,
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 2,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-10-20T11:32:23.244815+00:00',
    ended: undefined,
    created: '2021-10-20T11:32:23.244815+00:00',
    lastModified: '2021-12-07T10:58:08.640777+00:00',
    id: '39966dd7-ec80-4aa5-a6bc-5e2ce7479124'
  },
  {
    hexColor: '#f5d1c4',
    demoUrl: undefined,
    imageUrl: 'https://vroegsignalering.nl/images/blokken_bg_afbeelding.png',
    category: AppCategories.Sociaal,
    orderUrl: 'https://zaken.exxellence.nl/aanvragen/199/onbekend/aanvragen-app',
    name: 'Vroegsignaleren',
    description: 'Met de webapplicaties xxllnc Vroeg Eropaf van Inforing is het mogelijk om problematische financiële signalen binnen huishoudens op te vangen, te matchen en op te volgen. Een gemeente kan, naar eigen inzicht, met deze actuele informatie de manier van aanpak in kaart brengen en vroegtijdig actie ondernemen om verdere problematiek voor de schuldenaar te voorkomen en de maatschappelijke schade te beperken.',
    icon: app,
    vendor: 'xxllnc',
    vendorUrl: 'https://vroegsignalering.nl/nl/home',
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 100,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-12-08T08:44:45.067582+00:00',
    ended: undefined,
    created: '2021-12-08T08:44:45.067582+00:00',
    lastModified: '2021-12-08T09:16:15.968371+00:00',
    id: 'cedfcfae-b8ea-4bcd-b68d-52c5538825bb'
  },
  {
    hexColor: '#f6dfb9',
    demoUrl: undefined,
    imageUrl: 'http://4value.nl/wp-content/uploads/2015/10/4woz2.jpg',
    category: AppCategories.Belastingen,
    orderUrl: 'https://zaken.exxellence.nl/aanvragen/199/onbekend/aanvragen-app',
    name: 'Waarderen',
    description: 'Aan 4Value kunt u zorgeloos uw WOZ taxaties uitbesteden. De modelmatige waardebepaling van woningen en niet-woningen voeren wij uit met onze waarderingsapplicatie 4Woz 2.0. Modelmatige waardebepaling zoals het hoort! Geen gebruik van Excel en geen indexatie.',
    icon: app,
    vendor: 'xxllnc',
    vendorUrl: 'https://www.4value.nl/4value/',
    totalInstanceSoftLimit: 1,
    totalStorageSoftLimit: 100,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-12-08T08:42:00.017350+00:00',
    ended: undefined,
    created: '2021-12-08T08:42:00.017350+00:00',
    lastModified: '2021-12-08T08:42:00.017350+00:00',
    id: '08214ffa-c8b9-4bd8-8d80-38a8ca71abfa'
  },
  {
    hexColor: '#cfe7dc',
    demoUrl: undefined,
    imageUrl: 'https://zaaksysteem.nl/wp-content/uploads/2020/04/timeline-zaaksysteem-nl.svg',
    category: AppCategories.ZaakgerichtWerken,
    orderUrl: 'https://zaken.exxellence.nl/aanvragen/199/onbekend/aanvragen-app',
    name: 'Zaken',
    description: 'xxllnc Zaken is een flexibel en gebruikersvriendelijk casemanagement systeem voor overheidsorganisaties dat is vrijgegeven als open source. Met xxllnc Zaken kunnen overheidsorganisaties hun bedrijfsprocessen op een moderne en veilige manier zaakgericht uitvoeren. xxllnc Zaken komt uit de doos met DMS functionaliteit, flexibele formulieren, workflow, systeemintegratie, documentbeheer, Persoonlijke Internet Pagina, uitgebreide communicatiemodule en nog veel meer. Onze missie is om bedrijven te helpen beter te presteren met onze software. Wij geloven dat het ontwikkelen van software in open source de beste manier is om die missie te bereiken.',
    icon: app,
    vendor: 'xxllnc',
    vendorUrl: 'https://xxllnc.nl/apps#zaken',
    totalInstanceSoftLimit: 10,
    totalStorageSoftLimit: 25,
    applicationDesignTemplate: undefined,
    additionalConfiguration: undefined,
    started: '2021-10-20T11:26:10.619559+00:00',
    ended: undefined,
    created: '2021-10-20T11:26:10.619559+00:00',
    lastModified: '2021-12-08T09:07:40.503906+00:00',
    id: 'c5b19e9f-ba89-4644-a15b-7e83c5dbd424'
  }
]
