import AddIcon from '@mui/icons-material/Add'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails,
  AccordionProps, AccordionSummary as MuiAccordionSummary, AccordionSummaryProps, styled,
  Typography
} from '@mui/material'
import React, { FC } from 'react'
import { RaRecord, useGetList, useTranslate } from 'react-admin'
import { xxllncColor } from '../../layout/themes'
import { AppModuleOut } from '../../types/ecoRequests'

export const XxllncAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  'borderBottom': `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    borderBottom: 0,
    marginBottom: theme.spacing(2),
  },
  '&:active': {
    background: 'unset',
  },
}))

export const XxllncAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMore />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

export const XxllncAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export const StartIcon = styled(AddIcon)(() => ({
  position: 'absolute',
  width: '27px',
  height: '27px',
  marginLeft: '-40px',
  backgroundColor: xxllncColor.dark10,
  color: xxllncColor.dark100,
  borderRadius: '8px',
  fontSize: '16px',
}))

interface GenericOrganizationListdProps {
  record?: RaRecord
  resource: 'module' | 'integration'
}

export const GenericOrganizationList: FC<GenericOrganizationListdProps> = ({ record, resource }) => {
  const translate = useTranslate()
  const search = new URLSearchParams(location?.search)
  const appOrganizationId = search.get('appOrganizationId')
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const { data: objects } = useGetList<AppModuleOut>(
    `app_${resource}`,
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { appId: record?.id, appOrganizationId },
    },
  )

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return objects && objects.length > 0 ? (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' }}>
      <Typography variant="h6" style={{ marginBottom: '10px' }}>
        {translate(`show.${resource}`)}
      </Typography>
      { objects?.map((object) => (
        <XxllncAccordion
          key={object.name}
          expanded={expanded === object.name}
          onChange={handleChange(object.name)}
        >
          <XxllncAccordionSummary aria-controls={`${object.name}-content`} id={`${object.name}-header`}>
            <Typography sx={{ marginLeft: '20px', lineHeight: '27px' }}>
              <StartIcon />
              { object.name }
              { object.isActiveForMyOrganization &&
                  <span style={{ fontSize: '8pt', marginLeft: '8px' }}>
                    {translate('data.inUse')}
                  </span> }
            </Typography>
          </XxllncAccordionSummary>
          <XxllncAccordionDetails>
            <Typography sx={{ whiteSpace: 'pre-line' }}>
              { object.description }
            </Typography>
          </XxllncAccordionDetails>
        </XxllncAccordion>
      )) }
    </div>) : null
}