import AddIcon from '@mui/icons-material/Add'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FC } from 'react'
import { Button, EditButton, useRecordContext } from 'react-admin'
import { Link } from 'react-router-dom'
import { ActionsToolbar, RestrictedButton } from '../../components'
import CopyToClipboardButton from '../../components/buttons/CopyToClipboardButton'
import { AppOut } from '../../types/ecoRequests'
import { getTabPath } from '../../utils/getTabPath'

const pathMapping = {
  integrations: 'app_integration',
  modules: 'app_module',
  licences: 'app_licence',
  role: 'app_role',
  report: 'app_report'
}

interface ButtonProps {
  id: string
  tabPath: string
  record: AppOut
}

const ShowButton: FC<ButtonProps> = ({ id, tabPath, record }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, security/detect-object-injection
  const basePath = pathMapping[tabPath]

  if (basePath) {
    return (
      <Button
        component={Link}
        to={{
          pathname: `/${basePath}/create`,
          search: `?appId=${id}`,
        }}
        label={`actions.${tabPath}`}
        variant="contained"
      >
        <AddIcon />
      </Button>
    )
  }

  return (
    <RestrictedButton
      access={['eco.admin.app:write']}
      align="right"
    >
      {(): JSX.Element => <EditButton record={record} icon={<EditOutlinedIcon />} variant="contained" />}
    </RestrictedButton>
  )
}

const ManageAppShowButtons: FC = () => {
  const record = useRecordContext<AppOut>()
  const tabPath = getTabPath()

  if (!record) return null

  return (
    <RestrictedButton
      access={['eco.admin.app:write']}
      align="right"
    >
      {(): JSX.Element => (
        <>
          { tabPath === 'show' && <CopyToClipboardButton id={record?.id}/>}
          <ShowButton
            id={record.id}
            tabPath={tabPath}
            record={record}
          />
        </>
      )}
    </RestrictedButton>
  )
}

export const ManageAppShowActions = (): JSX.Element => (
  <ActionsToolbar source="name" icon="icon" to="/app">
    <ManageAppShowButtons />
  </ActionsToolbar>
)
