import { Grid } from '@mui/material'
import { FC } from 'react'
import { AutocompleteInput, DateTimeInput, ReferenceInput, TextInput } from 'react-admin'
import { ChipInput } from '../../components'
import { ActivateForm } from '../../components/ActivateForm'
import { defaultDate } from '../../utils/defaultDate'
import { required } from '../../utils/validation'

const AppInstanceForm: FC = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <ReferenceInput source="organizationId" reference="organization">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={12}>
      <TextInput
        helperText=""
        label="data.description"
        source="description"
        validate={required()}
        isRequired
        multiline
      />
    </Grid>
    <Grid item xs={12}>
      <ChipInput
        source="appOrganizationSystemTags"
        label="data.appOrganizationSystemTags"
        helperText=""
        disabled
      />
    </Grid>
    <Grid item xs={12}>
      <ChipInput
        source="systemTags"
        label="data.systemTags"
        helperText=""
      />
    </Grid>
    <Grid item xs={12}>
      <DateTimeInput
        defaultValue={defaultDate}
        helperText=""
        label="data.started"
        source="started"
        validate={required()}
        isRequired
      />
    </Grid>
    <ActivateForm description="app_instance" />
  </Grid>
)

export default AppInstanceForm
