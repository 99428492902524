import AddIcon from '@mui/icons-material/Add'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'
import { RestrictedButton } from '../../components'
import { ContainerAlign } from './ButtonTypes'

export const LocationCreateButton = (props: LocationCreateButtonProps): JSX.Element => {
  const { access, align, className, label, disabled, resource, search } = props
  return (
    <RestrictedButton
      access={access}
      align={align || 'right'}
    >
      {(): JSX.Element => (
        <Button
          component={Link}
          to={{
            pathname: `/${resource}/create`,
            search,
          }}
          label={label}
          variant="contained"
          className={className}
          disabled={disabled}
        >
          <AddIcon />
        </Button>
      )}
    </RestrictedButton>
  )
}

interface LocationCreateButtonProps {
  access: string[];
  align?: ContainerAlign;
  className?: string;
  label?: string;
  disabled?: boolean;
  resource: string;
  search: string;
}
