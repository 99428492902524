import { OrganizationOut } from '../../types/ecoRequests'

export const mockOrganization: OrganizationOut[] = [
  {
    created: '2021-08-04T13:43:54.578950+00:00',
    lastModified: '2000-05-11T09:00:14+00:00',
    id: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8',
    name: 'Exxellence',
    started: '2000-05-10T10:07:11+00:00',
    ended: undefined,
    logoUrl: 'https://xxllnc.nl/wp-content/uploads/2022/02/xxllnc-cloud-icon.x28451.svg',
    primaryColor: '#998dd9',
    secondaryColor: '#172b4d',
  },
  {
    created: '2021-08-04T13:50:44.364156+00:00',
    lastModified: '2021-08-04T13:50:44.364156+00:00',
    id: 'b1899778-9d3a-4d4f-bd3a-c129deb9392f',
    name: 'Test',
    started: '2021-08-04T13:50:00+00:00',
    ended: undefined,
    logoUrl: 'https://xxllnc.nl/wp-content/uploads/2022/02/xxllnc-cloud-icon.x28451.svg',
    primaryColor: '#998dd9',
    secondaryColor: '#172b4d',
  },
  {
    created: '2021-08-04T13:43:54.578950+00:00',
    lastModified: '2000-05-11T09:00:14+00:00',
    id: 'bf585e6d-e641-4ef7-be55-9d3a7054773a',
    name: 'Zaakstad',
    started: '2000-05-10T10:07:11+00:00',
    ended: undefined,
    logoUrl: 'https://xxllnc.nl/wp-content/uploads/2022/02/xxllnc-cloud-icon.x28451.svg',
    primaryColor: '#998dd9',
    secondaryColor: '#172b4d',
  },
  {
    created: '2021-08-04T13:43:54.578950+00:00',
    lastModified: '2000-05-11T09:00:14+00:00',
    id: '623dfac9-3e8e-4ea5-be5c-7dc4a78f5868',
    name: 'Zuiddrecht',
    started: '2000-05-10T10:07:11+00:00',
    ended: undefined,
    logoUrl: 'https://xxllnc.nl/wp-content/uploads/2022/02/xxllnc-cloud-icon.x28451.svg',
    primaryColor: '#998dd9',
    secondaryColor: '#172b4d',
  }
]
