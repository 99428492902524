import AddIcon from '@mui/icons-material/Add'
import { FC } from 'react'
import { Button, CreateButtonProps, useGetList, useRecordContext } from 'react-admin'
import { Link } from 'react-router-dom'
import { OrganizationAuthOut, OrganizationOut } from '../../types/ecoRequests'

export const CreateOrganizationAuthButton: FC<CreateButtonProps> = (props) => {
  const {
    className,
    label = 'actions.create',
    disabled,
  } = props
  const record = useRecordContext<OrganizationOut>()

  const { data } = useGetList<OrganizationAuthOut>(
    'organization_auth',
    {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'DESC' },
      filter: { organizationId: record?.id }
    }
  )

  return (
    <Button
      component={Link}
      to={{
        pathname: '/organization_auth/create',
        search: `?organizationId=${record?.id}`,
      }}
      label={label}
      variant="contained"
      className={className}
      disabled={disabled || !data}
    >
      <AddIcon />
    </Button>
  )
}
