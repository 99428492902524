import { AuditLogOut, ChangeType } from '../mockTypes'

export const mockAuditLog: AuditLogOut[] = [
  {
    id: 'fe2ec720-1560-11ec-82a8-0242ac130003',
    displayName: 'Tessa Tester',
    userId: '4b42a8e8-1fb0-11ec-9621-0242ac130002',
    organizationId: 'bf585e6d-e641-4ef7-be55-9d3a7054773a', // Zaakstad
    summary: 'Review text voor zaaksysteem.nl',
    timestamp: '2021-08-04T13:43:54.578950+00:00',
    appId: '42d06e6a-a49e-49f3-be38-3606169bedee', // Zaaksysteem
    changeType: ChangeType.Update,
  },
  {
    id: 'fe2ec978-1560-11ec-82a8-0242ac130003',
    displayName: 'W Jansen',
    userId: '4b42a8e8-1fb0-11ec-9621-0242ac130002',
    organizationId: 'bf585e6d-e641-4ef7-be55-9d3a7054773a', // Zaakstad
    summary: 'Andere tekst voor zaaksysteem.nl',
    timestamp: '2021-08-04T13:43:54.578950+00:00',
    appId: '42d06e6a-a49e-49f3-be38-3606169bedee', // Zaaksysteem
    changeType: ChangeType.Update,
  },
  {
    id: 'fe2ecc2a-1560-11ec-82a8-0242ac130003',
    displayName: 'Karel Appel',
    userId: '4b42a8e8-1fb0-11ec-9621-0242ac130002',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    summary: 'Review voor koppel.app',
    timestamp: '2021-08-04T13:43:54.578950+00:00',
    appId: '15cef401-4d6e-4d86-a04a-1511f4421ce8', // Koppel.app
    changeType: ChangeType.Update,
  },
  {
    id: 'fe2ecc2b-1560-11ec-82a8-0242ac130003',
    displayName: 'Karel Appel',
    userId: '4b42a8e8-1fb0-11ec-9621-0242ac130002',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    summary: 'Review voor 4Value',
    timestamp: '2021-08-04T13:43:54.578950+00:00',
    appId: 'd7f48ccf-6431-4f9a-bc64-6dd18e9560b5', // 4Value
    changeType: ChangeType.Update,
  },
  {
    id: '304050cc-2046-11ec-9621-0242ac130002',
    displayName: 'System',
    userId: 'b1944ce1-5742-4b03-a3e1-1ac98e7f014d',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    summary: 'Instance for 4Value',
    timestamp: '2021-08-04T13:43:54.578950+00:00',
    appId: 'd7f48ccf-6431-4f9a-bc64-6dd18e9560b5', // 4Value
    changeType: ChangeType.Insert,
  },
  {
    id: '469610c8-2046-11ec-9621-0242ac130002',
    displayName: 'Admin',
    userId: '4c16f08a-2046-11ec-9621-0242ac130002',
    organizationId: '0aa2beca-3819-4f49-aba2-2631e0bcbfb8', // Exxellence
    summary: 'Deleted instance for 4Value',
    timestamp: '2021-08-04T13:43:54.578950+00:00',
    appId: 'd7f48ccf-6431-4f9a-bc64-6dd18e9560b5', // 4Value
    changeType: ChangeType.Delete,
  },
]
