import { FC } from 'react'
import { Create, SimpleForm, TextInput, useGetList } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { AppOut } from '../../types/ecoRequests'

const AppIntegrationCreate: FC = () => {
  const search = new URLSearchParams(location?.search)
  const appId = search.get('appId')
  const redirect = appId ? `/app/${appId}/show/integrations` : undefined

  const handleTransform = (data: { appId: string | null }) => {
    data.appId = appId
    return data
  }

  const { data, isLoading } = useGetList<AppOut>(
    'app',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
    }
  )

  if (!data || data.length === 0)
    return null

  const relateToApp = data.find((record) => record.id === appId)

  return !isLoading ? (
    <Create
      actions={<ActionsToolbar record={relateToApp} source="name" i18n="resources.app_integration.create" />}
      redirect={redirect}
      transform={handleTransform}
      title={<HeaderTitle i18n="resources.app_integration.title" />}
    >
      <SimpleForm
        defaultValues={{ app1Id: appId }}
        sanitizeEmptyValues
      >
        <TextInput
          source="name"
          label="data.name"
          helperText=""
          multiline
        />
        <TextInput
          source="description"
          label="data.description"
          helperText=""
          multiline
        />
      </SimpleForm>
    </Create>
  ) : null
}

export default AppIntegrationCreate
