import { regex } from 'react-admin'

export const required = (message = 'ra.validation.required') => (
  value: string
): string | undefined => (value ? undefined : message)

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const systemNameValidation = [regex(/^[a-zA-Z0-9-]+$/, 'validation.systemName')]

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const colorValidation = [regex(/^#[a-fA-F0-9]{6}$/, 'validation.color')]

export const hostnameValidation =
  // eslint-disable-next-line max-len, @typescript-eslint/no-unsafe-call, security/detect-unsafe-regex
  [regex(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/, 'validation.hostname')]

