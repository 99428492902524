/* eslint-disable */
import {
  CreateParams,
  CreateResult, DeleteParams,
  DeleteResult, GetListParams,
  GetListResult, GetManyParams, GetManyReferenceParams,
  GetManyReferenceResult, GetManyResult, GetOneParams, GetOneResult, Identifier, RaRecord, UpdateParams
} from 'react-admin'
import { AppCategories, Provider, Strategy } from '../types/ecoRequests'
import { ChangeType, IReview } from './mockTypes'
import {
  mockApp,
  mockAppInstance,
  mockAppOrganization,
  mockAuditLog,
  mockAuthConnection,
  mockAuthMember,
  mockHostalias,
  mockOrganization,
  mockOrganizationAuth,
  mockReviews
} from './resources'

const getResource = (resource: string) => {
  switch (resource) {
    case 'organization':
      return mockOrganization
    case 'organization_auth':
      return mockOrganizationAuth
    case 'auth_connection':
      return mockAuthConnection
    case 'auth_member':
      return mockAuthMember
    case 'app_overview':
    case 'app':
      return mockApp
    case 'app_instance':
      return mockAppInstance
    case 'auditlog':
      return mockAuditLog
    case 'app_organization':
      return mockAppOrganization
    case 'hostalias':
      return mockHostalias
    case 'review':
      return mockReviews
    default:
      return [{ id: 1, error: 'unknown resource' }]
  }
}

const sortObject = (key: string, order = 'ASC') => {
  return function innerSort(a: string, b: string): number {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0

    const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
    const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return (order === 'DESC') ? (comparison * -1) : comparison
  }
}

const filterOnProperty = (filter: any, property: any, sorted: any) => {
  if (typeof filter[property] === 'string') {
    sorted = sorted.filter(item =>
      (item.hasOwnProperty(property) && item[property].includes(filter[property])) ||
      (property === 'q' && objectContainsSearchString(item, filter[property]))
    )
  }
  if (typeof filter[property] === 'number') {
    sorted = sorted.filter(item => item.hasOwnProperty(property) && filter[property] === item[property]
    )
  }
  return sorted
}

const filterOnPropertyGteOrLte = (filter: any, property: any, sorted: any) => {
  if (property.includes('gte')) {
    const gte_prop = property.replace('_gte', '')
    const gte_prop_value = filter[property]
    sorted = sorted.filter(item => {
      return item.hasOwnProperty(gte_prop) && item[gte_prop] >= gte_prop_value
    })
  }
  if (property.includes('lte')) {
    const lte_prop = property.replace('_lte', '')
    const lte_prop_value = filter[property]

    sorted = sorted.filter(item => {
      return item.hasOwnProperty(lte_prop) && item[lte_prop] <= lte_prop_value
    })
  }
  return sorted
}

const filterList = (list: any[], params: GetListParams) => {
  if (!list || list.length === 0) return list
  const { sort, filter } = params
  if (!sort) return list
  let sorted = list.sort(sortObject(sort.field, sort.order))
  if (filter) {
    for (const property in filter) {
      if (!filter.hasOwnProperty(property)) continue

      if (property.includes('lte') || property.includes('gte')) {
        sorted = filterOnPropertyGteOrLte(filter, property, sorted)
      } else {
        sorted = filterOnProperty(filter, property, sorted)
      }
    }
  }
  return sorted
}

const objectContainsSearchString = (object, searchString) => {
  const array = getRecordProperties(object)
  const found = array.filter(item =>
    item.toLowerCase().includes(searchString.toLowerCase()))
  return found.length > 0
}

const getRecordProperties = (object) => {
  let values: string[] = []
  for (const property in object) {
    if (!object.hasOwnProperty(property)) continue
    if (typeof object[property] === 'string') {
      values.push(object[property])
    } else if (Array.isArray(object[property])) {
      object[property].forEach(item => {
        if (typeof item === 'string') values.push(item)
        if (Array.isArray(item)) item.forEach(child => {
          if (typeof child === 'string') values.push(child)
          if (typeof child === 'object') values = [...values, ...getRecordProperties(child)]
        })
        if (typeof item === 'object') values = [...values, ...getRecordProperties(item)]
      })
    } else if (typeof object[property] === 'object') {
      values = [...values, ...getRecordProperties(object[property])]
    }
  }
  return values
}

const getPageResult = (list: any[], params: GetListParams) => {
  if (!list || list.length === 0) return list
  const { pagination } = params
  if (!pagination) return list

  const start = (pagination.page - 1) * pagination.perPage
  const end = start + pagination.perPage
  return list.slice(start, end)
}

export interface GetParams {
  id: Identifier;
}

const get = <RecordType extends RaRecord>(
  resource: string,
  params: GetParams,
): Promise<GetOneResult<RecordType>> => {
  const list = getResource(resource) as unknown as RaRecord[]
  if (!list || list.length === 0) return Promise.resolve({ data: { id: '1' } as any })
  const record = list.filter(item => String(item.id))[0] as any
  if (process?.env?.NODE_ENV !== 'test') console.log({ type: 'get', resource, list, record, params })
  return Promise.resolve({
    data: record
  })
}

const getList = <RecordType extends RaRecord>(
  resource: string,
  params: GetListParams
): Promise<GetListResult<RecordType>> => {
  const list = getResource(resource) as unknown as RaRecord[]
  const data = filterList(list, params)
  const page = getPageResult(data, params)
  if (process?.env?.NODE_ENV !== 'test') console.log({ type: 'getList', resource, list, data, page, params })
  return Promise.resolve({
    data: page,
    total: data.length
  })
}

const getOne = <RecordType extends RaRecord>(
  resource: string,
  params: GetOneParams
): Promise<GetOneResult<RecordType>> => {
  const list = getResource(resource) as unknown as RaRecord[]
  if (!list || list.length === 0) return Promise.resolve({ data: { id: '1' } as any })
  const record = list.filter(item => String(item.id) === String(params.id))[0] as any
  if (process?.env?.NODE_ENV !== 'test') console.log({ type: 'getOne', resource, list, record, params })
  return Promise.resolve({
    data: record
  })
}

const getMany = <RecordType extends RaRecord>(resource: string, params: GetManyParams): Promise<GetManyResult<RecordType>> => {
  const list = getResource(resource) as unknown as RaRecord[]
  const data = list.filter(record => params.ids.includes(record.id)) as any[]
  if (process?.env?.NODE_ENV !== 'test') console.log({ type: 'getMany', resource, list, data, params })
  return Promise.resolve({
    data: data,
    total: data.length
  })
}

const getManyReference = <RecordType extends RaRecord>(resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult<RecordType>> => {
  const list = getResource(resource) as unknown as RaRecord[]
  const data = list.filter(record => record.id) as any[]
  if (process?.env?.NODE_ENV !== 'test') console.log({ type: 'getManyReference', resource, list, data, params })

  return Promise.resolve({
    data: data,
    total: data.length
  })
}

const create = <RecordType extends RaRecord>(resource: string, params: CreateParams): Promise<CreateResult<RecordType>> => {
  if (process?.env?.NODE_ENV !== 'test') console.log({ type: 'create', resource, params })
  let id = ''
  switch (resource) {
    case 'organization':
      id = `${mockOrganization.length + 1}`
      mockOrganization.push({
        id,
        created: '',
        lastModified: '',
        name: '',
        started: '',
        ...params.data
      })
      break
      case 'organization_auth':
        id = `${mockOrganizationAuth.length + 1}`
        mockOrganizationAuth.push({
          id,
          organizationId: '',
          provider: Provider.Auth0,
          auth0Identifier: '',
          ...params.data
        })
      mockOrganizationAuth.push({
        id,
        organizationId: '',
        provider: Provider.Auth0,
        auth0Identifier: '',
        ...params.data
      })
      break
    case 'auth_connection':
      id = `${mockAuthConnection.length + 1}`
      mockAuthConnection.push({
        id,
        strategy: Strategy.Auth0,
        organizationAuthId: '',
        hostnames: [''],
        name: '',
        ...params.data
      })
      break
    case 'auth_member':
      id = `${mockAuthMember.length + 1}`
      mockAuthMember.push({
        id,
        name: '',
        email: '',
        picture: '',
        organizationAuthId: '',
        organizationId: '',
        ...params.data
      })
      break
    case 'app_overview':
    case 'app':
      id = `${mockApp.length + 1}`
      mockApp.push({
        id,
        imageUrl: '',
        name: '',
        description: '',
        category: AppCategories.Belastingen,
        icon: '',
        totalInstanceSoftLimit: 0,
        totalStorageSoftLimit: 0,
        created: '',
        lastModified: '',
        ...params.data,
      })
      break
    case 'app_instance':
      id = `${mockAppInstance.length + 1}`
      mockAppInstance.push({
        id,
        ...params.data,
        description: '',
        appId: '',
        created: '',
        lastModified: '',
        appName: ''
      })
      break
    case 'auditlog':
      id = `${mockAuditLog.length + 1}`
      mockAuditLog.push({
        id,
        ...params.data,
        appId: '',
        organizationId: '',
        displayName: '',
        userId: '',
        summary: '',
        timestamp: '',
        changeType: ChangeType.Insert
      })
      break
    case 'hostalias':
      id = `${mockHostalias.length + 1}`
      mockHostalias.push({
        id,
        ...params.data,
        appInstanceId: '',
        hostname: '',
        primaryHostalias: false,
        started: '',
        created: '',
        lastModified: ''
      })
      break
    case 'review':
      id = `${mockReviews.length + 1}`
      if (!('user' in params.data && 'date' in params.data && 'text' in params.data && 'appId' in params.data)) {
        throw new Error('params.data is missing required properties for IReview')
      }
      const review: IReview = {
        id,
        user: '',
        date: '',
        text: '',
        appId: '',
        ...params.data
      }
      mockReviews.push(review)
      break
  }

  return Promise.resolve({
    data: {
      id,
      ...params.data
    } as RecordType
  })
}

const deleteOne = <RecordType extends RaRecord>(resource: string, params: DeleteParams): Promise<DeleteResult<RecordType>> => {
  if (process?.env?.NODE_ENV !== 'test') console.log({ type: 'delete', resource, params })
  let data: any
  switch (resource) {
    case 'organization':
      const orgIndex = mockOrganization.findIndex(record => String(record.id) === params.id)
      data = mockOrganization.splice(orgIndex, 1)[0]
      break
    case 'organization_auth':
      const orgAuthIndex = mockOrganizationAuth.findIndex(record => String(record.id) === params.id)
      data = mockOrganizationAuth.splice(orgAuthIndex, 1)[0]
      break
    case 'auth_connection':
      const authConIndex = mockAuthConnection.findIndex(record => String(record.id) === params.id)
      data = mockAuthConnection.splice(authConIndex, 1)[0]
      break
    case 'auth_member':
      const authMemIndex = mockAuthMember.findIndex(record => String(record.id) === params.id)
      data = mockAuthMember.splice(authMemIndex, 1)[0]
      break
    case 'app_overview':
    case 'app':
      const appIndex = mockApp.findIndex(record => String(record.id) === params.id)
      data = mockApp.splice(appIndex, 1)[0]
      break
    case 'app_instance':
      const instIndex = mockAppInstance.findIndex(record => String(record.id) === params.id)
      data = mockAppInstance.splice(instIndex, 1)[0]
      break
    case 'hostalias':
      const hostIndex = mockHostalias.findIndex(record => String(record.id) === params.id)
      data = mockHostalias.splice(hostIndex, 1)[0]
      break
    case 'review':
      const revIndex = mockReviews.findIndex(record => String(record.id) === params.id)
      data = mockReviews.splice(revIndex, 1)[0]
      break
  }

  return Promise.resolve({
    data: {
      id: params.id,
      ...data
    }
  })
}

const update = <RecordType extends RaRecord>(resource: string, params: UpdateParams): Promise<GetOneResult<RecordType>> => {
  const list = getResource(resource) as unknown as RaRecord[]
  if (!list || list.length === 0) return Promise.resolve({ data: { id: '1' } as any })
  const record = list.filter(item => String(item.id) === String(params.id))[0] as any
  if (process?.env?.NODE_ENV !== 'test') console.log({ type: 'update', resource, list, record, params })
  return Promise.resolve({
    data: {
      ...record,
      ...params.data
    }
  })
}

export default {
  get,
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  delete: deleteOne,
  update,
}
