import CheckIcon from '@mui/icons-material/Check'
import { styled } from '@mui/material/styles'
import { xxllncColor } from '../../layout/themes'

export const AppCardActions = ({ isActiveForMyOrganization, icon }: AppCardActionsProps): JSX.Element => (
  <Root className={classes.root}>
    {icon &&
        <div className={classes.logo}>
          <img src={`data:image/svg+xml;base64,${icon}`} />
        </div>
    }
    {isActiveForMyOrganization && (
      <div className={classes.button}>
        <CheckIcon className={classes.icon} />
      </div>
    )}
  </Root>
)

interface AppCardActionsProps {
  isActiveForMyOrganization?: boolean | null;
  icon?: string;
}

const PREFIX = 'xxllncAppCardActions'

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  [`& .${classes.logo}`]: {
    height: '56px',
    width: '56px',
    borderRadius: 16,
    overflow: 'hidden',
    ['& img']: {
      width: '100%',
    }
  },
  [`& .${classes.button}`]: {
    alignItems: 'center',
    backgroundColor: xxllncColor.dark100,
    borderRadius: '50px',
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    lineHeight: 0,
    marginLeft: 'auto',
    position: 'absolute',
    right: '12px',
    top: '12px',
    transition: 'background .5s, color .5s',
    width: '32px',
  },
  [`& .${classes.icon}`]: {
    fill: xxllncColor.light100,
    width: '18px',
    height: '18px',
  },
}))
