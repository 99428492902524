import AuthMemberIcon from '@mui/icons-material/People'
import AuthMemberRoleCreate from './AuthMemberRole.create'
import AuthMemberRoleList from './AuthMemberRole.list'

const resource = {
  icon: AuthMemberIcon,
  list: AuthMemberRoleList,
  create: AuthMemberRoleCreate,
}

export default resource
