import polyglotI18nProvider from 'ra-i18n-polyglot'
import { DataProvider } from 'react-admin'
import * as domainMessages from 'react-admin-import-csv/lib/i18n'
import { getEnvVariable } from 'xxllnc-react-components'
import { i18nDutch, i18nEnglish } from '../i18n'
import authProvider from './auth'
import { getUrl, mockDataProvider, xxllncDataProvider } from './data'

const getDataProvider = (): DataProvider => {
  const mock = getEnvVariable('REACT_APP_MOCK_REQUESTS') === 'true' || false
  const test = getEnvVariable('NODE_ENV') === 'test'

  if (mock)
    console.log('mock is set to true!')

  return !mock && !test ? xxllncDataProvider : mockDataProvider
}

const messages = {
  en: { ...i18nEnglish, ...domainMessages.en },
  nl: { ...i18nDutch, ...domainMessages.nl },
}

const i18nProvider = polyglotI18nProvider(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  (locale: string) => (messages[`${locale}`] ? messages[`${locale}`] : messages.en),
  'nl'
)

const providers = {
  auth: authProvider,
  data: getDataProvider(),
  i18n: i18nProvider,
  url: getUrl(),
}

export default providers
