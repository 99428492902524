import { Grid } from '@mui/material'
import { FC } from 'react'
import { Edit, Identifier, RaRecord, SimpleForm, useEditController } from 'react-admin'
import { ActionsToolbar, ConfirmDeleteToolbar, HeaderTitle } from '../../components'
import { activateTransform } from '../../utils/activateTransform'
import AppOrganizationForm from './AppOrganizationForm'

const AppOrganizationEdit: FC = () => {
  const { record } = useEditController<RaRecord<Identifier>>()
  const deleteRedirect = record ? `/organization/${record.organizationId as Identifier}/show/apps` : '/organization'

  return (
    <Edit
      title={<HeaderTitle source="appName" i18n="resources.app_organization.edit" />}
      actions={<ActionsToolbar source="appName" i18n="resources.app_organization.edit" />}
      transform={activateTransform}
      redirect="show"
    >
      <SimpleForm
        toolbar={
          <ConfirmDeleteToolbar
            access={['eco.admin.apporganization:delete']}
            redirect={deleteRedirect}
          />
        }
        sanitizeEmptyValues
      >
        <Grid container spacing={2}>
          <AppOrganizationForm />
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default AppOrganizationEdit
