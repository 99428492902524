import { Grid } from '@mui/material'
import { FC } from 'react'
import { SimpleShowLayout, TextField } from 'react-admin'

const AuditlogsExpand: FC = () => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6}>
      <SimpleShowLayout>
        <TextField
          source="displayName"
          label="data.displayName"
          emptyText="-"
        />
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={12} sm={6}>
      <SimpleShowLayout>
        <TextField
          source="userId"
          label="data.userId"
          emptyText="-"
        />
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={12}>
      <SimpleShowLayout>
        <TextField
          source="summary"
          label="data.description"
          emptyText="-"
        />
      </SimpleShowLayout>
    </Grid>
  </Grid>
)

export default AuditlogsExpand
