import { Grid } from '@mui/material'
import { FC } from 'react'
import { SelectInput } from 'react-admin'
import { Provider } from '../../types/ecoRequests'
import { toReadableString } from '../../utils/textFormatter'
import { required } from '../../utils/validation'

const OrganizationAuthForm: FC = () => {
  const getChoicesArray = Object.values(Provider)
  const choices = getChoicesArray.map(choice => ({
    id: choice,
    name: toReadableString(choice || '-'),
  }))

  return(
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectInput
          choices={choices}
          helperText=""
          label="data.provider"
          source="provider"
          validate={required()}
          isRequired
        />
      </Grid>
    </Grid>
  )
}

export default OrganizationAuthForm
