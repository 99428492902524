import { FC } from 'react'
import { Create, SelectInput, SimpleForm, TextInput } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { required } from '../../utils/validation'

const availableApps = ['tribeCrm', 'plesk']

const ExternalAppsCreate: FC = () => (
  <Create
    actions={<ActionsToolbar i18n="resources.externalApps.create" />}
    title={<HeaderTitle i18n="resources.externalApps.create" />}
  >
    <SimpleForm>
      <SelectInput
        choices={availableApps.map((app) => ({ id: app, name: app })) }
        label="data.externalApp"
        source="id"
        validate={required()}
        isRequired
      />
      <TextInput source="refresh_token" label="data.refresh_token" validate={required()} />
    </SimpleForm>
  </Create>
)

export default ExternalAppsCreate
