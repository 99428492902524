import AppOrganizationShow from './AppOrganization.show'
import AppOrganizationCreate from './AppOrganization.create'
import AppOrganizationEdit from './AppOrganization.edit'

const resource = {
  show: AppOrganizationShow,
  create: AppOrganizationCreate,
  edit: AppOrganizationEdit,
}

export default resource
