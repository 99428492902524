import { HostAliasOut } from '../../types/ecoRequests'

export const mockHostalias: HostAliasOut[] = [
  {
    id: '51ad511c-5c46-4bc8-be07-3e661e510cdd',
    appInstanceId: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056', // Competent
    hostname: 'Exxellence_Competent_2',
    primaryHostalias: false,
    started: '2000-05-10T10:07:11+00:00',
    created: '2021-08-04T13:43:54.604016+00:00',
    ended: undefined,
    lastModified: '2000-05-11T09:00:14+00:00',
  },
  {
    id: 'df63e2f8-9704-41dd-af22-b023b15213b3',
    appInstanceId: '2fd768cf-30b8-4e9c-ae7b-e0ac5d7d0056', // Competent
    hostname: 'Exxellence_Competent_1',
    primaryHostalias: true,
    started: '2000-05-10T10:07:11+00:00',
    created: '2021-08-04T13:43:54.604016+00:00',
    ended: undefined,
    lastModified: '2000-05-11T09:00:14+00:00',
  },
  {
    appInstanceId: '8f1579da-ef06-4ead-bd2b-e4c45582bc3c', // 4Value
    created: '2021-09-20T09:17:39.919342+00:00',
    ended: undefined,
    hostname: 'prod.4value.nl/',
    id: '6f74931e-724f-4210-b702-175595a2923e',
    lastModified: '2021-09-20T09:17:39.919342+00:00',
    primaryHostalias: true,
    started: '2021-09-20T09:17:00+00:00',
  }
]
