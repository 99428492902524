import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FC } from 'react'
import { CreateButton, Datagrid, EditButton, Empty, SearchInput } from 'react-admin'
import { ImportButton, ImportConfig } from 'react-admin-import-csv'
import { EmptyContainer, ListActionsToolbar, RestrictedButton, RestrictedContainer } from '../../components'
import { ConditionalTextField } from '../../components/fields/ConditionalTextField'
import CustomList from '../../components/list/CustomList'

const organizationFilters = [
  <SearchInput source="q" alwaysOn />,
]

const OrganizationList: FC = () => {
  const config: ImportConfig = {
    logging: false,
    parseConfig: {
      delimiter: '',	// auto-detect
      newline: '',	// auto-detect
      quoteChar: '"',
      escapeChar: '"',
      header: false,
      transformHeader: undefined,
      dynamicTyping: false,
      preview: 0,
      encoding: 'ISO-8859-1',
      worker: false,
      comments: false,
      step: undefined,
      complete: undefined,
      error: undefined,
      download: false,
      downloadRequestHeaders: undefined,
      downloadRequestBody: undefined,
      skipEmptyLines: true,
      chunk: undefined,
      chunkSize: undefined,
      fastMode: undefined,
      beforeFirstChunk: undefined,
      withCredentials: undefined,
      transform: undefined,
      delimitersToGuess: [',', '\t', '|', ';'],
    }
  }

  return (
    <CustomList
      title='resources.organization.title'
      sort={{ field: 'name', order: 'ASC' }}
      filters={organizationFilters}
      exporter={false}
      actions={
        <ListActionsToolbar i18n="resources.organization.list">
          <RestrictedContainer
            access={['eco.admin.organization:write']}
            align="right"
          >
            <ImportButton label="resources.organization.import" {...config} />
          </RestrictedContainer>
          <RestrictedButton
            access={['eco.admin.organization:write']}
            align="right"
          >
            {(): JSX.Element => <CreateButton variant="contained" />}
          </RestrictedButton>
        </ ListActionsToolbar>
      }
      empty={
        <EmptyContainer>
          <Empty />
        </EmptyContainer>
      }
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="show"
      >
        <ConditionalTextField source="name" label="data.name" />
        <RestrictedButton
          access={['eco.admin.organization:write']}
          align="right"
        >
          {(): JSX.Element => <EditButton label="" icon={<EditOutlinedIcon />} />}
        </RestrictedButton>
      </Datagrid>
    </CustomList>
  )
}

export default OrganizationList
