import LinkIcon from '@mui/icons-material/Link'
import ExternalAppsList from './ExternalApps.list'
import ExternalAppsCreate from './ExternalApps.create'
import ExternalAppsEdit from './ExternalApps.edit'

const resource = {
  list: ExternalAppsList,
  create: ExternalAppsCreate,
  edit: ExternalAppsEdit,
  icon: LinkIcon,
}

export default resource
